<template>
    <a-responsive class="neutral task-detail-pane elevation-12" style="margin-top: 3px;" :style="{ zIndex: $route.name === 'tasks' ? 12 : 8 }" :class="{'visible': isOpen && editItem.id}">
        <a-container container--fluid grid-list-xs class="white px-6 pt-6 pb-4" v-if="editItem && editItem.id">
            <a-layout wrap align-start class="mb-4">
                <a-flex shrink class="u-flex align-center">
                    <slot name="status"></slot>
                    <!-- <a-btn class="ma-0 elevation-0" small :disabled="!permission_task('update')" v-if="!editItem.completed_at" @click="$emit('toggle-complete', editItem.id, editItem.completed_at, editItem.milestone_id)">
                        <a-icon size="20" color="grey darken-3" left class="mr-2">radio_button_unchecked</a-icon> Mark Complete
                    </a-btn>
                    <a-btn class="ma-0 elevation-0" small color="teal" :disabled="!permission_task('update')" :dark="permission_task('update')" v-if="editItem.completed_at" @click="$emit('toggle-complete', editItem.id, editItem.completed_at, editItem.milestone_id)">
                        <a-icon size="20" color="white" left class="mr-2">done</a-icon> Mark Incomplete
                    </a-btn> -->
                    <a-btn v-if="local_destroy_item_id !== editItem.id" icon text small color="grey" @click="local_destroy_item_id = editItem.id" class="ma-0 ml-3">
                        <a-icon size="18">delete</a-icon>
                    </a-btn>
                    <a-tooltip bottom right v-if="local_destroy_item_id === editItem.id">
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" icon text small color="red" class="ma-0 ml-3" @click="$emit('destroy', editItem.id, editItem.milestone_id)" :disabled="!permission_task('destroy')" :dark="permission_task('destroy')">
                                <a-icon color="red" size="18" >delete</a-icon>
                            </a-btn>
                        </template>
                        <span>Confirm Delete?</span>
                    </a-tooltip>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink>
                    <!-- <a-btn color="red" class="ml-2" text small icon @click="$emit('destroy', editItem.id, editItem.milestone_id)" :disabled="!permission_task('destroy')" :dark="permission_task('destroy')">
                        <a-icon size="18" color="red">delete</a-icon>
                    </a-btn> -->
                    <a-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a-btn v-on="on" color="grey" text small dark icon v-ripple="false" @click="local_close">
                                <a-icon size="18" color="grey">login</a-icon>
                            </a-btn>
                        </template>
                        <span>Close</span>
                    </a-tooltip>
                </a-flex>
            </a-layout>
            <div class="mb-10">
                <a-layout wrap class="mb-1 u-relative">
                    <a-flex xs12>
                        <a-textarea
                            v-model="editItem.title"
                            :class="[ {'indigo': title_edit_mode && permission_task('update')}, 'font-weight-regular content-editable u-border']"
                            placeholder="Enter Title"
                            rows="1"
                            :readonly="!permission_task('update')"
                            multi-line auto-grow solo flat hide-details
                            @input="local_validation('title')"
                            @focus="local_focus('title')"
                            @keyup.esc="local_update('title')"
                            @blur="revert_mode ? {} : local_update('title')"
                        ></a-textarea>
                    </a-flex>
                    <div class="u-absolute" style="bottom: -32px; right: 0">
                        <div v-if="title_edit_mode && permission_task('update')">
                            <v-hover v-slot="{ hover }">
                                <a-btn x-small min-width="2" height="28" class="mr-2 u-elevation-custom-1" :style="{'background-color': hover ? '#E8F5E9' : ''}">
                                    <a-icon x-small :style="{'color': hover ? '#388E3C' : ''}">done</a-icon>
                                </a-btn>
                            </v-hover>
                            <v-hover v-slot="{ hover }">
                                <a-btn class="u-elevation-custom-1" x-small min-width="2" height="28" @mousedown="local_revert_changes('title')" :style="{'background-color': hover ? '#FFEBEE' : ''}">
                                    <a-icon x-small :style="{'color': hover ? '#E53935' : ''}">close</a-icon>
                                </a-btn>
                            </v-hover>
                        </div>
                    </div>
                    <div class="u-absolute" style="bottom: -28px; left: 0">
                        <span class="md-caption red--text text--darken-2 d-block mt-1" v-if="error_response && error_response.title">
                            <a-icon size="16" color="red darken-2">warning</a-icon>
                            {{ error_response.title }}
                        </span>
                    </div>
                </a-layout>
            </div>
            <a-layout wrap mt-4 class="mb-1 u-relative">
                <a-flex xs12>
                    <a-textarea
                        v-model="editItem.description"
                        :class="[ {'indigo': description_edit_mode && permission_task('update')}, 'font-weight-regular content-editable u-border overflow-auto u-dashboard-scroll']"
                        style="maxHeight: 280px"
                        :placeholder="(user_self.scope === 'internal') ? 'Enter Description' : 'No Description'"
                        rows="2"
                        row-height="24"
                        :readonly="!permission_task('update')"
                        multi-line no-resize auto-grow solo flat hide-details
                        @input="local_validation('description')"
                        @focus="local_focus('description')"
                        @keyup.esc="local_update('description')"
                        @blur="revert_mode ? {} : local_update('description')"
                    ></a-textarea>
                </a-flex>
                <div class="u-absolute" style="bottom: -32px; right: 0">
                    <div v-if="description_edit_mode && permission_task('update')">
                        <v-hover v-slot="{ hover }">
                            <a-btn x-small min-width="2" height="28" class="mr-2 u-elevation-custom-1" :style="{'background-color': hover ? '#E8F5E9' : ''}">
                                <a-icon x-small :style="{'color': hover ? '#388E3C' : ''}">done</a-icon>
                            </a-btn>
                        </v-hover>
                        <v-hover v-slot="{ hover }">
                            <a-btn class="u-elevation-custom-1" x-small min-width="2" height="28" @mousedown="local_revert_changes('description')" :style="{'background-color': hover ? '#FFEBEE' : ''}">
                                <a-icon x-small :style="{'color': hover ? '#E53935' : ''}">close</a-icon>
                            </a-btn>
                        </v-hover>
                    </div>
                </div>
                <div class="u-absolute" style="bottom: -28px; left: 0">
                    <span class="md-caption red--text text--darken-2 d-block mt-1" v-if="error_response && error_response.description">
                        <a-icon size="16" color="red darken-2">warning</a-icon>
                        {{ error_response.description }}
                    </span>
                </div>
            </a-layout>
            <a-layout mt-10>
                <a-flex pb-0>
                    <slot name="assignees"></slot>
                </a-flex>
                <a-spacer></a-spacer>
            </a-layout>
        </a-container>
        <a-divider class="u-divider"></a-divider>
        <a-container container--fluid grid-list-xs class="white px-6 pb-3 pt-3" v-if="editItem && editItem.id">
            <a-layout align-center wrap class="mb-3">
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">event</a-icon>
                </a-flex>
                <a-flex>
                    <span class="md-caption grey--text mb-0">Start Date</span>
                    <a-menu :close-on-content-click="false" v-model="task_start_date" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :disabled="!permission_task('update') || error_fields">
                        <template v-slot:activator="{ on }">
                            <div v-if="editItem.start_date" v-on="on">
                                <g-moment input-format="YYYY-MM-DD" output-format="MMMM D, YYYY" :value="editItem.start_date" class="u-cursor-pointer md-body-2"></g-moment>
                            </div>
                            <div v-else v-on="on">
                                <span class="md-body-2 grey--text u-cursor-pointer">No Start Date</span>
                            </div>
                        </template>
                        <a-date-picker v-model="editItem.start_date" :max="editItem.due_date" no-title scrollable @input="local_update()"></a-date-picker>
                    </a-menu>
                    <p class="blue--text caption mb-0 u-cursor-pointer" v-if="editItem.start_date !== null && permission_task('update') && !error_fields" @click="local_clear_date('start_date')">Clear Start Date</p>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">event</a-icon>
                </a-flex>
                <a-flex style="min-width: 175px">
                    <span class="md-caption grey--text mb-0">Due Date</span>
                    <a-menu :close-on-content-click="false" v-model="task_due_date" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :disabled="!permission_task('update') || error_fields">
                        <template v-slot:activator="{ on }">
                            <div v-if="editItem.due_date" v-on="on">
                                <g-moment input-format="YYYY-MM-DD" output-format="MMMM D, YYYY" :value="editItem.due_date" class="u-cursor-pointer md-body-2" :class="{'red--text text--darken-2': local_due_date_passed(editItem.due_date, editItem.completed_at)}"></g-moment>
                            </div>
                            <div v-else v-on="on">
                                <span class="md-body-2 grey--text u-cursor-pointer">No Due Date</span>
                            </div>
                        </template>
                        <a-date-picker v-model="editItem.due_date" :min="editItem.start_date" no-title scrollable @input="local_update()"></a-date-picker>
                    </a-menu>
                    <p class="blue--text caption mb-0 u-cursor-pointer" v-if="editItem.due_date !== null && permission_task('update') && !error_fields" @click="local_clear_date('due_date')">Clear Due Date</p>
                </a-flex>
                <a-flex xs12 text-right pa-0 v-if="$response(task_response, 'due_date')">
                    <span class="md-caption red--text text--darken-1">{{ $response(task_response, 'due_date') }}</span>
                </a-flex>
            </a-layout>
            <a-layout align-center class="mb-3">
                <a-flex shrink align-self-end class="mr-2">
                    <a-icon size="24" color="grey lighten-1" class="mb-2" v-if="editItem.visibility === 'internal'">lock</a-icon>
                    <a-icon size="24" color="orange lighten-1" class="mb-2" v-if="editItem.visibility === 'external'">lock_open</a-icon>
                </a-flex>
                <a-flex>
                    <span class="md-caption grey--text mb-0">Visibility</span>
                    <g-confirmation v-if="editItem.visibility == 'internal'" type="task" :disabled="!permission_task('update') || error_fields" @action="permission_task('update') ? $emit('toggle-visibility', editItem.id, editItem.visibility, editItem.milestone_id) : ''">
                        <h5 class="md-subtitle-1 u-cursor-pointer">Private</h5>
                    </g-confirmation>
                    <h5 class="md-subtitle-1 orange--text u-cursor-pointer" v-else @click="permission_task('update') && !error_fields ? $emit('toggle-visibility', editItem.id, editItem.visibility, editItem.milestone_id) : ''">Public</h5>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink align-self-center class="mr-2">
                    <a-icon size="24" color="grey lighten-1">priority_high</a-icon>
                </a-flex>
                <a-flex class="ml-2" style="min-width: 175px">
                    <p class="md-caption grey--text mb-0">Priority</p>
                    <div class="u-flex align-center">
                        <a-menu offset-y :disabled="!permission_task('update') || error_fields">
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <p class="md-subtitle-1 mb-0 u-cursor-pointer grey--text" v-if="editItem && !editItem.priority">No Priority</p>
                                    <div class="md-subtitle-1 mb-0 u-cursor-pointer" v-else>
                                        <div class="u-rounded-corners px-2 md-body-2 u-flex align-center" style="padding-top: 2px; padding-bottom: 2px;" :class="{
                                            'red lighten-4 red--text text--darken-2 ml-0': editItem.priority === 'high',
                                            'blue lighten-4 blue--text text--darken-2 ml-0': editItem.priority ===  'low'
                                        }">
                                            {{ (editItem.priority === 'high') ? 'High' : '' }}
                                            {{ (editItem.priority ===  'low') ? 'Low' : '' }}
                                            <a-icon small class="ml-1" color="grey darken-4">keyboard_arrow_down</a-icon>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <a-list>
                                <a-list-item @click="$emit('update-priority', 'high')">
                                    <a-list-item-title>
                                        High
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item @click="$emit('update-priority', 'low')">
                                    <a-list-item-title>
                                        Low
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                        <a-icon size="16" color="blue" class="pa-1 u-cursor-pointer ml-1" v-if="editItem.priority !== null && permission_task('update') && !error_fields" @click="$emit('update-priority', null)">clear</a-icon>
                    </div>
                </a-flex>
            </a-layout>
            <a-layout align-center mt-4>
                <a-flex>
                    <div class="md-body-2 font-weight-medium grey--text text--darken-2 u-flex">
                    <a-icon size="24" color="grey lighten-1" class="mr-2 u-icon-nudge mt-2" :class="{'u-cursor-pointer': permission_task('update') && !error_fields}"  @click="permission_task('update') && !error_fields ? local_show_est_edit(editItem.estimated_duration_text) : {}">hourglass_top</a-icon>
                    <div>
                        <div class="mr-1 font-weight-medium md-caption u-flex align-center" :class="{'u-cursor-pointer': permission_task('update') && !error_fields}" @click="permission_task('update') && !error_fields ? local_show_est_edit(editItem.estimated_duration_text) : {}">
                            <span class="md-caption grey--text">Estimated Duration</span>
                            <a-icon size="12" class="ml-1" color="grey darken-1" v-if="permission_task('update') && !error_fields">edit</a-icon>
                        </div>
                        <v-slide-y-transition leave-absolute hide-on-leave>
                            <div v-if="!showEstimateEditForm" :class="{'u-cursor-pointer': permission_task('update') && !error_fields}" @click="permission_task('update') && !error_fields ? local_show_est_edit(editItem.estimated_duration_text) : {}">
                                <div>{{ editItem.estimated_duration_text ? editItem.estimated_duration_text : '0m' }}</div>
                            </div>
                            <a-form v-else class="u-relative" @submit.prevent="local_update_est">
                                <a-sheet height="24" class="u-flex align-center mt-2">
                                    <input type="text" v-model="localEstText" ref="refEstEdit" hide-details solo flat style="outline: none" class="u-rounded-corners u-wfull u-border pa-1 px-2 mr-2">
                                    <a-icon size="18" color="green darken-1" v-ripple @click="local_update_est" class="d-inline-block u-cursor-pointer u-rounded-corners pa-1 mr-1">check</a-icon>
                                    <a-icon size="18" color="grey darken-1" v-ripple @click="local_close_est_edit" class="d-inline-block u-cursor-pointer u-rounded-corners pa-1">clear</a-icon>
                                </a-sheet>
                                <span class="md-caption mt-2 red--text text--darken-2 d-block text-left" v-if="validationError">
                                    {{ validationError }} <span class="grey--text text--darken-2">(Ex: 4w 5d 6h 20m)</span>
                                </span>
                            </a-form>
                        </v-slide-y-transition>
                    </div>
                </div>
                </a-flex>
                <a-spacer></a-spacer>
                <a-flex shrink align-self-end class="mr-2">
                    <a-icon size="24" color="grey lighten-1">assistant_photo</a-icon>
                </a-flex>
                <a-flex style="min-width: 175px">
                    <p class="md-caption grey--text mb-0">Milestone</p>
                    <a-menu bottom left offset-y min-width="240" :disabled="!permission_task('update') || !!error_fields">
                        <template v-slot:activator="{ on }">
                            <p v-if="editItem.milestone_id !== null && editItem.milestone" v-on="on" class="md-subtitle-1 mb-0 u-cursor-pointer">
                                {{ editItem.milestone.title | truncateText(13) }}
                            </p>
                            <p v-else v-on="on" class="md-subtitle-1 mb-0 u-cursor-pointer">No Milestone</p>
                        </template>
                        <a-list class="u-list-condensed u-overflow-y" style="max-height: 250px;" v-if="milestone_list && milestone_list.length > 0">
                            <a-list-item v-for="milestone in milestone_list" :key="milestone.id"  :disabled="editItem.milestone && editItem.milestone_id !== null && milestone.id == editItem.milestone.id" @click="$emit('update-milestone', milestone.id)">
                                <a-list-item-content>
                                    <a-list-item-title class="text-truncate" style="max-width: 300px">{{ milestone.title }}</a-list-item-title>
                                </a-list-item-content>
                            </a-list-item>
                            <a-list-item :disabled="editItem.milestone_id == null" @click="$emit('update-milestone', null)">
                                <a-list-item-content>
                                    <a-list-item-title>No Milestone</a-list-item-title>
                                </a-list-item-content>
                            </a-list-item>
                        </a-list>
                    </a-menu>
                </a-flex>
            </a-layout>
        </a-container>
        <a-sheet class="u-flex align-center py-1 u-cursor-pointer" v-ripple @click="!error_fields ? $emit('open-time-est', editItem) : {}">
            <slot name="time-progress"></slot>
        </a-sheet>
        <a-divider class="grey lighten-3"></a-divider>
        <a-container container--fluid grid-list-xs class="pa-6 mb-5" v-if="editItem.id">
            <a-form @submit.prevent="$emit('store-comment')">
                <a-layout wrap align-start v-if="$can('tasks.comment')">
                    <a-flex xs9 class="mb-4">
                        <a-textarea
                            v-model="comment_item.content"
                            class="u-elevation-custom-1"
                            placeholder="Add Your Comment"
                            rows="2"
                            multi-line
                            auto-grow solo flat hide-details
                            :disabled="error_fields"
                        ></a-textarea>
                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(comment_response, 'comment_text')">
                            <a-icon size="16" color="red darken-2">warning</a-icon>
                            {{ $response(comment_response, 'comment_text') }}
                        </span>
                    </a-flex>
                    <a-flex xs3>
                        <a-btn class="ma-2 elevation-0 primary lighten-1" :disabled="error_fields" block @click="$emit('store-comment')">
                            <a-icon>keyboard_return</a-icon>
                        </a-btn>
                    </a-flex>
                </a-layout>
            </a-form>
            <a-layout wrap align-center>
                <a-flex xs12>
                    <loader-template v-if="isCommentLoading" width="100%" height="48" class="mt-4 u-rounded-corners-lg"></loader-template>
                    <template v-else>
                        <p class="md-body-2 my-4 pb-3 grey--text text--darken-1 text-center" v-if="comment_list && !comment_list.length"><a-icon size="16" color="grey" class="mr-2">chat_bubble</a-icon> No comments for this task.</p>
                        <div v-else>
                            <p class="md-body-2 mb-2 grey--text text--darken-1"><a-icon size="16" color="grey" class="mr-2">chat_bubble</a-icon> Comments</p>
                            <a-layout class="my-4" v-for="comment  in comment_list" :key="comment.id">
                                <a-tooltip bottom v-if="comment && comment.created_by">
                                    <template v-slot:activator="{ on }">
                                        <g-avatar v-on="on" :item="comment.created_by"></g-avatar>
                                    </template>
                                    <span>{{ comment.created_by.name }}</span>
                                </a-tooltip>
                                <a-flex>
                                    <a-card class="u-elevation-custom-1" :class="{ 'yellow lighten-4': comment.visibility === 'external', }">
                                        <a-card-text class="pa-3">
                                            <p class="md-body-2 mb-0 text-wrap-pre">{{ comment.content }}</p>
                                        </a-card-text>
                                    </a-card>
                                    <a-layout align-center>
                                        <a-flex shrink>
                                            <g-moment class="md-caption grey--text d-block pt-2" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM D, YYYY h:mma" :value="comment.created_at" convert-local></g-moment>
                                        </a-flex>
                                        <a-spacer></a-spacer>
                                        <a-flex shrink v-if="user_self && user_self.scope === 'internal'">
                                            <a-btn text v-if="comment.visibility === null || comment.visibility === 'internal'" color="grey" small class="mx-0 mb-0 mt-1" @click="local_comment_visibility_toggle(comment)" :disabled="comment.creator && user_self.id !== comment.creator.id || error_fields || !$can('tasks.comment')">
                                                <a-icon size="12" class="mr-2">lock</a-icon> Private
                                            </a-btn>
                                            <a-btn text v-else color="orange" small class="mx-0 mb-0 mt-1" @click="local_comment_visibility_toggle(comment)" :disabled="comment.creator && user_self.id !== comment.creator.id || error_fields || !$can('tasks.comment')">
                                                <a-icon size="12" class="mr-2">lock_open</a-icon> Public
                                            </a-btn>
                                        </a-flex>
                                    </a-layout>
                                </a-flex>
                            </a-layout>
                        </div>
                    </template>
                </a-flex>
            </a-layout>
        </a-container>
    </a-responsive>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        editItem: {
            type: Object,
            required: true
        },
        isOpen: {
            type: Boolean
        },
        isCommentLoading: {
            type: Boolean
        },
        revertItem: {
            type: Object,
        }
    },

    data () {
        return {
            task_start_date: null,
            task_due_date: null,
            localEstText: null,
            validationError: null,
            showEstimateEditForm: false,
            local_destroy_item_id: null,
            error_response: {},
            title_edit_mode: false,
            description_edit_mode: false,
            revert_mode: false,
            revert_target: null,
            storedItem: {},
        }
    },
    watch: {
        editItem (val) {
            if (!Object.keys(val).length) return
            this.storedItem = {...val}
            this.error_response = {}
        },

        error_fields (val) {
            const status = val ? true : false
            this.$emit('error', status)
        },

        revertItem (val, oldVal) {
            if (this.revert_target === 'title') {
                this.editItem.title = val.title
                this.error_response.title = null
                return
            }
            this.editItem.description = val.description
            this.error_response.description = null
        },
    },

    computed: {
        error_fields () {
            if (!Object.keys(this.editItem).length) return
            const title_error = (!this.editItem.title || (this.editItem.title && this.editItem.title.length > 255))
            const description_error = (this.editItem.description && this.editItem.description.length > 50000)
            return !!(title_error || description_error)

        },

        local_title_modified() {
            const title_modified =  (this.editItem.title && this.storedItem.title !== this.editItem.title.trim())
            return title_modified
        },

        local_description_modified() {
            const description_modified = (this.storedItem.description !== this.editItem.description)
            return description_modified
        },

        ...mapState('Comment', {
            comment_list: 'task_comments',
            comment_item: 'item',
            comment_response: 'response',
        }),

        ...mapState('Task', {
            task_response: 'response',
        }),

        ...mapState('Milestone', {
            milestone_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        local_update (field) {
            this.title_edit_mode = false
            this.description_edit_mode = false

            if (!field) return this.$emit('update', this.editItem)

            if (field === 'title') {
                if (!this.local_title_modified || this.error_response.title) return
                this.storedItem.title = this.editItem.title
                return this.$emit('update', { title: this.editItem.title })
            }

            if (!this.local_description_modified || this.error_response.description) return
            this.storedItem.description = this.editItem.description
            this.$emit('update', { description: this.editItem.description })
        },

        async local_comment_visibility_toggle (comment) {
            comment.visibility = comment.visibility === 'external' ? 'internal' : 'external'
            comment.type = 'Task'
            await this.comment_visible(comment)
        },

        local_set_validation (value) {
            if (value !== undefined) this.validationError = value
            return this.validationError
        },

        local_show_est_edit (durationText) {
            this.localEstText = durationText
            this.showEstimateEditForm = true
        },

        local_update_est () {
            this.$emit('update-est', {value: this.localEstText, validation: this.local_set_validation})
            this.$nextTick(() => {
                if (!this.validationError) this.local_close_est_edit()
            })
        },

        local_close_est_edit () {
            this.local_set_validation(null)
            this.localEstText = null
            this.showEstimateEditForm = false
        },

        local_clear_date(type) {
            this.editItem[type] = null
            this.local_update()
        },

        local_due_date_passed (date, completed_at) {
            return moment(date).isBefore() && !completed_at
        },

        permission_task (type) {
            return this.$can(`tasks.${type}`) && this.$can('projects.update-only')
        },

        local_validation (category) {
            if (category === 'title') {
                if (!this.editItem.title) this.error_response.title = 'Required field.'
                else if (!!this.editItem.title && this.editItem.title.length > 255) this.error_response.title = 'Only maximum of 255 characters allowed.'
                else this.error_response.title = null
            }
            if (category === 'description') {
                if (!!this.editItem.description && this.editItem.description.length > 50000) this.error_response.description = 'Only maximum of 50000 characters allowed.'
                else this.error_response.description = null
            }
        },

        async local_revert_changes (type) {
            this.title_edit_mode = false
            this.description_edit_mode = false
            this.revert_mode = true
            this.revert_target = type
            this.$emit('revert', this.storedItem)
        },

        local_close () {
            this.$emit('close')
            this.local_close_est_edit()
            this.error_response = {}
        },

        local_focus(type) {
            if (type === 'title') {
                this.title_edit_mode = true
                this.description_edit_mode = false
                this.revert_mode = false
                return
            }
            this.title_edit_mode = false
            this.description_edit_mode = true
            this.revert_mode = false
        },

        ...mapActions('Comment', {
            comment_visible: 'visible',
        }),
    }
}
</script>

<style scoped>
.task-detail-pane {
    transition: 0.3s all;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    height: 100vh;
    width: 520px;
    overflow-y: scroll;
    transform: translateX(520px);
}

.task-detail-pane.visible {
    transform: translateX(0);
}

.c-update-btn.v-btn.v-btn--disabled {
    opacity: 0.7;
    background-color: #C8E6C9 !important;
    color: #81C784 !important;
}
</style>

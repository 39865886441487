<template>
    <div>
        <InternalTicketView @unsaved="isTicketUpdate = true" @saved="isTicketUpdate = false" v-if="localUserIsExternal" />
        <ExternalTicketView @unsaved="isTicketUpdate = true" @saved="isTicketUpdate = false" v-else />

         <!--Leaving page without save alert -->
         <a-dialog v-model="dialogLeavePageModal" persistent max-width="400">
            <SCancelChanges
                @success="pageToLeave ? pageToLeave() : {}"
                @close="dialogLeavePageModal = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import InternalTicketView from '@/pages/Projects/ProjectsTicketsView.vue'
import ExternalTicketView from '@/pages/External/ExternalProjects/ExternalProjectsTicketsView.vue'
import SCancelChanges from '/src/components/Shared/SharedCancelChanges'

export default {
    components: {
        ExternalTicketView,
        InternalTicketView,
        SCancelChanges
    },
    data () {
        return {
            dialogLeavePageModal: false,
            pageToLeave: null,
            isTicketUpdate: false,
        }
    },

    beforeRouteLeave (toPage, fromPage, nextRoute) {
        if (!this.isTicketUpdate) return nextRoute()
        nextRoute(false)
        this.dialogLeavePageModal = true
        this.pageToLeave = nextRoute
    },

    computed: {
        localUserIsExternal() {
            return this.user_self && this.user_self.scope === 'internal'
        },

        ...mapState('User', {
            user_self: 'self'
        })
    }
}
</script>

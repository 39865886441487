const report_blocks = '/api/report-blocks'
// const report_blocks_attr = '/api/report-blocks-attributes'
// const report_templates = '/api/report-templates'
const report_templates_blocks = '/api/report-template-blocks'
const report_templates_blocks_attr = '/api/report-template-block-attributes'

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        block_template: {},
        meta: {},
        filters: {
            status: 'all',
            include: 'reportBlockAttributes',
            page: 1,
            count: 10,
            sort_direction: 'asc'
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {
                status: 'all',
                page: 1,
                count: 10,
                sort_field: 'company_name',
                sort_direction: 'asc'
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        ADD_BLOCK_TEMPLATE(state, payload) {
            state.block_template = payload
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        MEMBER_ITEM(state, member_item) {
            state.member_item = member_item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'uuid': payload.uuid });
            state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, uuid) {
            let index = _.findIndex(state.list, { 'uuid': uuid });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        async actionBlockIndex (context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item !== '';
            });
            try {
                const { data: { data: response } } = await axios.get(report_blocks, { params: query_filters })
                context.commit('UPDATE_LIST', response)
                this.dispatch('Interface/loader_stop')
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        async actionStoreTemplateBlock(context, payload) {
            try {
                const { data: { data: response } } = await axios.post(report_templates_blocks, payload, { params: { include: 'reportBlock' } })
                // console.log(response)
                context.commit('ADD_BLOCK_TEMPLATE', response)
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionEditTemplateBlock(context, payload) {
            try {
                const { data: { data: response } } = await axios.patch(`${report_templates_blocks}/${payload.uuid}`, payload)
                // console.log(response)
                // context.commit('ADD_BLOCK_TEMPLATE', response)
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },

        async actionStoreTemplateBlockAttrs (context, payload) {
            try {
                const { data: { data: response } } = await axios.post(report_templates_blocks_attr, payload)
                // console.log(response)
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        async actionEditTemplateBlockAttr (context, payload) {
            try {
                const { data: { data: response } } = await axios.patch(report_templates_blocks_attr + '/' + payload.uuid, payload)
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
        clear (context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item (context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        member_clear_item (context) {
            return new Promise((resolve, reject) => {
                context.commit('MEMBER_ITEM', _.cloneDeep(context.state.defaults.member_item));
                resolve('Cleared');
            });
        },
    },

    getters: {
        getBlocksList: (state) => {
            const list = [...state.list]
            return list
        },
        getBlockTemplate: (state) => {
            const block = { ...state.block_template }
            return block
        }
    }
}

<template>
	<a-main>
        <a-container container--fluid grid-list-xl>
            <a-layout align-center class="mt-2">
                <a-flex>
                    <h2 class="md-heading-6 indigo--text text--darken-1 font-weight-semibold"><a-icon size="24" color="indigo" class="mr-1 u-icon-nudge">menu_open</a-icon>Logs</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Success and error logs from workflow runs.</p>
                </a-flex>
            </a-layout>
            <template v-if="pageLoading">
                <PJiraLogListLoader/>
            </template>
            <template v-if="!pageLoading">
                <a-layout wrap align-center class="mt-2">
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="logSourceDropdown" max-width="224">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="197" v-on="on">
                                    <span class="md-body-2 grey--text text--darken-2 mx-1">
                                        <a-icon size="20" :color="localIsSourcesFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">{{ selectedSource.label }}</span>
                                    </span>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'source'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ logSourceDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item v-for="user_source in logSources" @click="localLogSourcesfilter(user_source)" class="pr-5" :class="{ 'grey lighten-4': selectedSource.value === user_source.value }" :key="user_source.value">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">{{ user_source.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item class="pr-5" @click="localLogDefaultSourcesFilter()">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Sources</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="workflowDropdown" :close-on-content-click="false" max-width="500" min-width="500">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="264" v-on="on">
                                    <div class="md-body-2 grey--text text--darken-2 mx-1 u-flex align-center">
                                        <a-icon size="20" :color="localIsWorkflowFilter ? 'blue' : 'grey darken-1'"  class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">
                                            <template v-if="selectedWorkflows && selectedWorkflows.length === 1 && selectedWorkflows[0] !== 'All workflows' && selectedWorkflows[0] !== 'No workflow'">
                                                {{ localGetWorlflowLabel(selectedWorkflows[0]) | truncateText(22) }}
                                            </template>
                                            <template v-else-if="selectedWorkflows && selectedWorkflows.length > 1">{{ selectedWorkflows.length + ' Selected' }}</template>
                                            <template v-else-if="selectedWorkflows[0] === 'No workflow'">No workflow</template>
                                            <template v-else>All Workflows</template>
                                        </span>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'workflow'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ workflowDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed">
                                <a-sheet class="c-input-wrap px-2">
                                    <a-text-field
                                        v-model="searchWorkflow"
                                        placeholder="Search Workflow"
                                        background-color="neutral"
                                        class="md-body-2 font-weight-normal"
                                        solo flat hide-details autofocus
                                    >
                                    </a-text-field>
                                </a-sheet>
                            </a-list>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item v-for="workflow in localFilteredWorkflowList" class="pr-5" :key="workflow.key" @click="localLogworkflowFilter(workflow.id)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="blue darken-2" class="ma-0 pa-0" :value="localLogWorkflowIsSelected(workflow.id)"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1" :title="workflow.title">{{ workflow.title | truncateText(60) }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                                <a-list-item v-if="searchWorkflow && (localFilteredWorkflowList && !localFilteredWorkflowList.length)">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">No Workflows Found</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y">
                                <a-list-item class="pr-5" @click="localLogworkflowFilter()">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Workflows</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="statusDropdown" :close-on-content-click="false" max-width="224">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="197" v-on="on">
                                    <span class="md-body-2 grey--text text--darken-2 u-flex mx-1">
                                        <a-icon size="20" :color="localIsStatusFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">
                                            <template v-if="selectedStatus && selectedStatus.length === 1">
                                                <span class="md-body-2 grey--text text--darken-3 ml-1 text-capitalize">{{ localLogGetStatusValue(selectedStatus[0]) }}</span>
                                            </template>
                                            <template v-else-if="selectedStatus && selectedStatus.length > 1">{{ selectedStatus.length + ' Selected' }}</template>
                                            <template v-else>All Status</template>
                                        </span>
                                    </span>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'status'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ statusDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y" style="max-height: 300px;">
                                <a-list-item v-for="(status, index) in logStatus" class="pr-5" :key="'status'+index" @click="localLogStatusFilter(status.value)">
                                    <a-list-item-icon class="ma-0 pa-0 u-flex justify-center">
                                        <a-checkbox dense hide-details color="blue darken-2" :value="localLogStatusIsSelected(status.value)" class="ma-0 pa-0"></a-checkbox>
                                    </a-list-item-icon>
                                    <a-list-item-title>
                                        <div class="u-flex">
                                            <span class="md-body-2 grey--text text--darken-3 ml-1">{{ status.label }}</span>
                                            <a-spacer></a-spacer>
                                            <div class="c-filter-status" :class="['c-filter-'+status.value]"></div>
                                        </div>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                            <a-divider></a-divider>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item class="pr-5" @click="localDefaultStatusFilter()">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">All Status</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink pr-2>
                        <a-menu bottom offset-y v-model="timeDropdown" max-width="224">
                            <template v-slot:activator="{ on }">
                                <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" height="40" width="197" v-on="on">
                                    <span class="md-body-2 grey--text text--darken-2 mx-1">
                                        <a-icon size="20" :color="selectedTime && selectedTime.value !== 1 && localIsTimeFilter ? 'blue' : 'grey darken-1'" class="mr-1 u-icon-nudge">filter_list</a-icon>
                                        <span class="grey--text text--darken-1 font-weight-normal">{{ selectedTime.label }}</span>
                                    </span>
                                    <a-spacer></a-spacer>
                                    <a-progress-circular v-if="filterLoading === 'time'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                                    <a-icon v-else size="20" class="ma-0">arrow_drop_{{ timeDropdown ? 'up' : 'down' }}</a-icon>
                                </a-sheet>
                            </template>
                            <a-list class="u-list-condensed u-overflow-y pt-0" style="max-height: 300px;">
                                <a-list-item v-for="local_time in logTimes" class="pr-5" @click="localLogTimefilter(local_time)" :class="{ 'grey lighten-4': selectedTime.value === local_time.value }" :key="local_time.value">
                                    <a-list-item-title>
                                        <span class="md-body-2 grey--text text--darken-3 ml-1">{{ local_time.label }}</span>
                                    </a-list-item-title>
                                </a-list-item>
                            </a-list>
                        </a-menu>
                    </a-flex>
                    <a-flex shrink v-if="(localIsTimeFilter && selectedTime && selectedTime.value !== 1) || localIsSourcesFilter || localIsWorkflowFilter || localIsStatusFilter || localIsProjectFilter">
                        <a-sheet :ripple="true" class="u-rounded-corners u-overflow-hidden u-elevation-custom-1 py-1 px-2 u-flex align-center u-cursor-pointer" @click="localClearLogFilters()" height="40" width="157">
                            <a-icon size="20" color="grey darken-1" class="mr-1">highlight_off</a-icon>
                            <span class="grey--text text--darken-1 font-weight-normal">Clear all filters</span>
                                <a-spacer></a-spacer>
                            <a-progress-circular v-if="filterLoading === 'clear'" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                        </a-sheet>
                    </a-flex>
                </a-layout>
                <template v-if="!pageLoading && workflow_log_list && workflow_log_list.length === 0">
                    <a-container container--fluid grid-list-xl class="my-4">
                        <a-layout align-center justify-center>
                            <a-flex xs4>
                                <div class="text-center"><a-icon size="160" color="grey lighten-1">menu_open</a-icon></div>
                                <h2 class="md-heading-5 mb-1 grey--text text--darken-3 text-center">No Logs</h2>
                                <p class="md-body-2 mb-2 grey--text text--darken-1 text-center">Setup the workflow to automate data sharing between Jira and Success.</p>
                            </a-flex>
                        </a-layout>
                    </a-container>
                </template>
                <template v-if="workflow_log_list && workflow_log_list.length">
                   <PJiraLogList
                        :logList="workflow_log_list"
                    />
                </template>
                <a-layout class="mx-0 c-sticky-paginate-parent u-elevation-custom-1 u-rounded-corners" v-if="workflow_log_list && workflow_log_list.length" :class="{ 'pa-4': !showPaginateSection }">
                    <a-flex class="u-flex align-center py-0">
                        <div class="md-body-2 grey--text text--darken-2 font-weight-medium mr-2 d-inline-block">Showing {{ workflow_log_meta.from }} - {{ workflow_log_meta.to }} out of {{ workflow_log_meta.total }} log{{ workflow_log_meta.total > 1 ? 's' : '' }}</div>
                    </a-flex>
                    <a-spacer></a-spacer>
                    <a-flex class="mr-16 pr-16 py-0 u-flex align-center justify-end" v-if="showPaginateSection">
                        <a-progress-circular v-if="pageLoading" color="orange darken-2" size="16" width="2" indeterminate></a-progress-circular>
                        <div class="text-right">
                            <a-pagination
                                v-model="workflow_log_meta.current_page"
                                :length="workflow_log_meta.last_page"
                                :total-visible="10"
                                class="c-inventory-pagination py-1"
                                @input="localFetchPaginate()"
                            ></a-pagination>
                        </div>
                    </a-flex>
              </a-layout>
            </template>
        </a-container>
	</a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import { SLogStatus } from '@/config/config-shared-components'
import PJiraLogList from './Partials/JiraLogList'
import PJiraLogListLoader from './Partials/PartialJiraLogListLoader'


export default {
    mixins: [loadWorkspace],
    components: {
        SLogStatus,
        PJiraLogList,
        PJiraLogListLoader,
    },
    data() {
        return {
            accordionLog: [],
            selectedWorkflows: [],
            selectedProjects: [],
            selectedStatus: [],

            selectLogitem: null,
            filterLoading: null,
            searchTimeout: null,
            pageCount: null,
            searchWorkflow: '',
            searchProject: '',

            dialogPayloadInformation: false,
            logSourceDropdown: false,
            statusDropdown: false,
            timeDropdown: false,
            workflowDropdown: false,
            projectDropdown: false,
            pageLoading: false,
            paginationLoading: false,
            projectLoading: false,
            dialogLogEntriesInformation: false,
            localLogEntriesLoading: false,

            logSources: [
                { label: 'From Success', value: 'outgoing' },
                { label: 'From Jira', value: 'incoming' },
            ],
            selectedSource: { label: 'All Sources', value: 'all' },
            logStatus: [
                { label: 'Incomplete', value: 'incomplete' },
                { label: 'Success', value: 'success' },
                { label: 'Invalid', value: 'invalid' },
                { label: 'Error', value: 'error' },
            ],
            logTimes: [
                { label: 'Last 1 hour', value: 1 },
                { label: 'Last 8 hour', value: 8 },
                { label: 'Last 24 hour', value: 24 },
            ],
            selectedTime: { label: 'All time', value: 0 },
            LOG_FILTERS: {
                PAGE: 'page',
                TIGGER_RESOURCE_PROJECT: 'filter[trigger_resource_id]',
                PROJECT_ID: 'filter[project_id]',
                ACTION_RESOURCE_PROJECT: 'filter[action_resource_id]',
                STATUS: 'filter[status]',
                WORKFLOW_TYPE: 'filter[workflow_type]',
                WORKFLOW_LOGS: 'fields[workflow_logs]',
                WORKFLOWS: 'fields[workflow]',
                WORKFLOW_ID: 'filter[workflow_id]',
                WORKFLOW_TIGGER: 'fields[workflow.trigger]',
                WORKFLOW_ACTION: 'fields[workflow.action]',
                WORKFLOW_TIGGER_SERVICES: 'fields[workflow.trigger.service]',
                WORKFLOW_ACTION_SERVICES: 'fields[workflow.action.service]',
                WORKFLOW_MAPPINGS: 'fields[workflow.mappings]',
                INCLUDE: 'include',
                SORT: 'sort',
                TIME: 'filter[created_at]',
                SOURCE: 'filter[source]',
                COUNT: 'limit',
            },

            logFilters: {},
            PROJECT_FILTERS: {
                FIEDS: 'fields[projects]',
                WORKSPACE: 'filter[workspace_id]',
                TITLE: 'filter[title]',
                SORT: 'sort',
                COUNT: 'count',
            },
            project_filters: {},
        }
    },
    mounted() {
        this.localIndex()
    },
    computed: {
        localFilteredWorkflowList() {
            return this.workflow_list.filter(list => list.title.toLowerCase().includes(this.searchWorkflow.toLowerCase()))
        },
        
        showPaginateSection () {
            return this.workflow_log_meta.current_page >= 1 && this.workflow_log_meta.last_page !== 1 && this.workflow_log_meta.total > 100
        },

        localIsSourcesFilter() {
            return this.selectedSource && this.selectedSource.label !== 'All Sources'
        },

        localIsTimeFilter() {
            return this.selectedTime && this.selectedTime.label !== 'All time'
        },

        localIsWorkflowFilter() {
            return this.selectedWorkflows && this.selectedWorkflows.length
        },

        localIsStatusFilter() {
            return this.selectedStatus && this.selectedStatus.length
        },

        localIsProjectFilter() {
            return this.selectedProjects && this.selectedProjects.length
        },

        ...mapState('Project', {
            project_list: 'list',
        }),

        ...mapState('WorkflowLog', {
            workflow_log_list: 'list',
            workflow_log_meta : 'meta',
            workflow_log_filters: 'filters',
        }),

        ...mapState('Workflow', {
            workflow_list: 'list',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
      
    },
    methods: {
        async localIndex() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.pageLoading = false
                return this.$router.replace({name: 'errors-unauthorized'})
            }
            
            this.pageLoading = true
            this.loadFilterData()
            await this.workflow_log_clear()
            await this.localDefaultTimeFilter()
            await this.localSetWorkflowFilter()
            await this.localFetchWorkflowLog(this.logFilters)
            
            this.pageCount = this.workflow_log_meta.current_page
            this.pageLoading = false
        },

        localSetWorkflowFilter() {
            this.logFilters[this.LOG_FILTERS.WORKFLOW_TYPE] = 'jira'
            this.logFilters[this.LOG_FILTERS.WORKFLOW_LOGS] = 'id,status,code,conditions,mappings,action_resource_type,trigger_resource_type,trigger_resource_id,action_resource_id,request_payload,response,project_id,workflow_id,created_at'
            this.logFilters[this.LOG_FILTERS.WORKFLOWS] = 'id,title,direction,applet_trigger_id,applet_action_id'
            this.logFilters[this.LOG_FILTERS.WORKFLOW_TIGGER] = 'applet_triggers.id,applet_triggers.applet_service_id',
            this.logFilters[this.LOG_FILTERS.WORKFLOW_ACTION] = 'applet_actions.id,applet_actions.slug,applet_actions.applet_service_id'
            this.logFilters[this.LOG_FILTERS.WORKFLOW_TIGGER_SERVICES] = 'applet_services.id,applet_services.slug',
            this.logFilters[this.LOG_FILTERS.WORKFLOW_ACTION_SERVICES] = 'applet_services.id,applet_services.slug',
            this.logFilters[this.LOG_FILTERS.INCLUDE] = 'workflow.trigger.service,workflow.action.service,workflow.mappings'
            this.logFilters[this.LOG_FILTERS.WORKFLOW_MAPPINGS] = 'workflow_mappings.id,workflow_mappings.field_name,workflow_mappings.value,workflow_mappings.is_valid'
            this.logFilters[this.LOG_FILTERS.SORT] = '-created_at'
            this.logFilters[this.LOG_FILTERS.TIGGER_RESOURCE_PROJECT] = null 
            this.logFilters[this.LOG_FILTERS.ACTION_RESOURCE_PROJECT] = null
            this.logFilters[this.LOG_FILTERS.PROJECT_ID] = null
            this.logFilters[this.LOG_FILTERS.STATUS] = 'all'
            this.logFilters[this.LOG_FILTERS.WORKFLOW_ID] = 'all'
            this.logFilters[this.LOG_FILTERS.TIME] = 1
            this.logFilters[this.LOG_FILTERS.PAGE] = 1
            this.logFilters[this.LOG_FILTERS.COUNT] = 100
        },

        async localDefaultTimeFilter() {
            this.selectedTime.value = 1
            this.selectedTime.label = 'Last 1 hour'
        },

        loadFilterData () {
          //  this.localLoadProjects()
            this.localLoadWorkflows()
        },

        localLoadWorkflows () {
            this.workflow_index({ 'filter[installation_id]': this.$route.params.id })
        },

        async localFetchPaginate () {
            if(this.pageCount == this.workflow_log_meta.current_page) return
            this.pageLoading = true
            await this.$vuetify.goTo(0)
            this.logFilters[this.LOG_FILTERS.PAGE] = this.workflow_log_meta.current_page
            this.logFilters[this.LOG_FILTERS.COUNT] = this.workflow_log_meta.per_page
            await this.localFetchWorkflowLog(this.logFilters)
            this.pageCount = this.workflow_log_meta.current_page
            this.pageLoading = false
        },

        async localFetchWorkflowLog(logFilters) {
            await this.workflow_log_index(logFilters)
        },

        localSelectLogItem (item, type) {
            this.selectLogitem = item
            this.selectLogitem.payload_type = type
            this.dialogPayloadInformation = true
        },

        localGetWorkflowTitle(workflow_id) {
            const workflow = this.workflow_list.find(item => item.workflow_id === workflow_id)
            return workflow ? workflow.label : ''
        },

        async localProjectFilter(project_id = null) {
            this.filterLoading = 'project'
            if (!project_id) return this.localDefaultProjectFilter()

            if (this.selectedProjects && !this.selectedProjects.length) this.selectedProjects.push(project_id)
            else {
                const index = this.selectedProjects.findIndex(id => id === project_id)
                if (index !== -1) this.selectedProjects.splice(index, 1)
                else this.selectedProjects.push(project_id)
            }

            this.logFilters[this.LOG_FILTERS.PROJECT_ID] = this.selectedProjects
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localDefaultProjectFilter() {
            this.localDefaultFilter()
            this.selectedProjects = []
            this.projectDropdown = false
            this.logFilters[this.LOG_FILTERS.PROJECT_ID] = null
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        localGetProjectTitle (project_id) {
            const project = this.project_list.find(item => item.id === project_id)
            return project ? project.title : ''
        },

        localProjectIsSelected (project_id) {
            return this.selectedProjects.includes(project_id)
        },

        async localSearchProjects () {            
            clearTimeout(this.searchTimeout)
            this.searchTimeout = window.setTimeout(async () => {
                this.projectLoading = true
                this.project_filters[this.PROJECT_FILTERS.TITLE] = this.searchProject
                this.project_filters[this.PROJECT_FILTERS.COUNT] = this.searchProject ? 999 : 20
                await this.localFetchProjects()
                this.projectLoading = false
            }, 1000)
        },

        async localLoadProjects () {
            this.projectLoading = true
            this.project_filters[this.PROJECT_FILTERS.SORT] = 'title'
            this.project_filters[this.PROJECT_FILTERS.COUNT] = 20
            await this.localFetchProjects()
            this.projectLoading = false
        },

        async localFetchProjects () {
            this.project_filters[this.PROJECT_FILTERS.FIEDS] = 'id,title'
            await this.project_index(this.project_filters)
        },

        async localLogSourcesfilter(sources = null) {
            this.filterLoading = 'source'
            this.selectedSource.label = sources.label
            this.selectedSource.value = sources.value
            this.localDefaultFilter()
            this.logFilters[this.LOG_FILTERS.SOURCE] = sources.value
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localLogDefaultSourcesFilter() {
            this.filterLoading = 'source'
            this.selectedSource.label = 'All Sources'
            this.selectedSource.value = 'all'
            this.logFilters[this.LOG_FILTERS.SOURCE] = this.selectedSource.value
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },
        
        async localLogTimefilter(time) {
            this.filterLoading = 'time'
            this.selectedTime.label = time.label
            this.selectedTime.value = time.value
            this.localDefaultFilter()
            this.logFilters[this.LOG_FILTERS.TIME] = time.value
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localLogStatusFilter(value = null) {
            this.filterLoading = 'status'
            if (!value) return this.localDefaultStatusFilter()

            if (this.selectedStatus && !this.selectedStatus.length) this.selectedStatus.push(value)
            else {
                const index = this.selectedStatus.findIndex(id => id === value)
                if (index !== -1) this.selectedStatus.splice(index, 1)
                else this.selectedStatus.push(value)
            }

            this.localDefaultFilter()
            this.logFilters[this.LOG_FILTERS.STATUS] = this.selectedStatus
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localDefaultStatusFilter() {
            this.selectedStatus = []
            this.statusDropdown = false
            this.logFilters[this.LOG_FILTERS.STATUS] = null
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        localLogStatusIsSelected (value) {
            return this.selectedStatus.includes(value)
        },

        localLogGetStatusValue (value) {
            const status = this.logStatus.find(item => item.value === value)
            return status ? status.value : ''
        },

        async localLogworkflowFilter(workflow_id = null) {
            this.filterLoading = 'workflow'
            if (!workflow_id) return this.localDefaultWorkflowFilter()

            if (this.selectedWorkflows && !this.selectedWorkflows.length) this.selectedWorkflows.push(workflow_id)
            else {
                const index = this.selectedWorkflows.findIndex(id => id === workflow_id)
                if (index !== -1) this.selectedWorkflows.splice(index, 1)
                else this.selectedWorkflows.push(workflow_id)
            }
            this.localDefaultFilter()
            this.logFilters[this.LOG_FILTERS.WORKFLOW_ID] = this.selectedWorkflows
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        localGetWorlflowLabel(id) {
            const workflow = this.workflow_list.find(item => item.id === id)
            return workflow ? workflow.title : ''
        },

        localLogWorkflowIsSelected (key) {
            return this.selectedWorkflows.includes(key)
        },

        async localDefaultWorkflowFilter() {
            this.selectedWorkflows = []
            this.workflowDropdown = false
            this.logFilters[this.LOG_FILTERS.WORKFLOW_ID] = null
            await this.localFetchWorkflowLog(this.logFilters)
            this.filterLoading = null
        },

        async localClearLogFilters() {
            this.filterLoading = 'clear'
            this.localSelectedClear()
            this.pageLoading = true
            await this.localFetchWorkflowLog(this.logFilters)
            this.pageLoading = false
            this.filterLoading = null
        },

        localSelectedClear() {
            this.selectedWorkflows = []
            this.selectedProjects = []
            this.selectedStatus = []
            this.selectedSource.label = 'All Sources'
            this.selectedSource.value =  'all'
            this.searchWorkflow = ''
            this.searchProject = null
            this.logSourceDropdown= false
            this.statusDropdown= false
            this.timeDropdown= false
            this.workflowDropdown= false
            this.projectDropdown= false
            this.localSearchProjects()
            this.localDefaultTimeFilter()
            this.localSetWorkflowFilter()
        },

        localDefaultFilter() {
            this.logFilters[this.LOG_FILTERS.PAGE] = 1
            this.logFilters[this.LOG_FILTERS.COUNT] = 100
        },

        ...mapActions('Project', {
            project_index: 'index',
            project_clear: 'clear',
        }),

        ...mapActions('Workflow', {
            workflow_index: 'index',
        }),
        ...mapActions('WorkflowLog', {
            workflow_log_index: 'index',
            workflow_log_clear: 'clear',
        }),
    },
}
</script>

<style scoped>
.c-filter-status{
    width: 11px;
    height: 11px;
    border-radius: 50%;
}
.c-filter-success{
    background: #7aee80 !important;
}
.c-filter-error{
    background: #fa96a0 !important;
}
.c-filter-invalid{
    background: #E0E0E0 !important;
}
.c-filter-incomplete {
    background: #fce49e !important;
}
</style>

<template>
    <a-card>
        <div class="px-7 py-6 u-flex-center-y">
            <div class="u-flex-center-y">
                <a-avatar size="40" color="primary">
                    <a-icon size="24" color="white">domain_verification</a-icon>
                </a-avatar>
                <h3 class="text-h6 grey--text text--darken-3 font-weight-medium ml-3">Move Workspace</h3>
            </div>
            <a-spacer></a-spacer>
            <a-icon size="20" color="grey darken-2" @click="localClose()">clear</a-icon>
        </div>

        <a-divider></a-divider>

        <a-sheet class="pa-6 px-7 u-overflow-y" max-height="600">
            <div>
                <span class="md-md-body-2 grey--text text--darken-2 d-inline-block mb-3">Workspace</span>
                <a-autocomplete
                    v-model="selectedWorkspace"
                    :items="workspaceList"
                    item-text="title"
                    item-value="id"
                    placeholder="Select Workspace"
                    background-color="grey lighten-5"
                    class="u-border u-rounded-corners-lg"
                    :menu-props="{ rounded: 'lg', nudgeBottom: '4px' }"
                    @input="localGetWorkspaceStages(selectedWorkspace.id)"
                    solo flat hide-details return-object
                ></a-autocomplete>
            </div>

            <template v-if="!localIsSameWorkspace">
                <div class="mt-4" v-if="showStageChange">
                    <span class="md-md-body-2 grey--text text--darken-2 d-inline-block mb-3">Stage</span>
                    <a-autocomplete
                        v-model="selectedStage"
                        :items="stage_list"
                        item-text="name"
                        item-value="id"
                        placeholder="Select Stage"
                        background-color="grey lighten-5"
                        class="u-border u-rounded-corners-lg"
                        :menu-props="{ rounded: 'lg', nudgeBottom: '4px' }"
                        :disabled="loadingWorkspace"
                        solo flat hide-details return-object
                    ></a-autocomplete>
                </div>

                <!-- Result for completed stage -->
                <PResultUpdate
                    v-if="selectedStage.id && selectedStage.status === 'completed'"
                    @update="localResultUpdate"
                    class="mt-5"
                ></PResultUpdate>
            </template>
        </a-sheet>

        <a-divider></a-divider>

        <div class="u-flex-center-y pa-6 px-7">
            <a-btn depressed class="primary white--text u-rounded-corners" :disabled="localCheckDisabledConditions" :loading="loading" @click="localUpdate()">Move Workspace</a-btn>
            <a-btn depressed class="ml-3 grey--text text--darken-1 u-rounded-corners" @click="localClose()">Cancel</a-btn>
        </div>
    </a-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { PResultUpdate } from '../Partials'

export default {
    props: {
        currentWorkspaceId: {
            type: String
        },
        workspaceList: {
            type: Array
        },
        currentStage: {
            type: Object
        },
        isOpen: {
            type: Boolean
        },
    },

    components: {
        PResultUpdate
    },

    data () {
        return {
            selectedWorkspace: null,
            selectedStage: {},
            resultItem: {},
            loadingWorkspace: false,
            showStageChange: false,
            loading: false,
        }
    },

    watch: {
        isOpen (val) {
            if (val) this.localIndex()
        }
    },

    mounted () {
        this.localIndex()
    },

    computed: {
        localIsSameWorkspace () {
            return this.selectedWorkspace === this.currentWorkspaceId || (this.selectedWorkspace && (this.selectedWorkspace.id === this.currentWorkspaceId))
        },

        localIsStageSelected () {
            return this.selectedStage && this.selectedStage.id
        },

        localIsResultSelected () {
            const stageSelected = this.selectedStage && this.selectedStage.status === 'completed'
            const resultSelected = this.resultItem && (this.resultItem.result && this.resultItem.result.id)
            return stageSelected && !resultSelected
        },

        localCheckDisabledConditions () {
            const commentError = !!(this.resultItem && this.resultItem.commentError)
            return this.localIsSameWorkspace || !this.localIsStageSelected || this.localIsResultSelected || commentError
        },

        ...mapState('Meta', {
            meta_list: 'list'
        }),

        ...mapState('Stage', {
            stage_list: 'list'
        }),

        ...mapState('MetaResultReason', {
            meta_result_reason_list: 'list',
        })
    },

     methods: {
         localIndex () {
            this.selectedWorkspace = this.currentWorkspaceId
            this.showStageChange = false
            this.selectedStage = {}
            this.resultItem = {}
         },

         localResultUpdate (resultItem) {
             this.resultItem = resultItem
         },

         async localGetWorkspaceStages (workspace_id) {
             if (this.currentWorkspaceId === workspace_id) return

            this.showStageChange = true
             this.selectedStage = {}
             this.loadingWorkspace = true
             await this.stage_index({ 'filter[workspace_id]': workspace_id })
             this.loadingWorkspace = false
         },

         localUpdate () {
             if (this.localIsSameWorkspace) return
             this.loading = true
             this.$emit('update', {
                 stage: this.selectedStage,
                 workspace_id: this.selectedWorkspace.id ?? this.selectedWorkspace,
                 result: this.resultItem,
                 resetLoading: this.localCancelLoading
             })
         },

         localClose () {
             this.$emit('close', this.localCancelLoading)
         },

         localCancelLoading () {
            this.loading = false
            this.selectedWorkspace = this.currentWorkspaceId
            this.selectedStage = {}
            this.resultItem = {}
         },

         ...mapActions('Stage', {
            stage_index: 'index',
        }),

         ...mapActions('MetaResultReason', {
            meta_result_reason_index: 'index',
            meta_result_reason_clear: 'clear',
        }),
    }
}
</script>

<style lang="scss" scoped></style>

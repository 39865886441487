const api_endpoint = '/tag-sections';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        filters: { 
            page: 1,
        },
        response: [],
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {
                page: 1,
            },
            response: [],
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        UPSERT_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            if (index !== -1) state.list[index] = Object.assign(state.list[index], payload)
            else state.list.push(payload);
        },
        UPDATE_LIST_AND_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            if (index !== -1) state.list[index] = Object.assign(state.list[index], payload)
            if (state.item && state.item.id === payload.id || !state.item.id) state.item = payload
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return ext_axios.get(api_endpoint, { params: { ...payload } })
                .then((response) => {
                    if (query_filters.page == 1) {
                        context.commit('UPDATE_LIST', response.data.data);
                    } else {
                        context.commit('APPEND_LIST', response.data.data);
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return ext_axios.get(api_endpoint + '/' + payload.id)
                .then(async (response) => {
                    if (payload.upsert) context.commit('UPSERT_ITEM', response.data.data)
                    else context.commit('UPDATE_LIST_AND_ITEM', response.data.data)

                    if (payload.mode && payload.mode === 'show') context.commit('UPDATE_ITEM', response.data.data)
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        clear_response(context) {
            return new Promise((resolve, reject) => {
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                resolve('Cleared');
            });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },

    getters: {
        list: (state) => {
            const list = [...state.list]
            return list
        },
        item: (state) => {
            const item = { ...state.item }
            return item
        },
        response: (state) => {
            const response = { ...state.response }
            return response
        },
        form_mode: (state) => state.form_mode,
    }
}

<template>
    <v-checkbox v-model="showModel" v-on="$listeners" v-bind="$attrs">
        <template #label><slot name="label"></slot></template>
    </v-checkbox>
</template>

<script>
import { VCheckbox } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VCheckbox },
    props: ['value'],

    data () {
        return {
            ref: null,
        }
    },

    mounted () {
        this.ref = this.$el
    },

    computed: {
        showModel: {
            get () {
                if (this.$attrs['input-value']) return this.$attrs['input-value']
                return this.value
            },
            set (val) {
                this.$emit('input', val)
            }
        },

        // reducedAttrs () {
        //     const attrs = Object.assign({}, this.$attrs)
        //     return attrs
        // }
    }
}
</script>

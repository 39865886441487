const api_endpoint = '/template-notes';

export default {
    namespaced: true,
    state: {
        list: [],
        item: {},
        meta: {},
        filters: {
            status: 'all',
            template_group_id: null,
            page: 1,
            count: 10,
            sort_field: 'created_at',
            sort_direction: 'desc'
        },
        response: {},
        defaults: {
            list: [],
            item: {},
            meta: {},
            filters: {
                status: 'all',
                template_group_id: null,
                page: 1,
                count: 10,
                sort_field: 'created_at',
                sort_direction: 'desc'
            },
            response: {},
        },
        form_mode: 'add',
    },
    mutations: {
        UPDATE_LIST(state, items) {
            state.list = items;
        },
        UPDATE_ITEM(state, item) {
            state.item = item;
        },
        UPDATE_LIST_ITEM(state, payload) {
            let index = _.findIndex(state.list, { 'id': payload.id });
            state.list[index] = payload.item;
        },
        UPDATE_FILTERS(state, filters) {
            state.filters = filters;
        },
        APPEND_LIST(state, items) {
            state.list = _.union(state.list, items);
        },
        ADD_ITEM(state, item) {
            state.list.unshift(item);
        },
        APPEND_ITEM(state, item) {
            state.list.push(item);
        },
        REMOVE_ITEM(state, id) {
            let index = _.findIndex(state.list, { 'id': id });
            state.list.splice(index, 1);
        },
        REFRESH_LIST(state) {
            let list_temp = _.cloneDeep(state.list);
            state.list = [];
            state.list = list_temp;
        },
        UPDATE_META(state, meta) {
            state.meta = meta;
        },
        FORM_CREATE(state) {
            state.form_mode = 'add';
        },
        FORM_EDIT(state) {
            state.form_mode = 'edit';
        },
        CLEAR_RESPONSE(state) {
            state.response = {};
        },
        THROW_RESPONSE(state, payload) {
            state.response = payload;
        },
    },
    actions: {
        index(context, payload) {
            this.dispatch('Interface/loader_start')
            let query_filters = _.pickBy(context.state.filters, function (item) {
                return item != '';
            });
            return axios.get(api_endpoint,  { params: { ...payload } })
                .then((response) => {
                    if (query_filters.page == 1) {
                        context.commit('UPDATE_LIST', response.data.data);
                    }
                    else {
                        context.commit('APPEND_LIST', response.data.data);
                    }
                    context.commit('UPDATE_META', response.data.meta);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        enabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/enabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        disabled(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/disabled')
                .then((response) => {
                    context.commit('UPDATE_LIST', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        show(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.get(api_endpoint + '/' + payload.id, { params: { ...payload.params } })
                .then((response) => {
                    context.commit('UPDATE_ITEM', response.data.data);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint, payload)
                .then((response) => {
                    context.commit('ADD_ITEM', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success'});
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        update(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id, payload)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload });
                    context.commit('UPDATE_ITEM', payload);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        visibility(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/states/visibility', payload)
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: payload });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        group_note_move(context, payload) {
            const { id, template_group_id } = payload
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + id + '/move', { template_group_id })
                .then((response) => {
                    context.commit('REMOVE_ITEM', id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        group_copy_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/copy', payload)
            .then((response) => {
                this.dispatch('Interface/loader_stop')
                if(payload.old_template_group_id === payload.template_group_id) {
                    context.dispatch('index', {
                        'filter[template_group_id]': payload.old_template_group_id,
                        'include': 'attachmentsCount',
                        'sort': 'order'
                    })
                }
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            })
            .catch((error) => {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            });
        },
        group_clone_store(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/clone', payload)
            .then((response) => {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
            })
            .catch((error) => {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
            });
        },
        destroy(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.delete(api_endpoint + '/' + payload.id)
                .then((response) => {
                    context.commit('REMOVE_ITEM', payload.id);
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        select_item(context, payload) {
            context.commit('UPDATE_ITEM', payload.data);
        },
        select(context, payload) {
            return new Promise((resolve, reject) => {
                context.dispatch('clear_item');
                let index = _.findIndex(context.state.list, { 'id': payload.id })
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.list[index]));
                context.commit('FORM_EDIT');
                resolve('Selected');
            });
        },
        complete(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/complete', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        uncomplete(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/uncomplete', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        client_visible(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/client_visible', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        client_invisible(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.patch(api_endpoint + '/' + payload.id + '/client_invisible', {})
                .then((response) => {
                    context.commit('UPDATE_LIST_ITEM', { id: payload.id, item: response.data.data });
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        reorder(context, payload) {
            this.dispatch('Interface/loader_start')
            context.commit('UPDATE_LIST', payload.list)
            return axios.patch(api_endpoint + '/reorder', { data: payload.list })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('REFRESH_LIST');
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
                });
        },
        template_export(context, payload) {
            this.dispatch('Interface/loader_start')
            return axios.post(api_endpoint + '/export', { data: payload.data, export_mode: payload.export_mode, template_group_id: payload.template_group_id, template_group_name: payload.template_group_name })
                .then((response) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' });
                })
                .catch((error) => {
                    this.dispatch('Interface/loader_stop')
                    context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error', server: error.response.data });
                });
        },
        clear(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('UPDATE_LIST', _.cloneDeep(context.state.defaults.list));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('UPDATE_FILTERS', _.cloneDeep(context.state.defaults.filters));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
        clear_item(context) {
            return new Promise((resolve, reject) => {
                context.commit('UPDATE_ITEM', _.cloneDeep(context.state.defaults.item));
                context.commit('THROW_RESPONSE', _.cloneDeep(context.state.defaults.response));
                context.commit('FORM_CREATE');
                resolve('Cleared');
            });
        },
    },
}

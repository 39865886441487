<template>
    <a-main>
        <a-container container--fluid grid-list-xl class="pa-0">
            <a-layout wrap align-start v-if="check_permission('store')">
                <a-flex shrink pt-0 class="u-flex align-center">
                    <a-btn small dark color="blue" class="ma-0 elevation-0" @click="local_open_search()">
                        Add Ticket
                        <!-- Add Ticket <span class="ml-1 md-caption">( Press / )</span> -->
                    </a-btn>
                </a-flex>
            </a-layout>

            <!-- Page Loading -->
            <a-layout v-if="page_loading">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-medium text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Tickets..</h3>
                    </a-responsive>
                </a-flex>
            </a-layout>
            <a-layout v-if="!page_loading && (ticket_list && !ticket_list.length)">
                <a-flex>
                    <a-responsive class="my-4">
                        <h3 class="md-subtitle-1 font-weight-medium text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
                        <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
                    </a-responsive>
                </a-flex>
            </a-layout>

            <!-- Tickets List -->
            <a-layout wrap align-start v-if="!page_loading">
                <a-flex xs12>
                    <draggable v-model="ticket_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                        <div class="u-wfull relative u-border u-rounded-corners" style="position: relative; border-collapse: separate; border-spacing: 0px 8px;">
                            <div
                                v-for="(ticket) in ticket_list"
                                :key="ticket.id"
                                :class="[ticket.visibility === 'external' ? 'yellow lighten-4' : '']"
                                @click="local_show_ticket(ticket.id)"
                                class="u-flex-center-y u-wfull js-draggable-file-list-item pa-2 py-2 pr-4"
                            >
                                <div
                                    style="letter-spacing: 1px;"
                                    class="md-caption font-weight-bold grey--text text--darken-2 text-left py-3 px-4 u-cursor-pointer text-truncate"
                                >
                                    <div class="pb-2 u-cursor-pointer text-truncate">
                                        <h2 class="md-subtitle-1 grey--text text--darken-4 text-truncate" :title="ticket.title">
                                            {{ ticket.title }}
                                        </h2>
                                    </div>
                                    <div class="u-flex align-center u-wfull">
                                        <s-meta-ticket-dropdown
                                            :can-update="check_permission('update')"
                                            :meta-item="ticket.type"
                                            :meta-list="type_list"
                                            :loading="loading_ticket_item(ticket.id, 'type_id', ticket.type_id)"
                                            :hide-icon="!check_permission('update')"
                                            @updateMeta="local_update_meta(ticket.id, 'type', ...arguments)"
                                            max-width="150"
                                            inline
                                        ></s-meta-ticket-dropdown>
                                        <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                        <p class="md-body-2 grey--text text--darken-1 u-flex align-center mb-0 ml-3" @click="local_show_ticket(ticket.id)">
                                            <span class="mr-1">
                                                Reported by
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" class="font-weight-medium">
                                                            {{ ticket.created_by ? ticket.created_by.name: '' }}
                                                        </span>
                                                    </template>
                                                    <div class="u-flex align-center">
                                                        <g-avatar :item="ticket.created_by" no-border :size="28" class="mr-2" :right-space="false"></g-avatar>
                                                        <g-profile-info dark :item="ticket.created_by"></g-profile-info>
                                                    </div>
                                                </a-tooltip>
                                                on
                                            </span>
                                            <a-menu bottom open-on-hover offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <div v-on="on">
                                                        <g-moment :value="ticket.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD" convert-local></g-moment>
                                                    </div>
                                                </template>
                                                <a-sheet class="md-body-2 u-elevation-custom-1 u-rounded-corners primary darken-1 grey--text text--lighten-1 pa-2 px-3">
                                                    <g-moment :value="ticket.created_at" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY [at] hh:mm A" convert-local></g-moment>
                                                </a-sheet>
                                            </a-menu>
                                            <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                            <span class="md-subtitle-1 grey--text text--lighten-1 ml-3" v-if="ticket.code">{{ ticket.code }}</span>
                                        </p>
                                        <a-icon size="5" color="grey lighten-2" class="ml-3">fiber_manual_record</a-icon>
                                        <g-attachment-count-info
                                            :count="ticket.attachments_count"
                                            class="ml-3"
                                            fontSize="14"
                                        />
                                    </div>
                                </div>
                                <a-spacer></a-spacer>
                                <div v-if="!ticket.ticket_id && check_permission('update')" class="u-flex align-center justify-center mr-2">
                                    <a-menu offset-y :close-on-content-click="ticket_search_mode" max-width="200">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on" class="u-cursor-pointer">
                                                <a-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <a-icon v-on="on" size="24" color="grey darken-1" class="ml-2">add_link</a-icon>
                                                    </template>
                                                    <span>Link Ticket</span>
                                                </a-tooltip>
                                            </div>
                                        </template>
                                        <a-list class="u-list-condensed pt-0 mt-0">
                                            <a-sheet class="c-input-wrap px-2" :class="[ticket_global_list && ticket_global_list.length > 6 ? 'pt-2' : 'pt-1']">
                                                <a-text-field
                                                    v-model="local_global_search"
                                                    placeholder="Search Global Tickets"
                                                    background-color="neutral"
                                                    class="md-body-2 font-weight-normal"
                                                    solo flat hide-details autofocus
                                                    v-if="ticket_global_list && ticket_global_list.length > 6"
                                                    @click="ticket_search_mode = false"
                                                ></a-text-field>
                                                <div class="md-caption font-weight-medium grey--text text--darken-1 px-2 pt-1 pb-2" style="border-bottom: 1px solid #f5f5f5" v-if="ticket_global_list && ticket_global_list.length <= 6">GLOBAL TICKETS</div>
                                            </a-sheet>
                                            <a-list class="u-list-condensed pb-0 mt-0" style="max-height: 300px; overflow-y: auto">
                                                <template v-for="(ticket_global) in local_filtered_tickets">
                                                    <a-list-item :key="ticket_global.id" @click="local_link_ticket(ticket_global.id, ticket.id)">
                                                        <a-list-item-content class="text-truncate">{{ ticket_global.title }}</a-list-item-content>
                                                    </a-list-item>
                                                </template>
                                            </a-list>
                                        </a-list>
                                    </a-menu>
                                </div>
                                <div
                                    class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-4 u-cursor-pointer"
                                    style="letter-spacing: 1px;"
                                >
                                    <s-meta-ticket-dropdown
                                        :can-update="check_permission('update')"
                                        :meta-item="ticket.priority"
                                        :meta-list="priority_list" text-length="6"
                                        :loading="loading_ticket_item(ticket.id, 'priority_id', ticket.priority_id)"
                                        @updateMeta="local_update_meta(ticket.id, 'priority', ...arguments)"
                                    ></s-meta-ticket-dropdown>
                                </div>
                                <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-left py-3 px-4 u-cursor-pointer" style="letter-spacing: 1px;">
                                    <GNonActiveInfo
                                        :project="project_item"
                                        :stage="project_item && project_item.status"
                                        :disabled="!check_permission('update') || (check_permission('update') && project_item && project_item.status === 'active')"
                                        left
                                    >
                                        <s-meta-ticket-dropdown
                                            :can-update="check_permission('update') && (project_item && project_item.status === 'active')"
                                            :meta-item="ticket.status"
                                            :meta-list="status_list"
                                            :loading="loading_ticket_item(ticket.id, 'status_id', ticket.status_id)"
                                            @updateMeta="local_update_meta(ticket.id, 'status', ...arguments)"
                                        ></s-meta-ticket-dropdown>
                                        <template #move-stage="{ moveStage }">
                                            <span @click="localCheckPlanStageUpdate(moveStage)" class="d-inline-flex align-center u-cursor-pointer">
                                                <h3 class="blue--text md-body-2 font-weight-medium">Move to active stage</h3>
                                                <a-icon size="16" color="blue" class="ml-1">trending_flat</a-icon>
                                            </span>
                                        </template>
                                    </GNonActiveInfo>
                                </div>
                                <div class="md-caption font-weight-bold grey--text text--darken-2 text-uppercase text-right py-3 u-cursor-pointer" style="letter-spacing: 1px;">
                                    <SAssigneeDropdown
                                        :item="ticket"
                                        :users-list="ticket.assignees"
                                        :project-id="$route.params.id"
                                        :can-update="check_permission('update')"
                                        :size="36"
                                        @click.stop="{}"
                                        @assignee-store="localTicketShow(ticket.id)"
                                        @unassigned="localTicketShow(ticket.id)"
                                        @menuOpen="mixinGetCollabs"
                                        module-type="Ticket"
                                        class="u-flex align-center mr-2"
                                        hideExternalConfirmation
                                    />
                                </div>
                            </div>
                        </div>
                    </draggable>
                </a-flex>
            </a-layout>

            <!-- Load More Button -->
            <a-layout wrap align-start v-if="!page_loading">
                <a-flex xs12 class="u-flex align-center">
                    <a-btn v-if="ticket_meta && (ticket_meta.current_page < ticket_meta.last_page)" :loading="loading" :disabled="loading" @click="local_paginate(FILTERS.PAGE, ticket_meta.current_page + 1, 'pagination')" small text class="primary" dark>
                        Load More
                    </a-btn>
                    <a-spacer></a-spacer>
                    <span class="md-body-2 grey--text text--darken-1" v-if="ticket_meta.total">Showing <strong>{{ ticket_list.length }}</strong> out of <strong>{{ ticket_meta.total }}</strong> tickets</span>
                </a-flex>
            </a-layout>
        </a-container>

        <!-- Ticket Add/Edit form -->
        <a-dialog v-model="dialog_ticket_form" :max-width="local_screen_lg ? 1200 : 1280" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="45">
                                        <a-icon dark>account_tree</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ ticket_form_mode === 'add' ? 'Create New' : 'Update' }} Ticket</h2>
                                    <p class="md-subtitle-2 mb-0 grey--text text--darken-1" v-if="ticket_form_mode === 'add'">You can create new ticket</p>
                                    <p class="md-subtitle-2 mb-0 grey--text text--darken-1" v-else :title="ticket_item.title">Update ticket <strong class="grey--text text--darken-2">{{ local_truncate_text(ticket_item.title, 70) }}</strong></p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout>
                                <a-flex xs6 pa-0>
                                    <a-container container--fluid grid-list-xl>
                                        <a-layout wrap>
                                            <a-flex xs12>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                                <a-text-field
                                                    v-model="ticket_item.title"
                                                    background-color="neutral"
                                                    placeholder="Enter Title"
                                                    class="u-border"
                                                    clear-icon="backspace"
                                                    solo flat hide-details clearable autofocus
                                                ></a-text-field>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.title">
                                                    <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.title[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                                    Enter title of the ticket.
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Type</label>
                                                <a-select
                                                    v-model="ticket_item.type_id"
                                                    :items="type_list"
                                                    item-text="value"
                                                    item-value="id"
                                                    placeholder="Select Type"
                                                    background-color="neutral"
                                                    solo flat hide-details
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 26) }}</span>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 35) }}</span>
                                                    </template>
                                                </a-select>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.type_id">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.type_id[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                                    Select type of the ticket
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Priority</label>
                                                <a-select
                                                    v-model="ticket_item.priority_id"
                                                    :items="priority_list"
                                                    item-text="value"
                                                    item-value="id"
                                                    placeholder="Select Priority"
                                                    background-color="neutral"
                                                    solo flat hide-details
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 26) }}</span>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 35) }}</span>
                                                    </template>
                                                </a-select>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.priority_id">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.priority_id[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                                    Select priority of the ticket
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Status</label>
                                                <a-select
                                                    v-model="ticket_item.status_id"
                                                    :items="status_list"
                                                    item-text="value"
                                                    item-value="id"
                                                    placeholder="Select Status"
                                                    background-color="neutral"
                                                    solo flat hide-details
                                                >
                                                    <template v-slot:selection="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 26) }}</span>
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <span class="md-body-2 font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.value, 35) }}</span>
                                                    </template>
                                                </a-select>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.status_id">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.status_id[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                                    Select status of the ticket
                                                </span>
                                            </a-flex>
                                            <a-flex xs6>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Due Date (Optional)</label>
                                                <!-- <a-menu
                                                    v-model="datepicker_due_date"
                                                    :close-on-content-click="true"
                                                    max-width="290"
                                                    full-width offset-y lazy
                                                >   <template v-slot:activator="{ on }">
                                                        <a-text-field
                                                            v-model="ticket_item.due_date"
                                                            :value="local_format_date(ticket_item.due_date)"
                                                            append-icon="date_range"
                                                            placeholder="Select Due Date"
                                                            background-color="neutral"
                                                            v-on="on"
                                                            clear-icon="backspace"
                                                            solo flat hide-details clearable readonly
                                                        ></a-text-field>
                                                    </template>
                                                    <a-date-picker v-model="ticket_item.due_date" @input="datepicker_due_date = false" no-title scrollable></a-date-picker>
                                                </a-menu> -->
                                                <vc-date-picker
                                                    v-model="local_due_date"
                                                    :model-config="modelConfig"
                                                    class="d-block u-wfull"
                                                    @input="local_set_date()"
                                                >
                                                    <template v-slot="{ togglePopover }">
                                                        <div @click.stop="() => togglePopover({ placement: 'bottom-start' })">
                                                            <a-text-field
                                                                v-model="local_due_date"
                                                                :value="local_format_date(ticket_item.due_date)"
                                                                append-icon="date_range"
                                                                placeholder="Select Due Date"
                                                                background-color="neutral"
                                                                clear-icon="backspace"
                                                                solo flat hide-details clearable readonly
                                                            ></a-text-field>
                                                        </div>
                                                    </template>
                                                </vc-date-picker>
                                                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.due_date">
                                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                                    {{ ticket_response.server.errors.due_date[0] }}
                                                </span>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                                    <a-icon size="16">info</a-icon>
                                                    Select due date of the ticket.
                                                </span>
                                            </a-flex>
                                            <a-flex xs12>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Assignee (Optional)</label>
                                                <GUsersSelectDropdown
                                                    v-model="selected_assignees"
                                                    :list="collaborator_list"
                                                    :loading="mixinSearchCollabLoading"
                                                    :inputStyle="{ minHeight: '24px' }"
                                                    inputClass="md-subtitle-1"
                                                    itemText="user.name"
                                                    itemValue="user.id"
                                                    @search="localSearchCollabs"
                                                    placeholder="Select Assignees"
                                                    noDataText="No User Found"
                                                    class="pr-5 neutral"
                                                    :class="{ 'py-2': selected_assignees && selected_assignees.length }"
                                                    dropdownClass="c-tiny-scroll py-2"
                                                    returnObject multiple removable
                                                />
                                                <!--<a-autocomplete
                                                    v-model="selected_assignees"
                                                    :items="collaborator_list"
                                                    item-text="user.name"
                                                    item-value="user_id"
                                                    placeholder="Select Assignee"
                                                    background-color="neutral"
                                                    clear-icon="backspace"
                                                    solo flat hide-details clearable multiple chips small-chips hide-selected
                                                >
                                                <template v-slot:no-data>
                                                    <div class="px-4 py-2">
                                                        <span class="md-body-1 grey--text">No Users found</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item: { user } }">
                                                    <a-chip pill close @click:close="selected_assignees.splice(selected_assignees.indexOf(user.id), 1)">
                                                        <h5 class="md-body-2 text-truncate" style="max-width: 300px" :title="user.name">{{ local_truncate_text(user.name, 20) }}</h5>
                                                    </a-chip>
                                                </template>
                                                <template v-slot:item="{ item }">
                                                    <a-layout align-center class="py-2">
                                                        <g-avatar :item="item"></g-avatar>
                                                        <g-profile-info :item="item" set-max-width></g-profile-info>
                                                    </a-layout>
                                                </template>
                                                </a-autocomplete>-->
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                    <a-icon size="16">info</a-icon>
                                                    Select the assignees for the ticket.
                                                </span>
                                            </a-flex>
                                            <a-flex xs12>
                                                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Tags (Optional)</label>
                                                <component
                                                    :is="$can('tags.update') ? 'a-combobox' : 'a-autocomplete'"
                                                    v-model="selected_tags"
                                                    :items="tag_list"
                                                    item-text="label"
                                                    item-value="id"
                                                    :placeholder="`Select${ $can('tags.store') ? ' or create ' : ' ' }tag`"
                                                    background-color="neutral"
                                                    :search-input.sync="ticket_tag_search"
                                                    clear-icon="backspace"
                                                    @input="local_ticket_tag_store"
                                                    solo flat hide-details clearable multiple chips small-chips hide-selected deletable-chips
                                                >
                                                    <template v-slot:no-data>
                                                        <div class="px-4 py-2">
                                                            <template v-if="$can('tags.store')">
                                                                <span class="md-body-2" v-if="ticket_tag_search != null && ticket_tag_search != ''">
                                                                    Create <strong>{{ ticket_tag_search }}</strong> as tag. Hit<span style="font-family: monospace;" class="mx-1 font-weight-bold">Enter</span>to create
                                                                </span>
                                                            </template>
                                                            <span v-else class="md-body-2 grey--text">
                                                                No tags found. <template v-if="$can('tags.store')">Enter label and hit enter to create new one.</template>
                                                            </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <g-tags :tag="item" @destroy="local_remove_tag(selected_tags, 'tag', item, $event)"></g-tags>
                                                        <!-- <span v-if="item === Object(item)" class="md-caption d-inline-flex align-center py-1 px-2 mx-1 my-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">
                                                            <span>{{ local_truncate_text(item.label, 35) }}</span>
                                                            <a-icon @click="local_remove_tag(selected_tags, 'tag', item)" class="ml-1" size="16" :color="$color(item.color, 'color_text')">clear</a-icon>
                                                        </span> -->
                                                    </template>
                                                    <template v-slot:item="{ item }">
                                                        <g-tags :tag="item" dense hide-clear-icon></g-tags>
                                                        <!-- <span class="md-caption font-weight-meidum d-inline-block pa-2 py-1 u-rounded-corners" :style="{backgroundColor: $color(item.color, 'color_bg'), color: $color(item.color, 'color_text')}">{{ local_truncate_text(item.label, 35) }}</span> -->
                                                    </template>
                                                </component>
                                                <span class="md-caption grey--text text--darken-2 d-block mt-2">
                                                    <a-icon size="16">info</a-icon>
                                                    Select the tags for the ticket.
                                                </span>
                                            </a-flex>
                                        </a-layout>
                                    </a-container>
                                </a-flex>
                                <a-flex xs6>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Description (Optional)</label>
                                    <div class="neutral pa-3 u-rounded-corners c-scrollbar" style="min-height: 578px; max-height: 578px; overflow-y: auto" @click="() => { ticket_item.description = '<p> </p>'; editor_ticket_description.focus() }">
                                        <p class="grey--text text--darken-1 u-hfull" v-if="editor_ticket_description  === null" @click="() => { ticket_item.description = '<p> </p>'; editor_ticket_description.focus() }">Click to edit the description</p>
                                        <editor-content v-else class="u-hfull" :editor="editor_ticket_description"></editor-content>
                                        <editor-menu-bubble :editor="editor_ticket_description" :keep-in-bounds="false" v-slot="{ commands, isActive, menu }">
                                            <div
                                                class="menububble"
                                                :class="{ 'is-active': menu.isActive }"
                                                :style="`left: ${menu.left - 16}px; bottom: ${menu.bottom + 8}px;`"
                                            >
                                                <a-btn icon text small class="mx-0" :color="(isActive.bold()) ? 'blue' : 'grey'" @click="commands.bold">
                                                    <a-icon size="16">format_bold</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.italic()) ? 'blue' : 'grey'" @click="commands.italic">
                                                    <a-icon size="16">format_italic</a-icon>
                                                </a-btn>
                                                <a-divider vertical dark></a-divider>
                                                <a-btn icon text small class="mx-0" :color="(isActive.bullet_list()) ? 'blue' : 'grey'" @click="commands.bullet_list">
                                                    <a-icon size="16">format_list_bulleted</a-icon>
                                                </a-btn>
                                                <a-btn icon text small class="mx-0" :color="(isActive.ordered_list()) ? 'blue' : 'grey'" @click="commands.ordered_list">
                                                    <a-icon size="16">format_list_numbered</a-icon>
                                                </a-btn>
                                            </div>
                                        </editor-menu-bubble>
                                    </div>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="ticket_response.server && ticket_response.server.errors && ticket_response.server.errors.description">
                                        <a-icon size="16" class="u-icon-nudge-xs" color="red darken-2">warning</a-icon>
                                        {{ ticket_response.server.errors.description[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16" class="u-icon-nudge-xs">info</a-icon>
                                        You can summarize and describe the ticket.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <a-btn color="primary" :loading="ticket_loader_type === 'add'" :disabled="ticket_loader_type === 'add'" class="ma-0 elevation-0 px-3" @click="local_ticket_store()">Create Ticket</a-btn>
                                    <a-btn color="grey" class="ml-2" text :disabled="ticket_loader_type === 'add'" @click="local_close_create_form">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog v-model="dialog_search_tickets" origin="top center" scrollable class="u-overflow-hidden" max-width="800">
            <a-card class="pa-0 u-rounded-corners u-elevation-custom-1" min-height="192">
                <a-sheet class="pa-4 pr-8 pl-4 grey lighten-3 u-flex align-center">
                    <div class="u-wfull">
                        <a-text-field
                            v-model="search_tickets"
                            :placeholder="'Search' + (check_permission('store') ? ' or Create ' : ' ') + 'new ticket'"
                            background-color="transparent"
                            style="font-size: 20px"
                            solo flat hide-details autofocus
                            @input="local_search_tickets"
                            @keydown.enter="local_search_tickets"
                        ></a-text-field>
                    </div>
                    <a-spacer></a-spacer>
                    <div class="u-flex align-center">
                        <span class="md-caption grey--text text--darken-1 mr-2 u-flex align-center" v-if="ticket_search_list && search_tickets"><span class="mr-1">{{ ticket_search_list.length }}</span> {{ ticket_search_list.length > 1 ? 'tickets' : 'ticket' }}</span>
                        <div class="u-elevation-custom-1 u-rounded-corners white d-inline-block px-2">
                            <a-icon size="24" color="grey darken-1">keyboard_return</a-icon>
                        </div>
                    </div>
                </a-sheet>
                <a-divider class="u-divider"></a-divider>
                <a-card-text class="pa-0">
                    <div class="u-relative">
                        <template v-if="local_search_loading">
                            <a-sheet min-height="100" class="u-flex align-center justify-center py-2 grey lighten-5 u-wfull u-hfull" :class="{ 'u-absolute': ticket_search_list.length }" style="top: 0px; left: 0px; z-index: 1;">
                                <div class="text-center">
                                    <a-img src="../../assets/images/loading-1.svg" class="d-inline-block" width="40" alt="Fetching tickets.."></a-img>
                                </div>
                            </a-sheet>
                        </template>
                        <template v-else-if="!local_search_loading && !show_no_data_found && !ticket_search_list.length">
                            <a-sheet min-height="92" class="u-flex align-center justify-center py-6 grey lighten-5 u-wfull" style="z-index: 1;">
                                <div class="text-center">
                                    <a-icon size="40" v-if="!check_permission('update')" color="grey lighten-1">search</a-icon>
                                    <h2 class="md-subtitle-1 grey--text text--darken-1 text-center mx-auto" style="max-width: 450px;">Enter ticket title <span class="font-italic font-weight-medium">(min 3 letters)</span> to search for tickets <span v-if="check_permission('update')">to add</span></h2>
                                    <template v-if="check_permission('update')">
                                        <h2 class="md-body-2 mt-2 mb-1 grey--text text--darken-1 text-center">(Or)</h2>
                                        <a-btn small text @click="local_ticket_create" class="ma-0 u-rounded-corners blue darken-2 mt-2 white--text">
                                            <a-icon class="mr-1 white--text">add</a-icon>
                                            Create New Ticket
                                        </a-btn>
                                    </template>
                                </div>
                            </a-sheet>
                        </template>
                        <template v-else>
                            <template v-if="(ticket_search_list && !ticket_search_list.length) && search_tickets && show_no_data_found">
                                <a-sheet min-height="114" class="u-flex align-center justify-center py-4">
                                    <div class="text-center">
                                        <h3 class="md-body-2 font-weight-bold text-center grey--text text--darken-1 text-uppercase">No tickets found</h3>
                                        <p class="md-body-2 text-center grey--text mb-0">Try with different search term or create new ticket.</p>
                                        <a-btn small text @click="local_ticket_create" v-if="check_permission('update')" class="ma-0 u-rounded-corners blue darken-2 mt-2 white--text">
                                            <a-icon class="mr-1 white--text">add</a-icon>
                                            Create Ticket
                                        </a-btn>
                                    </div>
                                </a-sheet>
                            </template>
                        </template>
                        <template v-if="ticket_search_list && ticket_search_list.length">
                            <div class="u-flex align-center px-6 py-2 grey lighten-5 u-cursor-pointer" v-ripple="{ color: 'blue darken-1' }" v-if="!local_search_loading && check_permission('update')" @click="local_ticket_create" style="position: sticky; top: 0px; left: 0px; z-index: 1">
                                <!-- <a-icon class="mr-2" size="18" color="blue darken-1">info</a-icon> -->
                                <h2 class="md-body-2 d-inline-block blue--text text--darken-1">Didn't find the ticket? Click to create new one.</h2>
                                <a-spacer></a-spacer>
                                <!-- <a-btn small text class="u-rounded-corners grey lighten-3 blue--text text--darken-2">
                                    <a-icon class="mr-1" size="18" color="blue darken-2">add</a-icon>
                                    Create
                                </a-btn> -->
                            </div>
                            <a-list class="py-0" v-for="(ticket, index) in ticket_search_list" :key="ticket.id + index + '.list'">
                                <a-list-item class="u-flex align-center px-6 py-1" style="min-height: 56px" @click="local_add_ticket(ticket, 'attach')">
                                    <div>
                                        <h2 class="md-subtitle-1 d-inline-block grey--text text--darken-4 mb-2" :title="ticket.title">{{ local_truncate_text(ticket.title, 50) }}</h2>
                                    </div>
                                    <a-spacer></a-spacer>
                                    <div>
                                        <p v-if="ticket.due_date" class="md-body-2 text-right grey--text text--darken-1 mb-0">
                                            <span>Due on </span>
                                            <g-moment :value="ticket.due_date" input-format="YYYY-MM-DD HH:mm:ss" output-format="MMM DD, YYYY" convert-local></g-moment>
                                        </p>
                                        <p v-else class="md-body-2 grey--text text-right text--darken-1 mb-0">No Due Date</p>
                                    </div>
                                    <a-sheet min-width="120" class="transparent text-right ml-2">
                                        <span v-if="ticket.status" class="md-caption pa-1 d-inline-block px-2 text-uppercase font-weight-medium u-rounded-corners" style="letter-spacing: 1px;" :style="[{ maxWidth: local_screen_lg ? '200px' : '150px' }, { color: $color(ticket.status.color, 'color_text'), backgroundColor: $color(ticket.status.color, 'color_bg') }]">
                                            {{ ticket.status.value }}
                                        </span>
                                        <span v-else class="md-caption pa-1 d-inline-block px-2 text-uppercase font-weight-medium u-rounded-corners u-wfull" style="letter-spacing: 1px;"> - </span>
                                    </a-sheet>
                                </a-list-item>
                                <a-divider class="u-divider"></a-divider>
                            </a-list>
                        </template>
                    </div>
                </a-card-text>
            </a-card>
        </a-dialog>

        <!-- User Upgrade Popup -->
        <a-dialog max-width="400" v-model="dialog_user_upgrade_modal">
            <SUserUpgradeModal @close="dialog_user_upgrade_modal = false" />
        </a-dialog>

        <!-- Admin Upgrade Popup -->
        <a-dialog max-width="600" v-model="dialog_admin_upgrade_modal">
            <SAdminUpgradeModal
                :isOpen="dialog_admin_upgrade_modal"
                @upgrade="{}"
                @close="dialog_admin_upgrade_modal = false"
            >
                <template #plan-info-text>
                    Your current <span class="secondary--text font-weight-bold">Starter</span> plan is limited to {{ $plan('active_projects_limit') }} active projects.
                </template>
            </SAdminUpgradeModal>
        </a-dialog>

        <!-- Snackbar -->
        <a-snackbar v-model="snackbar_status" auto-heigh :color="snackbar_text.type" :timeout="8000" bottom center>
            <div class="text-center">
                {{snackbar_text.content}}
            </div>
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
    Bold,
    Italic,
    Strike,
    OrderedList,
    BulletList,
    ListItem,
    History,
} from 'tiptap-extensions'
import marked from 'marked'
import { VCombobox, VAutocomplete } from 'vuetify/lib'

import mixinGetOrganizationPlan from '@/mixins/mixin-get-organization-plan'
import mixinSearchCollaborators from '@/mixins/mixin-search-collaborators'
import loadWorkspace from '@/mixins/mixin-workspace'
import TemplateImportStatus from '@/mixins/mixin-template-import-status'
import {
    SUserListPopup,
    SMetaTicketDropdown,
    SAssigneeDropdown,
    SUserUpgradeModal,
    SAdminUpgradeModal
} from '@/config/config-shared-components'

export default {
    mixins: [
        loadWorkspace,
        TemplateImportStatus,
        mixinSearchCollaborators,
        mixinGetOrganizationPlan
    ],

    components: {
        EditorContent,
        EditorMenuBubble,
        SMetaTicketDropdown,
        SUserListPopup,
        VCombobox,
        VAutocomplete,
        SAssigneeDropdown,
        SUserUpgradeModal,
        SAdminUpgradeModal
    },

    data () {
        return {
            dialog_user_upgrade_modal: false,
            dialog_admin_upgrade_modal: false,
            editor_ticket_description: null,
            ticket_description: '',
            datepicker_due_date: null,
            status_list: [],
            priority_list: [],
            type_list: [],
            page_loading: true,
            loading_ticket: {},
            dialog_ticket_form: false,
            local_search_loading: false,
            ticket_loader_type: null,
            dialog_search_tickets: false,
            show_no_data_found: false,
            ticket_tag_search: '',
            selected_assignees: [],
            selected_tags: [],
            search_tickets: '',
            local_user_edit_item: {},
            local_selected_user_add_item: null,
            autosave_user_add_status: 'none',
            search_module: { label: 'Pudding', value: 'pudding' },
            integration_modules: [{ label: 'Jira', value: 'jira' }, { label: 'Zendesk', value: 'zendesk' }, { label: 'Pudding', value: 'pudding' }],
            search_ticket_timeout: null,
            load_more_page_number: null,
            local_ticket_fields: {
                'include': 'status,priority,type,createdBy,assignees.collaborator.type,attachmentsCount',
                'filter[is_global]': 0,
                'filter[parent_id]': this.$route.params.id,
                'sort': 'order'
            },
            ticket_filter: {},
            ticket_count: 100,
            snackbar_status: false,
            snackbar_text: { type: '', content: '' },
            local_global_search: '',
            ticket_search_mode: true,
            FILTERS: {
                PAGE: 'page',
                COUNT: 'count'
            },
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
            local_due_date: null
        }
    },

    mounted () {
        this.local_index()
    },

    computed: {
        local_screen_lg() {
            return this.$vuetify.breakpoint.lgAndDown
        },

        table_style () {
            return {
                title: { borderLeft: '1px solid #eeeeee !important' },
                drag: { width: (this.$vuetify.breakpoint.lgAndDown ?  30 : 30) + 'px' },
                assignee: {
                    width: (this.$vuetify.breakpoint.lgAndDown ?  50 : 50) + 'px',
                    borderRight: '1px solid #eeeeee !important'
                },
                type: { width: (this.$vuetify.breakpoint.lgAndDown ?  120 : 120) + 'px' },
                priority: { width: (this.$vuetify.breakpoint.lgAndDown ?  110 : 120) + 'px' },
                status: { width: (this.$vuetify.breakpoint.lgAndDown ?  140 : 140) + 'px' },
                action: { width: (this.$vuetify.breakpoint.lgAndDown ?  80 : 80) + 'px' },
            }
        },

        ticket_list: {
            get() {
                return this.$store.state.Ticket.list
            },
            set(list) {
                this.ticket_reorder({ data: list })
            }
        },

        local_project_id () {
            return this.$route.params.id
        },

        local_filtered_tickets () {
            return this.ticket_global_list.filter(item => item.title.toLowerCase().includes(this.local_global_search.toLowerCase()))
        },

        ...mapState('User', {
            user_list: 'list',
            user_self: 'self',
        }),

        ...mapState('Collaborator', {
            collaborator_list: 'list',
            collaborator_filters: 'filters',
        }),

        ...mapState('Assignee', {
            assignee_response: 'response',
        }),

        ...mapState('Ticket', {
            ticket_search_list: 'search_list',
            ticket_global_list: 'global_list',
            ticket_item: 'item',
            ticket_meta: 'meta',
            ticket_form_mode: 'form_mode',
            ticket_response: 'response',
        }),

        ...mapState('Meta', {
            meta_list: 'list',
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_item: 'item',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapGetters('Workspace', {
            workspace_current: 'current',
        }),
    },

    methods: {
        async local_index () {
            if (this.$can('poc_tickets.index') === false) {
                return this.$router.replace({ name: 'errors-unauthorized' })
            }
            this.$vuetify.goTo(0)
            // this.ticket_clear_search_list()
            this.ticket_filter[this.FILTERS.COUNT] = this.ticket_count
            this.local_ticket_index()
            this.local_global_index()
            this.tag_index({ 'filter[type]': 'ticket_tag', 'sort': 'order' })
            await this.local_load_status()
            await this.local_load_priority()
            await this.local_load_type()
            this.page_loading = false
        },

        async local_load_status () {
            await this.meta_index({
                'filter[type]': 'ticket_status',
                'sort': 'order'
            })
            this.status_list = this.meta_list
        },

        async local_load_priority () {
            await this.meta_index({
                'filter[type]': 'ticket_priority',
                'sort': 'order'
            })
            this.priority_list = this.meta_list
        },

        async local_load_type () {
            await this.meta_index({
                'filter[type]': 'ticket_type',
                'sort': 'order'
            })
            this.type_list = this.meta_list
        },

        // Create ticket
        async local_ticket_create () {
            await this.local_create_reset_form()
            this.ticket_item.title = this.search_tickets
            this.dialog_search_tickets = false
            this.local_set_description()
            this.local_set_picklist()
            this.mixinGetCollabs()
            setTimeout(() => this.dialog_ticket_form = true, 0)
            this.snackbar_status = false
        },

        async local_create_reset_form () {
            await this.ticket_clear_item()
            this.selected_assignees = []
            this.selected_tags = []
            this.ticket_description = ''
        },

        local_set_picklist () {
            this.ticket_item.status_id = this.local_get_starred_meta_id(this.status_list)
            this.ticket_item.priority_id = this.local_get_starred_meta_id(this.priority_list)
            this.ticket_item.type_id = this.local_get_starred_meta_id(this.type_list)
        },

        local_get_starred_meta_id (list) {
            if (list.length === 0) return null
            const list_item = list.find(item => item.is_starred)
            return list_item ? list_item.id : list[0].id
        },

        async local_ticket_store () {
            if (!this.ticket_item.due_date) delete this.ticket_item.due_date
            this.ticket_loader_type = 'add'
            this.ticket_item.is_global = 1
            this.ticket_item.description = this.ticket_description

            const params = { parent_id: this.local_project_id, parent_type: 'Project' }
            await this.ticket_store({ ...this.ticket_item, ...params, mode: 'ticket_create' })
            if (this.ticket_response.status !== 'success') {
                return this.ticket_loader_type = null
            }

            await this.local_add_tags(this.ticket_item)
            await this.local_add_ticket(this.ticket_item)
            setTimeout(async () => {
                await this.local_show_ticket(this.ticket_item.id)
                this.dialog_search_tickets = false
                this.ticket_loader_type = null
                this.dialog_ticket_form = false
            }, 1000)
        },

        async local_add_ticket ({ id }, type = null) {
            if (type && this.dialog_ticket_form) this.dialog_ticket_form = false
            await this.ticket_clone_store({
                parent_id: this.$route.params.id,
                ticket_id: id,
                mode: 'add',
                order: -1
            })
            await this.local_add_users(this.ticket_item)
            this.dialog_search_tickets = false
            this.$nextTick(async () => {
                if (type === 'attach') await this.local_show_ticket(this.ticket_item.id)
            })
        },

        async local_add_users (model) {
            if (!this.selected_assignees.length) return
            _.map(this.selected_assignees, 'user_id').forEach(async user_id => {
                const assignee_param = { resource_type: 'Ticket', resource_id: model.id, project_id: this.local_project_id, user_id }
                await this.assignee_store({ ...assignee_param })
            })
        },

        async local_add_tags (model) {
            if (!this.selected_tags.length) return
            const selected_tags = this.selected_tags.map(item => item.id ?? item)
            selected_tags.forEach(async tag_id => {
                await this.attachment_store({ source_type: 'Ticket', source_id: model.id, target_type: 'Tag', target_id : tag_id, type: 'ticket_tag' })
            })
        },

        async local_close_create_form() {
            await this.ticket_clear_item()
            this.dialog_ticket_form = false
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
        },

        local_set_description () {
            if (this.editor_ticket_description) this.editor_ticket_description.destroy()
            const content = this.ticket_item.description ? this.ticket_item.description : ' '
            this.editor_ticket_description = new Editor({
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Strike(),
                    new BulletList(),
                    new OrderedList(),
                    new ListItem(),
                    new History(),
                ],
                content: marked(content, { sanitize: true }),
                onUpdate: state => this.ticket_description = turndownService.turndown(state.getHTML()),
            })
        },

        async local_paginate (filter, value, type = null) {
            this.load_more_page_number = value
            this.ticket_filter[this.FILTERS.COUNT] = this.ticket_count
            this.ticket_filter[filter] = this.load_more_page_number
            await this.local_ticket_index({ type })
        },

        async local_global_index () {
            await this.ticket_global_index({
                'fields[tickets]': 'id,title',
                'filter[is_global]': 1,
                'sort': 'order',
                'count': 9999
            })
        },

        async local_ticket_index (params = {}) {
            await this.ticket_index({
                ...this.local_ticket_fields,
                ...this.ticket_filter,
                ...params
            })
        },

        local_select_search_module (module) {
            this.search_module = module
        },

        async local_open_search () {
            if (this.dialog_ticket_form) this.dialog_ticket_form = false
            await this.ticket_clear_search_list()
            this.search_tickets = null
            this.dialog_search_tickets = true
            this.show_no_data_found = false
        },

        // local_search_command (evt) {
        //     if (evt.keyCode === 47 && evt.key === '/') {
        //         evt.preventDefault()
        //         evt.stopPropagation()
        //         this.local_open_search()
        //     }
        // },

        async local_search_tickets (evt) {
            if (this.search_ticket_timeout) clearTimeout(this.search_ticket_timeout)
            if (!this.search_tickets) {
                await this.ticket_clear_search_list()
                this.show_no_data_found = false
                this.local_search_loading = false
                return
            }
            if (this.search_tickets.length < 3) return this.local_search_loading = false
            // if (evt && evt.key === 'Enter' && this.search_tickets && (this.ticket_search_list && !this.ticket_search_list.length)) return this.local_ticket_create()

            this.show_no_data_found = false
            this.local_search_loading = true
            this.ticket_filter[this.FILTERS.PAGE] = 1
            this.ticket_filter[this.FILTERS.COUNT] = 10

            if (evt && evt.key === 'Enter' && this.search_tickets ) return this.local_search_setup()
            this.search_ticket_timeout = setTimeout(async () => await this.local_search_setup(), 400);
        },

        async local_search_setup () {
            await this.local_search_ticket_index()
            if (this.ticket_search_list && !this.ticket_search_list.length) this.show_no_data_found = true
            this.local_search_loading = false
        },

        async local_search_ticket_index () {
            await this.ticket_search({
                parent_id: this.$route.params.id,
                'include': 'status',
                'filter[is_global]': 1,
                'filter[title]': this.search_tickets,
                'filter[poc_search]': 1,
                'fields[tickets]': 'id,title,due_date,status_id',
                'fields[status]': 'id,color,value,status,type',
                'sort': 'order',
                'mode': 'search',
                ...this.ticket_filter
            })
        },

        // Add Assignee
        async local_assign (ticket, user_id, unassign = false) {
            const assignee_param = { resource_type: 'Ticket', resource_id: ticket.id, project_id: this.local_project_id, user_id }
            this.autosave_user_add_status = 'none'
            this.autosave_user_add_status = 'loading'

            if (unassign) await this.assignee_destroy({ id: user_id })
            else await this.assignee_store(assignee_param)
            if (this.assignee_response.status === 'success') {
                await this.ticket_show({ id: ticket.id, ...this.local_ticket_fields })
                this.local_reset_assignee_variables(ticket.id)
            }
        },

        local_reset_assignee_variables (ticket_id) {
            this.local_selected_user_add_item = null
            this.autosave_user_add_status = 'done'
            Object.assign(this.local_user_edit_item, this.ticket_list.find(item => item.id === ticket_id))
        },

        local_check_user_external (user_id) {
            const { user } = _.find(this.local_collaborator_list, { user_id })
            return user && user.role && user.role.scope === 'external' ? user : false
        },

        // Tag store
        async local_ticket_tag_store () {
            if (this.ticket_tag_search && this.$can('tags.store')) {
                this.tag_item.label = this.ticket_tag_search
                this.tag_item.type = 'ticket_tag'
                this.tag_store({ ...this.tag_item, mode: 'add_update' }).then(async res => {
                    setTimeout(async () => {
                        this.selected_tags.push(this.tag_item)
                        this.selected_tags = this.selected_tags.filter(item => typeof item === 'object')
                        this.ticket_tag_search = null
                        await this.tag_clear_item()
                    }, 200);
                })
            }
        },

        local_remove_tag (list, type, item) {
            const id = item.id ?? item
            const index = list.findIndex(chip => (chip === id) || (chip.id === id))
            if(index !== -1) list.splice(index, 1)
        },

        // Link global ticket
        async local_link_ticket (ticket_id, local_ticket_id, type = null) {
            if (!type) return this.ticket_link_global({ id: local_ticket_id, ticket_id, model: 'list' })
        },

        // Metas Update
        async local_update_meta (id, field, { meta_id }) {
            this.snackbar_status = false
            await this.ticket_select({ id })
            if (this.ticket_item[field + '_id'] === meta_id) return

            this.local_set_ticket_loading(id, field)
            this.ticket_item[field + '_id'] = meta_id
            await this.ticket_update({
                id: id,
                item: this.ticket_item,
                mode: 'update',
                params: {
                    ...this.local_ticket_fields,
                    workspace_id: this.current_workspace_id_mixin
                }
            })
            if (this.ticket_response.status === 'success') {
                this.local_notify_response('Ticket updated successfully', 'success')
                this.loading_ticket = {}
            }
        },

        /* Ticket Show */
        async localTicketShow (id, params = null) {
            params = params ?? this.local_ticket_fields
            await this.ticket_show({ id, params: params })
        },

        // Project --- Start
        localCheckPlanStageUpdate (stageToMove) {
            const { is_admin } = this.user_self || {}
            const { projects_active_count } = this.mixinGetOrgPlan() || {}

            if (this.$plan('active_projects_limit') === -1) return stageToMove()
            if (projects_active_count < this.$plan('active_projects_limit')) return stageToMove()

            if (is_admin) this.dialog_admin_upgrade_modal = true
            else this.dialog_user_upgrade_modal = true
        },
        // Project --- End

        // Extras
        local_set_ticket_loading (id, field) {
            this.loading_ticket.id = id
            this.loading_ticket[field + '_id'] = this.ticket_item[field + '_id']
        },

        async local_show_ticket (id) {
            this.$router.push({ name: 'projects-tickets-view', params: { id: this.project_item.id, ticket_id: id } })
        },

        local_set_date() {
            this.ticket_item.due_date = this.local_due_date
            this.local_due_date = this.local_format_date(this.ticket_item.due_date)
            this.datepicker_due_date = false
        },

        local_format_date(date) {
            return date ? moment(date).format('MMM DD, YYYY') : null
        },

        local_truncate_text(text, range) {
            if (!text) return
            return text.length > range ? text.slice(0, range) + '...' : text
        },

        loading_ticket_item (id, field = null, meta_id = null) {
            if (!meta_id) return this.loading_ticket.id === id
            return this.loading_ticket.id === id && this.loading_ticket[field] === meta_id
        },

        local_notify_response (content, type) {
            this.snackbar_text.content = content
            this.snackbar_text.type = type ?? 'success'
            this.snackbar_status = true
        },

        check_permission (type) {
            return this.$can(`poc_tickets.${type}`) && this.$can('projects.update-only')
        },

        async localSearchCollabs (val) {
            //const args = { include: 'user', 'filter[project_id]': this.$route.params.id, count: 20, page: 1 }
            //await this.mixinSearchCollabs(val, args)
        },

        ...mapActions('Meta', {
            meta_index: 'index',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_clear_item: 'clear_item',
        }),

        ...mapActions('Collaborator', {
            collaborator_index: 'index',
            collaborator_clear: 'clear',
        }),

        ...mapActions('Assignee', {
            assignee_store: 'store',
            assignee_destroy: 'destroy',
        }),

        ...mapActions('Association', {
            attachment_store: 'store',
            attachment_destroy: 'destroy',
        }),

        ...mapActions('Ticket', {
            ticket_index: 'index',
            ticket_global_index: 'global_index',
            ticket_link_global: 'link_global',
            ticket_search: 'poc_ticket_search',
            ticket_store: 'store',
            ticket_update: 'update',
            ticket_select: 'select',
            ticket_show: 'show',
            ticket_clone_store: 'clone_store',
            ticket_reorder: 'reorder',
            ticket_clear_search_list: 'clear_search_list',
            ticket_clear_item: 'clear_item',
        }),
    },
}
</script>

<style lang="scss" scoped>
.c-table {
    display: table !important;
    &__view-action { color: rgb(175, 175, 175) !important; }
    &__content-row {
        &:hover {
            .c-table__view-action { color: #333 !important; }
        }
    }
    // .c-table__view-action-button--edit, .c-table__view-action-button--delete {
    //     transition: 0.1s ease-in;
    // }
    .c-table__view-action-button--edit, .c-table__view-action-button--delete {
        &:hover {
            // margin-bottom: 2px;
            // box-shadow: 0px 1px 4px rgba($color: #000000, $alpha: 0.2);
            transition: 0.1s ease-in;
            // .a-icon { color: rgb(235, 137, 10) !important }
        }
    }
    .c-table__view-action-button--delete {
        &:hover {
            .a-icon { color: rgb(245, 43, 43) !important }
        }
    }
    &__cell {
            display: table-cell !important;
            vertical-align: middle !important;
            white-space: nowrap !important;
            background: #fff !important;
            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
        }
}
</style>

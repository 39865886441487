<template>
    <a-menu offset-y left min-width="400" max-width="400" :close-on-content-click="false" :disabled="disabled" content-class="u-rounded-corners">
        <template v-slot:activator="{ on, value }">
            <div v-on.stop="on">
                <slot>
                    <div class="u-flex">
                        <a-sheet v-if="type !== 'Organization'" max-width="50" :class="['d-inline-flex align-center px-3 u-rounded-tag-left-corners u-cursor-pointer', dense ? '' : 'pa-2']" :style="[dense ? { paddingTop: '3px', paddingBottom: '3px' } : '']" :color="check_is_public ? 'orange darken-2' : 'grey darken-4'">
                            <a-icon size="14" color="white">{{ type === 'projectTag' ? 'folder' : 'stars' }} </a-icon>
                        </a-sheet>
                        <a-sheet max-width="140" :class="[type === 'Organization' ? 'u-rounded-corners' : 'u-rounded-tag-right-corners', 'd-inline-flex align-center px-3 u-cursor-pointer', dense ? '' : 'pa-2']" :style="[dense ? { paddingTop: '3px', paddingBottom: '3px' } : '']" :color="check_is_public ? 'orange darken-2' : 'grey darken-4'">
                            <div class="u-flex align-center py-1">
                                <a-icon size="14" color="white">{{ check_is_public ? 'public' : 'lock' }}</a-icon>
                                <span class="md-caption font-weight-medium white--text ml-2">{{ check_is_public ? 'Public' : 'Private' }}</span>
                            </div>
                            <a-spacer></a-spacer>
                            <a-icon v-if="!disabled" size="20" color="white" class="ml-2">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                        </a-sheet>
                    </div>
                </slot>
            </div>
        </template>
        <div>
            <a-sheet class="pa-6 grey lighten-5">
                <div class="u-flex align-center mb-2">
                    <a-icon size="20" color="indigo darken-2" class="mr-2">visibility</a-icon>
                    <h3 class="md-subtitle-1 font-weight-medium indigo--text text--darken-2">Tags Visibility</h3>
                </div>
                <div class="grey--text md-subtitle-2 text--darken-1">You can choose to share <span class="grey--text text--darken-3" :class="[ check_system_tag_type ? 'text-capitalize': '']">{{ tagType }} Tags</span> with external collaborators or keep it internal only.</div>
                <div class="grey--text md-subtitle-2 text--darken-1 mt-4" v-if="orgVisibility === 'internal'">
                    <span><a-icon size="20" class="mr-1 text--darken-3" color="grey">lock</a-icon></span>
                    Organization: <span class="grey--text text--darken-3">Private</span>
                </div>
                <div class="grey--text md-subtitle-2 text--darken-1 mt-4" v-if="orgVisibility === 'external-ready-only'">
                    <span><a-icon size="20" class="mr-1 text--darken-2" color="orange">public</a-icon></span>
                    Organization: <span class="orange--text text--darken-3">Public</span>
                </div>
            </a-sheet>
            <template v-if="type !== 'Organization'">
                <a-divider class="grey lighten-3"></a-divider>
                <a-sheet class="px-6 py-4 u-cursor-pointer" :class="[!check_org_inherit ? 'grey lighten-5' : '']" @click="!loading && check_org_inherit ? local_visibility_revert() : ''">
                    <div class="u-flex align-center">
                        <a-icon size="20" color="grey darken-3" class="mr-2 u-icon-nudge" style="top: -10px;">stars</a-icon>
                        <div>
                            <h3 class="md-subtitle-2 font-weight-medium grey--text text--darken-3">Inherit</h3>
                            <h3 class="md-subtitle-2 grey--text text--darken-1 font-italic">Inherit from Organization</h3>
                        </div>
                        <v-spacer></v-spacer>
                        <span v-if="!check_org_inherit">
                            <a-icon size="24" color="grey darken-3" class="mr-2 u-icon-nudge" style="top: -8px;">done</a-icon>
                        </span>
                    </div>
                </a-sheet>
            </template>
            <a-divider class="grey lighten-3"></a-divider>
            <a-sheet class="px-6 py-4 u-cursor-pointer" :class="[check_org_inherit && !check_is_public ? 'grey lighten-5' : '']"  @click="!loading ? local_visibility_update('internal') : ''">
                <div class="u-flex align-center">
                    <a-icon size="20" :color="check_org_inherit && !check_is_public ? 'indigo darken-1' : 'grey darken-3'" class="mr-2 u-icon-nudge" style="top: -10px;">lock</a-icon>
                    <div>
                        <h3 :class="[check_org_inherit && !check_is_public ? 'indigo--text text--darken-1' : 'grey--text text--darken-3']" class="md-subtitle-2 font-weight-medium">Private</h3>
                        <h3 class="md-subtitle-2 grey--text text--darken-1 font-italic">Private at the {{ type === 'Organization' ? 'Organization-level' : 'Project-level'}}</h3>
                    </div>
                    <v-spacer></v-spacer>
                    <span v-if="check_org_inherit && !check_is_public">
                        <a-icon size="24" color="indigo darken-1" class="mr-2 u-icon-nudge" style="top: -8px;">done</a-icon>
                    </span>
                </div>
            </a-sheet>
            <a-divider class="grey lighten-3"></a-divider>
            <a-sheet class="px-6 py-4 u-cursor-pointer" :class="[check_org_inherit && check_is_public ? 'grey lighten-5' : '']" @click="!loading ? local_visibility_update('external-ready-only') : ''">
                <div class="u-flex align-center">
                    <a-icon size="20" :color="check_org_inherit && check_is_public ? 'orange darken-2' : 'grey darken-3'" class="mr-2 u-icon-nudge" style="top: -10px;">public</a-icon>
                    <div>
                        <h3 :class="[ check_org_inherit && check_is_public ? 'orange--text text--darken-2' : 'grey--text text--darken-3']" class="md-subtitle-2 font-weight-medium">Public</h3>
                        <h3 class="md-subtitle-2 grey--text text--darken-1 font-italic">Public at the {{ type === 'Organization' ? 'Organization-level' : 'Project-level'}}</h3>
                    </div>
                    <v-spacer></v-spacer>
                    <span v-if="check_org_inherit && check_is_public">
                        <a-icon size="24" color="orange darken-2" class="mr-2 u-icon-nudge" style="top: -8px;">done</a-icon>
                    </span>
                </div>
            </a-sheet>
            <a-sheet>
                <a-progress-linear height="2" :indeterminate="loading" :color="loading ? 'orange darken-2' : 'grey lighten-1'"></a-progress-linear>
            </a-sheet>
        </div>
    </a-menu>
</template>

<script>

export default {
    props: {
        type: {
            type: String,
            required: true
        },
        dense: {
            type: Boolean,
            default: false
        },
        tagType: {
            type: String,
            default: ''
        },
        orgVisibility: {
            type: String,
            default: ''
        },
        isPublic: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        check_is_public() {
           return this.isPublic
        },
        check_org_inherit() {
           return this.type === 'projectTag' || this.type === 'Organization'
        },
        check_system_tag_type() {
            return ['label', 'product', 'platform'].includes(this.tagType)
        }
    },

    methods: {
        local_visibility_revert() {
            this.$emit('revert')
        },
        local_visibility_update(value) {
            this.$emit('action', value)
        },
    }
}
</script>

<style scoped>
    .u-rounded-tag-left-corners {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        margin-right: 2px;
    }
    .u-rounded-tag-right-corners {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }
</style>

import { mapActions, mapState } from 'vuex'
import { storage } from '../helpers/helper-local-storage'

export default {
    data () {
        return {
            orgFields: {
                'fields[organizations]': 'id,subscription_plan_id,name,slug,logo,domain,projects_active_count,subscription_payment_status,subscription_schedule,subscription_plan_end_date,subscription_pending',
                'fields[subscription_plan]': 'id,name,level,base_amount,unit_amount'
            },
        }
    },

    computed: {
        ...mapState('Organization', {
            organization_plan: 'plan',
        }),
    },

    methods: {
        mixinGetOrgPlan (params = {}) {
            const { field = null } = params || {}
            if (!field) return this.organization_plan

            const [key1, key2] = field.split('.')
            return !key2 ? this.organization_plan[key1] : this.organization_plan[key1][key2]
        },

        async mixinFetchOrgPlanDetails (params) {
            await this.organization_get_plan({
                include: 'subscriptionPlan.features,systemOrganization',
                ...this.orgFields,
                ...params
            })
            storage.save('orgPlan', this.organization_plan)
        },

        ...mapActions('Organization', {
            organization_get_plan: 'get_plan',
        }),
    }
}

<template>
    <PartialMetaLayout
        title="Competitor Types"
        subtitle="Customize labels associated with competitors"
        :loading="loading"
    >
        <div>
            <draggable v-if="meta_list && meta_list.length" v-model="meta_list" :options="{handle: '.js-drag-handle'}" tag="div" class="u-elevation-custom-1 white u-rounded-corners">
                <a-card flat v-for="(meta, index) in meta_list" :key="meta.id">
                    <a-divider v-if="index !== 0" class="grey lighten-4"></a-divider>
                    <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-4 px-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pl-0 pr-1" v-if="loaderItem === meta.id">
                                    <a-progress-circular color="blue lighten-2" size="20" width="2" indeterminate></a-progress-circular>
                                </a-flex>
                                <template v-else>
                                    <a-flex shrink class="px-0" v-if="check_meta_permission('update')">
                                        <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle">drag_indicator</a-icon>
                                    </a-flex>
                                </template>
                                <a-flex xs7 xl8>
                                    <h2 class="md-subtitle-1 font-weight-medium mb-0">{{ meta.value }}</h2>
                                </a-flex>
                                <a-spacer></a-spacer>
                                <a-flex shrink v-if="!localHasFlagCompetitor">
                                    <a-chip color="white" class="text-capitalize" style="border: 1px solid #eeeeee !important;" text-color="grey darken-2" small>{{ meta.selection_type ?  meta.selection_type : 'multiple' }}</a-chip>
                                </a-flex>
                                <a-flex shrink>
                                    <a-chip color="primary" text-color="white" small>{{ meta.status }}</a-chip>
                                </a-flex>
                                <a-flex shrink pl-1 v-if="check_meta_permission('update')">
                                    <a-btn color="grey darken-2" @click="localEdit(meta.id)" text icon small>
                                        <a-icon small>edit</a-icon>
                                    </a-btn>
                                </a-flex>
                                <a-flex shrink pl-0 v-if="check_meta_permission('destroy')">
                                    <a-btn color="grey darken-2" @click="localShowTransferDialog(meta)" text icon small>
                                        <a-icon small>delete</a-icon>
                                    </a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-card-text>
                </a-card>
            </draggable>
            <a-card v-if="check_meta_permission('store')" class="u-elevation-custom-1" :class="{ 'mt-3': meta_list && meta_list.length }">
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="py-2">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-0">
                                    <a-icon color="grey" size="20">add</a-icon>
                                </a-flex>
                                <a-flex pl-0>
                                    <a-text-field
                                        v-model="metaItemValue"
                                        placeholder="Type picklist value and hit enter"
                                        solo flat hide-details
                                        @keyup.enter="localStore()"
                                    >
                                    </a-text-field>
                                </a-flex>
                                <a-flex shrink>
                                    <a-menu bottom offset-y>
                                        <template v-slot:activator="{ on }">
                                            <a-chip class="px-2" color="grey darken-2" text-color="white" small left v-on="on">
                                                {{ metaItemStatus }}
                                                <a-icon class="ml-1" color="white" size="18">arrow_drop_down</a-icon>
                                            </a-chip>
                                        </template>
                                        <a-list>
                                            <a-list-item v-for="(status, index) in statuses" :key="'status_'+index" @click="localMetaStatusSelect(status)">
                                                <a-list-item-title>{{ status }}</a-list-item-title>
                                            </a-list-item>
                                        </a-list>
                                    </a-menu>
                                </a-flex>
                                <a-flex shrink class="pr-4">
                                    <a-btn color="grey darken-2" text icon small @click="localStore()">
                                        <a-icon small>keyboard_return</a-icon>
                                    </a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(meta_response, 'value') && meta_form_mode === 'add'">
                <a-icon size="16" color="red darken-2">warning</a-icon>
                {{ $response(meta_response, 'value') }}
            </span>
            <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(meta_response, 'percentage') && meta_form_mode === 'add'">
                <a-icon size="16" color="red darken-2">warning</a-icon>
                {{ $response(meta_response, 'percentage') }}
            </span>
        </div>

        <!-- Meta edit form -->
        <a-dialog v-model="dialogMetaForm" max-width="700" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="() => false">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-start>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>view_column</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1 mb-1">{{ (meta_form_mode == 'add') ? 'Create Picklist Value' : 'Edit Existing Picklist Value' }}</h2>
                                    <p class="md-subtitle-2 mb-0">{{ (meta_form_mode == 'add') ? 'You can add new picklist value to the system.' : 'You can edit existing picklist value.' }}</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Label</label>
                                    <a-text-field
                                        v-model="meta_item.value"
                                        placeholder="Enter Label"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(meta_response, 'value') && meta_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ $response(meta_response, 'value') }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the label to be selected.
                                    </span>
                                </a-flex>
                                <a-flex xs12 pt-2>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Status</label>
                                    <a-select
                                        v-model="meta_item.status"
                                        :items="statuses"
                                        placeholder="Status"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-select>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(meta_response, 'status')">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ $response(meta_response, 'status') }}
                                    </span>
                                    <template v-else>
                                        <span class="md-caption grey--text text--darken-2 u-flex align-center mt-2">
                                            <a-icon size="16" class="mr-1">info</a-icon>
                                            Select the status for the label.
                                        </span>
                                    </template>
                                </a-flex>
                                <template v-if="!localHasFlagProductCompetitor">
                                    <a-flex xs12 pt-2>
                                        <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Product Visibility (Optional)</label>
                                        <a-autocomplete
                                            v-model="localProductTags"
                                            :items="tag_list"
                                            item-text="label"
                                            item-value="id"
                                            placeholder="Select product tags"
                                            background-color="neutral"
                                            solo flat hide-details multiple hide-selected
                                        >
                                            <template #item="{ item }">
                                                <g-tags :text-range="20" :tag="item" :key="item.id" hide-clear-icon></g-tags>
                                            </template>
                                            <template #selection="{ item }">
                                                <g-tags :tag="item" @destroy="() => localRemoveProductTag(item.id)" :key="item.id"></g-tags>
                                            </template>
                                        </a-autocomplete>
                                        <span class="md-caption grey--text text--darken-2 u-flex align-center mt-2">
                                            <a-icon size="16" class="mr-1">info</a-icon>
                                            Choose products to limit the visibility of Competitor Type
                                        </span>
                                    </a-flex>
                                </template>
                                <template v-if="!localHasFlagCompetitor">
                                    <a-flex xs12 pt-2>
                                        <v-checkbox class="d-block" :value="localCheckSectionType" @click="localUpdateSectionType(meta_item.selection_type)" color="primary" hide-details dense small label="Restrict to single selection"></v-checkbox>
                                    </a-flex>
                                    <a-flex xs12 pt-2 v-if="localMetaItem.selection_type === 'multiple' && meta_item.selection_type === 'single'">
                                        <div class="amber lighten-5 pa-4">
                                            <p class="md-body-1 d-block mb-0 orange--text text--darken-3 font-weight-medium">
                                                <a-icon v-on="on" color="amber darken-3" size="22" class="mr-1 u-icon-nudge-xs" style="top: -3px;">warning</a-icon> Value Migration:
                                            </p>
                                            <p class="md-body-1 d-block mb-0 grey--text text--darken-2 mt-2 ml-8">Switching the field type from multiple to single may lead to the loss of previous selections made in the project.  Please select one of the following options to manage the previous selections:</p>
                                            <v-radio-group class="mt-3 ml-8" hide-details v-model="migrationType">
                                                <v-radio :ripple="false" class="mt-2" label="Retain the oldest selection and discard the others" value="oldest_one"></v-radio>
                                                <v-radio :ripple="false" class="mt-2" label="Retain the latest selection and discard the others" value="latest_one"></v-radio>
                                                <v-radio :ripple="false" class="mt-2" label="Discard all selections and start over" value="clear_all"></v-radio>
                                            </v-radio-group>
                                        </div>
                                        <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="localMigrateError">
                                            <a-icon size="16" color="red darken-2">warning</a-icon>
                                            Select a value for migration.
                                        </span>
                                    </a-flex>
                                </template>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="localUpdate()" :loading="loading" :disabled="loading">Save Value</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="localClose()" :disabled ="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>

        <a-dialog max-width="480" v-model="dialogTransferMeta" persistent>
            <a-card class="pa-6 pb-7 px-8">
                <h2 class="md-heading-5 grey--text text--darken-3">Delete Competitor Type</h2>
                <div class="mt-2 md-subtitle-1 grey--text text--darken-3">
                    <span>You are about to delete</span>
                    <h3 class="md-subtitle-2 font-weight-bold grey--text text--darken-3 d-inline-block ml-1">"{{ metaToDelete.value | truncateText(15) }}"</h3>
                    <span>, this action cannot be reverted.</span>
                    <template v-if="metaToDelete.competitors_count > 0">
                        <div class="md-subtitle-1 grey--text text--darken-1 mt-2">
                            It is associated with <span class="font-weight-bold grey--text text--darken-2">{{ metaToDelete.competitors_count }}</span> competitors. Select a type below to transfer these competitors.
                        </div>
                        <a-autocomplete
                            v-model="transferMeta"
                            :items="localFilteredMeta()"
                            item-text="value"
                            item-value="id"
                            background-color="neutral"
                            placeholder="Select type to transfer"
                            class="u-rounded-corners mt-4"
                            solo flat hide-details clearable
                        >
                            <template #no-data>
                                <div class="md-body-1 grey--text px-4 py-2">No Data Found</div>
                            </template>
                        </a-autocomplete>
                        <div class="u-flex align-center pa-2 mt-5 orange lighten-4 u-rounded-corners">
                            <a-icon size="18" class="mr-1 u-icon-nudge" style="top: 0px;" color="brown darken-1">note_alt</a-icon>
                            <span class="md-caption brown--text text--darken-1 font-weight-medium">This action won't trigger any workflows.</span>
                        </div>
                    </template>
                </div>
                <div :class="[metaToDelete.competitors_count > 0 ? 'mt-6' : 'mt-5']">
                    <a-btn small class="ma-0 px-3 elevation-0 red lighten-5 red--text text--darken-2" v-if="metaToDelete" @click="localDestroy(metaToDelete.id)" :disabled="metaToDelete.competitors_count > 0 ? localCheckValidateId(transferMeta) : false" :loading="loading">Delete Type</a-btn>
                    <a-btn small color="grey" class="ma-0 ml-4 grey lighten-4" text @click="dialogTransferMeta = !dialogTransferMeta">Cancel</a-btn>
                </div>
            </a-card>
        </a-dialog>
    </PartialMetaLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MetaPermission from '@/mixins/mixin-meta-permission'
import { SColorPalette } from '@/config/config-shared-components'
import { TabMeta } from '@/config/config-tab-components'
import { PartialMetaLayout } from './Partials'

export default {
    mixins: [MetaPermission],

    components: {
        TabMeta, SColorPalette, PartialMetaLayout
    },

    data() {
        return {
            dialogMetaForm: false,
            dialogTransferMeta: false,
            localMigrateError: false,
            statuses: ['Primary', 'Secondary', 'Incumbent', 'Other'],
            metaItemStatus: 'Primary',
            metaItemValue: '',
            timerUpdateDebounce: null,
            loaderItem: null,
            transferMeta: null,
            metaToDelete: {},
            metaFilters: {},
            META_FILTERS: {
                TYPE: 'filter[type]',
                FIEDS: 'fields[metas]',
                INCLUDE: 'include',
                SORT: 'sort',
                COUNT: 'count',
            },
            preferencesList: [],
            migrationType: null,
            localMetaItem: {},
            localProductTags : [],
            localRemovedProductTags : [],
            localCloneProductTags: [],
        }
    },

    mounted() {
        this.localIndex()
    },

    computed: {
        meta_list: {
            get() {
                return this.$store.state.Meta.list
            },
            set(list) {
                this.localReorder(list)
            }
        },

        localCheckSectionType () {
            return this.meta_item && this.meta_item.selection_type === 'multiple' ? false : true
        },

        localHasFlagCompetitor () {
            return !(this.localHasCheckCompetitorKey('organization.competitor_type_selection') && this.user_self && this.user_self.organization && this.user_self.organization.flag_competitor_preferences === 1)
        },

        localHasFlagProductCompetitor () {
            return !(this.localHasCheckCompetitorKey('organization.link_competitor_type_product_tag') && this.user_self && this.user_self.organization && this.user_self.organization.flag_competitor_preferences === 1)
        },

        localHasMigrateChages () {
            return !(this.localMetaItem.selection_type === 'multiple' && this.meta_item.selection_type === 'single' && this.migrationType === null)
        },

        ...mapState('Meta', {
            meta_item: 'item',
            meta_filters: 'filters',
            meta_form_mode: 'form_mode',
            meta_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('Preference', {
            preference_list: 'list'
        }),

        ...mapState('Tag', {
            tag_list: 'list',
        }),
    },

    methods: {
        async localIndex () {
            if (this.check_meta_permission('index') === false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.localFetchPreferences()
            this.localLoadProductTags()
            this.meta_clear()
            this.metaFilters[this.META_FILTERS.TYPE] = 'competitor_type'
            if (!this.localHasFlagCompetitor) this.metaFilters[this.META_FILTERS.FIEDS] = 'id,type,value,status,selection_type'
            else this.metaFilters[this.META_FILTERS.FIEDS] = 'id,type,value,status'
            if (!this.localHasFlagProductCompetitor) this.metaFilters[this.META_FILTERS.INCLUDE] = 'competitorsCount,products'
            else this.metaFilters[this.META_FILTERS.INCLUDE] = 'competitorsCount'
            this.metaFilters[this.META_FILTERS.SORT] =  'order'
            this.metaFilters[this.META_FILTERS.COUNT] =  999
            await this.meta_index(this.metaFilters)
        },

        async localLoadProductTags () {
            await this.tag_clear()
            await this.tag_index({
                sort: 'order',
                'filter[type]': 'product',
                'fields[tags]': 'id,label,color',
                count: 999
            })
        },

        async localEdit (id) {
            this.localMigrateError = false
            this.localMetaItem = {}
            await this.meta_clear_item()
            await this.meta_select({id: id})
            this.localMetaItem =  _.cloneDeep(this.meta_item)
            if (!this.localHasFlagProductCompetitor) this.localGetProductTags()
            this.dialogMetaForm = true
        },

        localGetProductTags () {
            if (this.meta_item.products) {
                this.meta_item.products.forEach(product => {
                    if (product.association) {
                        this.localProductTags.push(product.association.target_id)
                    }
                });
                this.localCloneProductTags = _.cloneDeep(this.localProductTags)
            }
        },

        async localCreate () {
            await this.meta_clear_item()
            this.dialogMetaForm = true
        },

        async localStore () {
            this.meta_item.type = 'competitor_type'
            this.meta_item.value = this.metaItemValue
            this.meta_item.status = this.metaItemStatus
            if (!this.localHasFlagCompetitor) this.meta_item.selection_type = 'multiple'
            await this.meta_store(this.meta_item)
            if (this.meta_response.status === 'success') {
                this.metaItemValue = ''
                this.metaItemStatus = 'Primary'
                await this.meta_clear_item()
            }
        },

        async localClose () {
            this.dialogMetaForm = false
            this.localRemoveTagRelatedItem()
        },

        localRemoveTagRelatedItem () {
            this.localProductTags = []
            this.localCloneProductTags = []
            this.localRemovedProductTags = []
        },

        async localUpdate () {
            if (!this.localHasFlagCompetitor) {
                this.meta_item.migrate_type = this.migrationType
                if (!this.localHasMigrateChages) {
                    this.localMigrateError = true
                    return
                }
            }
            await this.meta_update(this.meta_item)
            if (this.meta_response.status === 'success') {
                this.localMigrateError = false
                if (!this.localHasFlagProductCompetitor) await this.localCheckProductTagUpdate()
                this.metaItemStatus = 'Primary'
                this.dialogMetaForm = false
                if (!this.localHasFlagCompetitor) this.migrationType = null
                this.localRemoveTagRelatedItem()
                await this.meta_clear_item()
                this.localClearDebounceTimer()
            }
        },

        async localShowMeta () {
            this.metaFilters[this.META_FILTERS.TYPE] = 'competitor_type'
            this.metaFilters[this.META_FILTERS.FIEDS] = 'id,type,value,status,selection_type'
            this.metaFilters[this.META_FILTERS.INCLUDE] = 'competitorsCount,products'
            await this.meta_show({
                id : this.meta_item.id,
                mode: 'update_only',
                params: this.metaFilters,
            })
        },

        async localCheckProductTagUpdate () {
            if (!_.isEqual(this.localProductTags, this.localCloneProductTags)) {
                await this.localAttachProductTag()
                await this.localCheckProductTagRemove()
                this.localShowMeta()
            }
        },

        async localCheckProductTagRemove () {
            if (this.localRemovedProductTags.length) {
                await this.association_bulk_destroy({
                    ids: this.localRemovedProductTags,
                    source_id: this.meta_item.id,
                    source_type: 'Meta'
                })
            }
        },

        async localRemoveProductTag (id) {
            const index = this.localProductTags.findIndex(item => item === id)
            if (index !== -1) {
                this.localProductTags.splice(index, 1)
                const product = this.meta_item.products.find(item => item.id === id)
                if (product && product.association) this.localRemovedProductTags.push(product.association.id)
            }
        },

        async localAttachProductTag () {
            const prdouctIds = []
            this.localProductTags.forEach(tag => {
                prdouctIds.push(this.$appendId({ target_id: tag,}))
            });
            await this.association_bulk_store({
                data: prdouctIds,
                source_type: 'Meta',
                source_id: this.meta_item.id,
                target_type: 'Tag',
                type: 'competitor_type_product'
            })
        },

        localUpdateSectionType (selection_type) {
            this.meta_item.selection_type = selection_type === 'multiple' ? 'single' : 'multiple'
        },

        async localDestroy (id) {
            await this.meta_destroy({ id, transfer_meta_id: this.transferMeta ?? null })
            if (this.dialogTransferMeta) this.dialogTransferMeta = false
        },

        localShowTransferDialog (meta) {
            this.transferMeta = null
            this.metaToDelete = meta
            this.dialogTransferMeta = true
        },

        async localReorder (list) {
            this.meta_reorder({list: list})
        },

        async localMetaStatusSelect (status) {
            this.metaItemStatus = status
        },

        async localUpdateDebounced (color, meta) {
            await this.meta_select({id: meta.id})
            this.localClearDebounceTimer()
            if (color) this.meta_item.color = color
            this.loaderItem = meta.id
            this.timerUpdateDebounce = setTimeout(this.localUpdate, 200)
        },

        async localClearDebounceTimer () {
            if (this.timerUpdateDebounce) {
                clearTimeout(this.timerUpdateDebounce)
                this.timerUpdateDebounce = null
                this.loaderItem = null
            }
        },

        localFilteredMeta () {
            if (this.metaToDelete) {
                return this.meta_list.filter(({ id }) => id !== this.metaToDelete.id)
            }
        },

        localCheckValidateId (id) {
            const metaIds = this.meta_list.map(({ id }) => id)
            return !(metaIds.includes(id))
        },

        async localFetchPreferences () {
            await this.preference_index()
            this.preferencesList = _.cloneDeep(this.preference_list)
        },

        localHasCheckCompetitorKey (key) {
            const result = this.preferencesList.find(i => i.key === key)
            if (result && result.value === 'Yes') return true
            return false
        },

        ...mapActions('Preference', {
            preference_index: 'index',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_store: 'store',
            meta_show: 'show',
            meta_update: 'update',
            meta_select: 'select',
            meta_destroy: 'destroy',
            meta_reorder: 'reorder',
            meta_clear: 'clear',
            meta_clear_item: 'clear_item',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_clear: 'clear',
        }),

        ...mapActions('Association', {
            association_bulk_store: 'bulk_store',
            association_bulk_destroy: 'bulk_destroy',
        }),
    },
}
</script>

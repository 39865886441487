import ProjectExternal from './External/Project';
import UsecaseExternal from './External/Usecase';
import TestcaseExternal from './External/Testcase';
import SuccessCriterionExternal from './External/SuccessCriterion';
import TaskExternal from './External/Task';
import MilestoneExternal from './External/Milestone';
import DocumentExternal from './External/Document';
import LinkExternal from './External/Link';
import NoteExternal from './External/Note';
import StatusUpdateExternal from './External/StatusUpdate'
import MetaExternal from './External/Meta'
import CommentExternal from './External/Comment'
import AssigneeExternal from './External/Assignee'
import CollaboratorExternal from './External/Collaborator'
import TicketExternal from './External/Ticket'
import VisibilityExternal from './External/Visibility'
import TagSectionExternal from './External/TagSection'
import AttachmentExternal from './External/Attachment'

export default {
    AttachmentExternal,
    ProjectExternal,
    UsecaseExternal,
    TestcaseExternal,
    SuccessCriterionExternal,
    StatusUpdateExternal,
    TaskExternal,
    MilestoneExternal,
    DocumentExternal,
    LinkExternal,
    NoteExternal,
    MetaExternal,
    CommentExternal,
    AssigneeExternal,
    CollaboratorExternal,
    TicketExternal,
    VisibilityExternal,
    TagSectionExternal,
}

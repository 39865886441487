<template>
    <div class="pt-5">
        <template v-if="localShowHeader">
            <hubspot-breadcrumb></hubspot-breadcrumb>
            <hubspot-header :installation="installation_item"></hubspot-header>
            <hubspot-tabs :installation="installation_item"></hubspot-tabs>
        </template>
        <div class="">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import HubspotBreadcrumb from './Partials/HubspotBreadcrumb'
import HubspotHeader from './Partials/HubspotHeader'
import HubspotTabs from './Partials/HubspotTabs'

export default {
    components: {
        HubspotBreadcrumb,
        HubspotHeader,
        HubspotTabs,
    },

    mounted() {
        this.localIndex()
    },

    computed: {
        localShowHeader () {
            const whiteList = ['appstore-hubspot-workflows-create', 'appstore-hubspot-workflows-edit']
            return !whiteList.includes(this.$route.name)
        },

        ...mapState('WorkflowLog', {
            workflow_log_list: 'list',
            workflow_log_filters: 'filters',
        }),

        ...mapState('Installation', {
            installation_item: 'item',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }

            await this.installation_clear_item()

            await this.installation_show({id: this.$route.params.id})

            if (this.installation_item && !this.installation_item.is_active) {
                this.$router.replace({ name: 'appstore-hubspot-accounts' })
            }
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('Workflow', {
            workflow_clear: 'clear',
            workflow_clear_item: 'clear_item',
        }),

        ...mapActions('WorkflowLog', {
            workflow_log_index: 'index',
            workflow_log_clear: 'clear',
        }),
    },
}
</script>

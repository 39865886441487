<template>
    <a-main>
        <g-settings-breadcrumb class="mt-5"></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="py-8 pt-4 mb-12">
            <a-layout>
                <g-page-header
                    title="Layout Designer"
                    :subtitle="`Manage a layout for custom fields and tags under your organization.`"
                    icon="space_dashboard"
                    class="px-0 mb-1"
                >
                </g-page-header>
            </a-layout>
            <a-layout v-if="!preview_mode && !page_loading" class="u-relative c-notification-tab-slider">
                <v-tabs v-model="active_tab" color="indigo darken-1" background-color="transparent">
                    <v-tabs-slider color="indigo darken-1"></v-tabs-slider>
                    <v-tab v-for="item in custom_field_sections" :key="item.id" :href="'#tab-'+item.id" @click="local_change_layout_module(item.type)">
                        <span class="text-capitalize">{{ item.label }}</span>
                    </v-tab>
                </v-tabs>
                <div class="u-absolute u-wfull" style="border-bottom: 2px solid #E0E0E0; bottom: 0px;"></div>
            </a-layout>
            <div v-if="page_loading">
                <a-divider class="grey lighten-2 mb-4"></a-divider>
                <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-capitalize">Fetching Sections..</h3>
            </div>
            <a-responsive v-if="!page_loading" :max-width="$vuetify.breakpoint.lgAndDown ? '940' : '1264'" class="mx-auto">
                <a-layout v-if="local_loading" class="mt-8 mx-2">
                    <div class="u-flex flex-column u-wfull">
                        <a-card v-for="n in 1" :key="n" class="u-wfull u-shadow u-rounded-corners mb-6">
                            <div class="mx-2">
                                <div class="mx-4 my-6 u-flex justify-space-between">
                                    <loader-template height="16" width="120px"></loader-template>
                                    <loader-template height="16" width="120px"></loader-template>
                                </div>
                                <a-divider></a-divider>
                                <div class="mx-4 my-6">
                                    <div class="u-flex justify-space-between">
                                        <loader-template height="99" width="48%" class="mb-5"></loader-template>
                                        <loader-template height="99" width="48%" class="mb-5"></loader-template>
                                    </div>
                                    <loader-template height="99" width="100%" class="mb-5"></loader-template>
                                    <loader-template height="99" width="100%"></loader-template>
                                </div>
                            </div>
                        </a-card>
                    </div>
                </a-layout>
                <div v-if="!local_loading && !preview_mode" class="mx-2">
                    <a-layout align-center class="my-4">
                        <a-flex class="u-flex align-center">
                            <div class="md-heading-6 indigo--text text--darken-1 font-weight-medium mr-3">{{ moduleType === 'project_fields_and_tags' ? 'Fields & Tags' : 'Fields' }}</div>
                            <a-tooltip bottom :nudge-right="moduleType === 'project_fields_and_tags' ? 236 : 210">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="md-caption font-weight-bold grey darken-3 px-2 py-1 white--text u-rounded-corners-full u-flex" v-if="local_layout_design_list">
                                        <a-icon v-if="!unsaved_changes && !!local_check_empty_sections(local_layout_design_list)" size="16" color="amber darken-2" class="mr-1">info</a-icon>
                                        <span>
                                            {{ local_layout_design_list.length }} {{ local_layout_design_list.length > 1 ? 'Sections' : 'Section'}}
                                        </span>
                                    </div>
                                </template>
                                <span class="u-flex align-center">
                                    Sections without field will not be shown in the "
                                    {{ moduleType === 'project_fields_and_tags' ? 'Project' : 'Ticket' }}
                                    <a-icon size="16" color="white" class="mx-1">arrow_forward</a-icon>
                                    {{ moduleType === 'project_fields_and_tags' ? 'Fields & Tags' : 'Fields' }}
                                    " layout.
                                </span>
                            </a-tooltip>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink class="pr-0">
                            <a-menu bottom offset-y nudge-bottom="6" :disabled="local_loading" v-if="moduleType === 'project_fields_and_tags'">
                                <template v-slot:activator="{ on, value }">
                                    <a-card height="32" class="py-2 pr-2 u-rounded-corners u-overflow-hidden u-shadow u-flex-center-y" v-on="on">
                                        <a-btn plain small :ripple="false" class="d-flex align-center">
                                            Manage Fields
                                        </a-btn>
                                        <a-icon size="20" class="ma-0">arrow_drop_{{ value ? 'up' : 'down' }}</a-icon>
                                    </a-card>
                                </template>
                                <a-list class="u-list-condensed py-2 pa-1">
                                    <a-list-item v-for="(item, index) in local_configure_list" :key="index + '_' + item.label" @click="local_direct_to_configure(item.value)">
                                            <a-list-item-title>
                                                <span class="md-subtitle-2 grey--text text--darken-1">{{ item.label }}</span>
                                            </a-list-item-title>
                                    </a-list-item>
                                </a-list>
                            </a-menu>
                            <template v-else>
                                <a-card height="32" class="py-2 pr-2 u-rounded-corners u-overflow-hidden u-shadow u-flex-center-y" @click="local_direct_to_configure('custom_field')">
                                    <a-btn plain small :ripple="false" :disabled="local_loading" class="d-flex align-center">
                                        Manage Fields
                                    </a-btn>
                                    <a-icon size="14" class="ma-0">open_in_new</a-icon>
                                </a-card>
                            </template>
                        </a-flex>
                        <a-flex shrink class="u-flex">
                            <a-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a-sheet @click="local_switch_preview_mode()" v-on="on" height="32" width="32" class="mr-3 pa-0 white u-shadow u-flex-center-y justify-center u-cursor-pointer u-rounded-corners">
                                        <a-icon size="18">visibility</a-icon>
                                    </a-sheet>
                                </template>
                                <span>Preview</span>
                            </a-tooltip>
                            <a-btn small depressed height="32" class="mr-3 grey lighten-1 white--text" :disabled="!unsaved_changes" @click="dialog_cancel_changes=true">Cancel</a-btn>
                            <a-btn small depressed height="32" class="grey darken-4 white--text c-save-layout-btn" :disabled="!unsaved_changes" @click="local_store_layout()">Save</a-btn>
                        </a-flex>
                    </a-layout>
                    <draggable
                        v-model="local_layout_design_list"
                        :options="{handle: '.js-drag-handle'}"
                        scroll-sensitivity="400"
                        v-bind="dragOptions"
                        ghost-class="c-ghost"
                        @start="onDrag=true"
                        @end="local_trigger_sections_modified()"
                    >
                        <transition-group type="transition" :name="!onDrag ? 'flip-list' : null">
                            <a-card
                                v-for="(section, index) in local_layout_design_list"
                                :key="section.id"
                                color="white"
                                class="mb-8 mx-auto u-shadow u-wfull u-rounded-corners c-section-card u-relative"
                            >
                                <div :class="{'c-section-disabled': !section.is_active}">
                                    <v-hover v-slot="{ hover }">
                                        <div class="pa-6" @mouseover="local_remove_hover_element(null, null, index)">
                                            <a-layout align-center>
                                                <a-flex class="u-flex align-center">
                                                    <div style="width: 24px" class="u-flex">
                                                        <a-icon v-if="hover" size="20" color="grey" class="js-drag-handle u-cursor-pointer">drag_indicator</a-icon>
                                                    </div>
                                                    <a-tooltip bottom :disabled="response_title && (response_title.status !== 'error')">
                                                        <template v-slot:activator="{on}">
                                                            <div
                                                                v-on="on"
                                                                :class="[(response_title && (response_title.id === section.id) && response_title.status === 'error' ? 'c-border-error c-wiggle-short': ''),'u-rounded-corners u-wfull']"
                                                                :style="[(currentEditId === section.id) ? { border: '2px solid #1495bd' } : { cursor: 'text' }]"
                                                            >
                                                                <div v-if="currentEditId === section.id" class="u-flex-center-y u-wfull py-1">
                                                                    <textarea
                                                                        v-model="section.name"
                                                                        rows="1"
                                                                        class="u-wfull md-subtitle-1 d-block pl-1"
                                                                        style="outline: none; resize: none;"
                                                                        :ref="'title_input' + section.id"
                                                                        @click.stop="{}"
                                                                        @blur="local_focus_title_input(section, 'blur')"
                                                                        @keydown.esc="local_inline_update(section, 'clear')"
                                                                        @keydown.enter.prevent="local_inline_update(section, 'click')"
                                                                        autofocus
                                                                    ></textarea>
                                                                    <a-btn icon small depressed width="24" height="24" class="mr-2" @click.stop="local_inline_update(section, 'click')">
                                                                        <a-icon color="green darken-1">check</a-icon>
                                                                    </a-btn>
                                                                    <a-btn icon small depressed width="24" height="24" @mousedown="local_revert_section_title(section)" @click.stop="local_inline_update(section, 'clear')">
                                                                        <a-icon color="red darken-1">clear</a-icon>
                                                                    </a-btn>
                                                                </div>
                                                                <div class="u-wfull u-flex" @click="(response_title && response_title.id) ? { } : local_title_edit_focus(section)" v-else>
                                                                    <h2 class="c-module-title md-subtitle-1 grey--text text--darken-3" :title="section.name">
                                                                        {{ section.name | truncateText(local_is_lgdown ? 40 : 80) }}
                                                                    </h2>
                                                                    <a-tooltip bottom nudge-top="10" :nudge-right="moduleType === 'project_fields_and_tags' ? 280 : 254" :disabled="!section.is_active" v-if="!unsaved_changes && (section.fields && !section.fields.length) && !!section.is_active">
                                                                        <template v-slot:activator="{ on }">
                                                                            <a-icon v-on="on" class="ml-2 u-cursor-pointer" color="amber darken-2" size="20">info</a-icon>
                                                                        </template>
                                                                        <span class="u-flex align-center">
                                                                            Sections without field will not be shown in the "
                                                                            {{ moduleType === 'project_fields_and_tags' ? 'Project' : 'Ticket' }}
                                                                            <a-icon size="16" color="white" class="mx-1">arrow_forward</a-icon>
                                                                            {{ moduleType === 'project_fields_and_tags' ? 'Fields & Tags' : 'Fields' }}
                                                                            " layout.
                                                                        </span>
                                                                    </a-tooltip>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <span v-if="response_title && response_title.text">{{response_title.text}}</span>
                                                    </a-tooltip>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink>
                                                    <div class="u-flex align-center">
                                                        <a-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <g-toggle
                                                                    v-on="on"
                                                                    v-model="section.is_active"
                                                                    custom-bg dense-xl hide-clear-icon
                                                                    :disabledColor="section.is_active ? '#4CAF50' : '#d0d0d0'"
                                                                    inactiveColor="grey darken-2"
                                                                    @change="local_change_section_display_status(section)"
                                                                    :class="{'c-toggle': !section.is_active}"
                                                                    style="marginTop: 1px"
                                                                    :color="section.is_active ? 'green darken-1' : 'grey darken-2'"
                                                                ></g-toggle>
                                                            </template>
                                                            <template>
                                                                <div>
                                                                    <template v-if="section.is_active">Disable</template>
                                                                    <template v-else>Enable</template>
                                                                </div>
                                                            </template>
                                                        </a-tooltip>
                                                        <a-menu
                                                            bottom
                                                            offset-y
                                                            min-width="200"
                                                            :close-on-content-click="!delete_process"
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <div v-on="on" class="ml-8 u-flex u-cursor-pointer" style="paddingTop: 1px">
                                                                    <a-icon size="20">more_vert</a-icon>
                                                                </div>
                                                            </template>
                                                            <a-list class="u-list-condensed py-2 pa-1">
                                                                <template v-for="(item) in section_more_options">
                                                                    <a-list-item v-if="(item.value === 'moveUp' && index > 0) || (item.value === 'moveDown' && index < local_layout_design_list.length-1) || (item.value === 'rename')" :key="item.value" :disabled="item.value === 'rename' && !section.is_active" class="mx-1 u-rounded-corners-lg" @click="local_modify_section(section, index, item.value)" @mouseover="delete_process = false">
                                                                        <a-list-item-title>
                                                                            <span :class="[{ 'u-opacity-40' : item.value === 'rename' && !section.is_active},'md-subtitle-2 grey--text text--darken-1']">{{ item.label }}</span>
                                                                        </a-list-item-title>
                                                                    </a-list-item>
                                                                </template>
                                                                <!-- <a-divider></a-divider> -->
                                                                <a-list-item class="mx-1 u-rounded-corners-lg" v-if="!current_remove_section" @click="local_select_delete_section(section)" @mouseover="delete_process = true">
                                                                    <a-list-item-title class="red--text">
                                                                        <span class="md-subtitle-2">Delete</span>
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                                <a-list-item v-else class="mx-1 red lighten-5 u-rounded-corners-lg" @click="local_delete_section()">
                                                                    <a-list-item-title class="red--text text--darken-1">
                                                                        <span class="md-subtitle-2">Confirm Delete?</span>
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                            </a-list>
                                                        </a-menu>
                                                    </div>
                                                </a-flex>
                                            </a-layout>
                                        </div>
                                    </v-hover>
                                    <a-divider></a-divider>
                                    <div class="pa-6 u-wfull">
                                        <draggable
                                            v-model="section.fields"
                                            :group="!section.is_active ? 'non-droppable' : 'a'"
                                            scroll-sensitivity="400"
                                            ghost-class="c-ghost"
                                            :options="{handle: '.js-drag-handle'}"
                                            v-bind="dragOptions"
                                            @start="onDrag=true"
                                            @end="local_fields_reorder()"
                                        >
                                            <transition-group type="transition" :name="!onDrag ? 'flip-list' : null" class="u-wfull c-section-container">
                                                <a-card
                                                    v-for="(field, fieldIndex) in section.fields"
                                                    :key="field.id"
                                                    flat
                                                    class="u-relative"
                                                    :class="[{'c-field-card': field.id !== 'dummy_id'}, field.width === '50%' ? 'c-field-card__half' : 'c-field-card__full', 'mb-5']"
                                                >
                                                    <div v-if="field && field.id !== 'dummy_id'">
                                                        <div @mouseover="local_remove_hover_element(field, fieldIndex, index)">
                                                            <div class="u-flex align-center justify-space-between px-2" @mouseover="hide_add_field=true" @mouseleave="hide_add_field=false" :class="[{'js-drag-handle u-cursor-pointer': local_check_section_status(section)} ]">
                                                                <div class="u-flex align-center py-3">
                                                                    <v-hover v-slot="{ hover }">
                                                                        <a-icon
                                                                            size="20"
                                                                            color="grey darken-1"
                                                                            @mouseover="hide_add_field=true"
                                                                            @mouseleave="hide_add_field=false"
                                                                            :class="[{'js-drag-handle u-cursor-pointer': hover && local_check_section_status(section)} ]"
                                                                        >
                                                                            {{ hover && local_check_section_status(section) ? 'drag_indicator' : local_get_custom_field_type_item(field) }}
                                                                        </a-icon>
                                                                    </v-hover>
                                                                    <span class="md-body-1 px-3">
                                                                        <template v-if="field.parent_type === 'CustomField'">{{ field.parent.label | truncateText(38) }}</template>
                                                                        <template v-else>
                                                                            {{ field.parent_type === 'SystemTagSection' ? field.parent.value : field.parent.name | truncateText(38)}}
                                                                        </template>
                                                                    </span>
                                                                </div>
                                                                <a-tooltip bottom :disabled="!section.is_active">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div v-on="on" v-if="(field.parent && !field.parent.is_active)">
                                                                            <span class="amber--text text--darken-3 amber lighten-5 md-subtitle-2 px-2 py-1 u-rounded-corners-full">Disabled</span>
                                                                        </div>
                                                                    </template>
                                                                    <span>It can be enabled from {{ field.parent_type === 'CustomTagSection' ? 'tags' : 'custom fields' }} settings.</span>
                                                                </a-tooltip>
                                                            </div>
                                                            <a-divider color="grey lighten-3"></a-divider>
                                                            <a-layout align-center class="mx-2 my-0">
                                                                <a-tooltip bottom :disabled="!local_check_full_width_field(field) || !local_check_section_status(section)">
                                                                    <template v-slot:activator="{ on }">
                                                                        <a-flex v-on="on" shrink class="c-field-width-toggle u-flex align-center justify-space-between px-1 py-1 u-rounded-corners my-2">
                                                                            <div :class="[ field.width === '50%' ? 'c-field-width-toggle__active' : 'grey--text', 'mr-2 text-center u-cursor-pointer', { 'u-opacity-50': local_check_full_width_field(field) }, {'c-field-disabled': field.parent && !field.parent.is_active} ]"
                                                                                @click="local_check_section_status(section) && !local_check_full_width_field(field) && field.parent.is_active ? local_change_field_width(index, field, '50%') : {}"
                                                                            >
                                                                                <span class="md-body-2 font-weight-medium px-1">50%</span>
                                                                            </div>
                                                                            <div :class="[ field.width === '100%' ? 'c-field-width-toggle__active' : 'grey--text', 'text-center u-cursor-pointer', {'c-field-disabled': field.parent && !field.parent.is_active} ]"
                                                                                    @click="local_check_section_status(section) && field.parent.is_active ? local_change_field_width(index, field, '100%') : {}"
                                                                            >
                                                                                <span class="md-body-2 font-weight-medium px-1">100%</span>
                                                                            </div>
                                                                        </a-flex>
                                                                    </template>
                                                                    <template v-if="field.parent && field.parent.type === 'multiline'">
                                                                        <span>Multiline field does not support 50% width</span>
                                                                    </template>
                                                                    <template v-if="field.parent_type === 'SystemTagSection' || field.parent_type === 'CustomTagSection'">
                                                                        <span>Tags field does not support 50% width</span>
                                                                    </template>
                                                                </a-tooltip>
                                                                <a-spacer></a-spacer>
                                                                <a-flex shrink>
                                                                    <a-tooltip v-if="current_remove_field === field.id" bottom nudge-right="50" :disabled="!local_check_section_status(section)">
                                                                        <template v-slot:activator="{on}">
                                                                            <a-icon v-on="on" size="20" color="red darken-1" @click="local_check_section_status(section) ?  local_remove_field(index, field) : {}">do_not_disturb_on</a-icon>
                                                                        </template>
                                                                        <span>Confirm remove?</span>
                                                                    </a-tooltip>
                                                                    <a-tooltip v-else bottom nudge-right="50" :disabled="!local_check_section_status(section)">
                                                                        <template v-slot:activator="{on}">
                                                                            <a-icon v-on="on" size="20" color="grey" @click="local_check_section_status(section) ? local_select_remove_field(index, field) : {}">do_not_disturb_on</a-icon>
                                                                        </template>
                                                                        <span>Remove from layout</span>
                                                                    </a-tooltip>
                                                                </a-flex>
                                                            </a-layout>
                                                        </div>
                                                    </div>

                                                    <!-- Add field hover UI -->
                                                    <template v-if="!hide_add_field && field.id !== 'dummy_id' && section.is_active">
                                                        <div class="u-absolute"
                                                            v-if="field && field.insertable"
                                                            :class="[field.width === '50%' ? field.solo_field ? 'c-add-field-solo' : 'c-add-field-sm' : 'c-add-field-lg', 'u-wfull u-cursor-pointer']"
                                                            @mouseover="local_display_hover_element(field, fieldIndex, index)"
                                                        >
                                                        </div>
                                                    </template>
                                                    <div v-if="(field.id === 'dummy_id')">
                                                        <div
                                                            style="top: -8px; height: 16px"
                                                            :class="[field.width === '50%' ? ($vuetify.breakpoint.lgAndDown ? 'c-add-field-sm': 'c-add-field-lg')  : 'u-wfull']"
                                                            class="align-center u-flex u-absolute"

                                                        >
                                                            <div class="c-insert-field-divider c-insert-field-divider__left"></div>
                                                            <a-menu
                                                                v-model="dropdown[field.id]"
                                                                max-width="436"
                                                                min-width="436"
                                                                nudge-bottom="12"
                                                                nudge-left="218"
                                                                absolute
                                                                :close-on-content-click="false"
                                                                :disabled="!local_check_section_status(section)"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <a-icon v-on="on" size="18" color="indigo darken-1" class="u-cursor-pointer">add_circle</a-icon>
                                                                </template>
                                                                <div class="pa-5 grey lighten-5" style="border: 1px solid red;">
                                                                    <a-text-field
                                                                        v-model="local_field_search"
                                                                        class="c-field-search u-rounded-corners white u-relative"
                                                                        solo flat autofocus
                                                                        prepend-inner-icon="search"
                                                                        hide-details
                                                                        placeholder="Search fields & tags..."
                                                                        @input="local_search_fields()"
                                                                    >
                                                                    </a-text-field>
                                                                </div>
                                                                <a-sheet class="pa-0 u-overflow-y c-tiny-scroll" style="maxHeight: 250px;">
                                                                    <template v-if="local_filtered_field_list && local_filtered_field_list.length">
                                                                        <div v-for="option in local_filtered_field_list" :key="option.id">
                                                                            <v-hover v-slot="{ hover }">
                                                                                <div :class="[ hover ? 'indigo lighten-5' : '','ma-2 px-3 py-2 rounded u-flex justify-space-between align-center u-cursor-pointer']" @click="local_add_field('insert', option, section, fieldIndex)">
                                                                                    <div class="">
                                                                                        <div class="grey--text text--darken-2 mb-2">{{ option.label | truncateText(40) }}</div>
                                                                                        <div class="u-flex align-center">
                                                                                            <a-icon size="20" color="grey">{{ local_get_custom_field_type_item(option) }}</a-icon>
                                                                                            <span v-if="local_check_field_type(option) === 'tag'" class="text-capitalize md-subtitle-2 pl-2 grey--text mr-3">tag</span>
                                                                                            <span v-else class="text-capitalize md-subtitle-2 pl-2 grey--text mr-3">{{option.type}}</span>
                                                                                            <template v-if="option && !option.is_active">
                                                                                                <a-icon size="20" color="amber darken-3">block</a-icon>
                                                                                                <span class="amber--text text--darken-3 ml-1">Disabled</span>
                                                                                            </template>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-if="hover" class="u-flex align-center">
                                                                                        <a-icon size="20" color="indigo darken-1">add_circle</a-icon>
                                                                                    </div>
                                                                                </div>
                                                                            </v-hover>
                                                                            <a-divider></a-divider>
                                                                        </div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="u-flex align-center pa-5 justify-center">
                                                                            <div class="grey--text text--darken-2 md-subtitle-2 text-center">
                                                                                <template v-if="local_no_results">No fields found!</template>
                                                                                <template v-else>All fields have been added!</template>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </a-sheet>
                                                            </a-menu>
                                                            <div class="c-insert-field-divider c-insert-field-divider__right"></div>
                                                        </div>
                                                    </div>
                                                </a-card>
                                            </transition-group>
                                        </draggable>
                                        <div class="d-flex justify-center">
                                            <a-menu
                                                v-model="dropdown[section.id]"
                                                max-width="436"
                                                min-width="436"
                                                nudge-bottom="24"
                                                nudge-left="218"
                                                absolute
                                                :disabled="!local_check_section_status(section)"
                                                :close-on-content-click="false"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <div class="u-wfull" style="border: 1px dashed #BDBDBD; height: 48px">
                                                        <button
                                                            v-on="on"
                                                            class="rounded c-field-menu text-capitalize u-wfull u-flex align-center justify-center"
                                                            style="height: 100%"
                                                            @mouseover="local_remove_hover_element_on_select()"
                                                            @click="local_set_available_fields()"
                                                        >
                                                            <div class="d-flex justify-center align-center">
                                                                <a-icon size="16" color="indigo darken-2">add</a-icon>
                                                                <span class="md-subtitle-2 indigo--text text--darken-2 font-weight-medium">Add Field</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </template>
                                                <div class="pa-5 grey lighten-5 u-wfull" style="border: 1px solid red;">
                                                    <a-text-field
                                                        v-model="local_field_search"
                                                        class="c-field-search u-rounded-corners white"
                                                        solo flat autofocus
                                                        prepend-inner-icon="search"
                                                        hide-details
                                                        placeholder="Search fields & tags..."
                                                        @input="local_search_fields()"
                                                    >
                                                    </a-text-field>
                                                </div>
                                                <a-sheet class="pa-0 u-overflow-y c-tiny-scroll" style="maxHeight: 250px;">
                                                    <template v-if="local_filtered_field_list && local_filtered_field_list.length">
                                                        <div v-for="option in local_filtered_field_list" :key="option.id">
                                                            <v-hover v-slot="{ hover }">
                                                                <div :class="[ hover ? 'indigo lighten-5' : '','ma-2 px-3 py-2 rounded u-flex justify-space-between align-center u-cursor-pointer']" @click="local_add_field('append', option, section)">
                                                                    <div class="">
                                                                        <div class="grey--text text--darken-2 mb-2">{{ option.label | truncateText(40) }}</div>
                                                                        <div class="u-flex align-center">
                                                                            <a-icon size="20" color="grey">{{ local_get_custom_field_type_item(option) }}</a-icon>
                                                                            <span v-if="local_check_field_type(option) === 'tag'" class="text-capitalize md-subtitle-2 pl-2 grey--text mr-3">tag</span>
                                                                            <span v-else class="text-capitalize md-subtitle-2 pl-2 grey--text mr-3">{{option.type}}</span>
                                                                            <template v-if="option && !option.is_active">
                                                                                <a-icon size="20" color="amber darken-3">block</a-icon>
                                                                                <span class="amber--text text--darken-3 ml-1">Disabled</span>
                                                                            </template>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="hover" class="u-flex align-center">
                                                                        <a-icon size="20" color="indigo darken-1">add_circle</a-icon>
                                                                    </div>
                                                                </div>
                                                            </v-hover>
                                                            <a-divider></a-divider>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="u-flex align-center pa-5 justify-center">
                                                            <div class="grey--text text--darken-2 md-subtitle-2 text-center">
                                                                <template v-if="local_no_results">No fields found!</template>
                                                                <template v-else>All fields have been added!</template>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </a-sheet>
                                            </a-menu>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!section.is_active" class="c-section-tooltip">
                                    <span>Section is disabled</span>
                                </div>
                                <!-- Section add UI -->
                                <template v-if="(index !== local_layout_design_list.length - 1)">
                                    <div class="c-add-section u-relative u-absolute u-wfull" style="height: 32px">
                                        <div class="c-add-section__bar u-absolute u-wfull u-flex align-center" style="top: 7px">
                                            <div class="c-insert-field-divider c-insert-field-divider__left"></div>
                                            <a-icon size="18" @click="local_create_section(index)" color="indigo darken-1" class="u-cursor-pointer">add_circle</a-icon>
                                            <div class="c-insert-field-divider c-insert-field-divider__right"></div>
                                        </div>
                                    </div>
                                </template>
                            </a-card>
                        </transition-group>
                    </draggable>
                    <a-card elevation="0" height="46">
                        <button
                            class="rounded c-field-menu text-capitalize u-wfull u-flex align-center justify-center"
                            style="height: 100%"
                            @click="local_create_section()"
                        >
                            <a-icon size="16" color="indigo darken-2">add</a-icon>
                            <span class="md-subtitle-2 indigo--text text--darken-2 font-weight-medium">Add Section</span>
                        </button>
                    </a-card>
                </div>

                <!-- Preview page -->
                <div v-if="preview_mode" class="mx-2 mt-4">
                    <a-card class="blue-grey pa-4 u-shadow" flat>
                        <div class="u-flex">
                            <a-icon size="18" color="white">info</a-icon>
                            <span class="white--text md-subtitle-2 ml-2 font-weight-bold">You are viewing a preview of the fields & tags section (excluding empty sections).</span>
                        </div>
                    </a-card>
                    <a-layout align-center class="my-4">
                        <a-flex class="u-flex align-center">
                            <div class="md-heading-6 indigo--text text--darken-1 font-weight-medium mr-3">{{ moduleType === 'project_fields_and_tags' ? 'Fields & Tags' : 'Fields' }}</div>
                        </a-flex>
                        <a-spacer></a-spacer>
                        <a-flex shrink>
                            <a-btn depressed small height="32" @click="preview_mode=false" class="grey darken-4 white--text">Exit Preview</a-btn>
                        </a-flex>
                    </a-layout>
                    <template v-if="!local_valid_sections">
                        <div class="py-8 px-6 u-flex flex-column align-center text-center">
                            <div class="rounded-circle grey lighten-2 u-flex justify-center mb-4" style="width: 80px; height: 80px">
                                <a-icon size="48" color="grey lighten-1">sell</a-icon>
                            </div>
                            <div class="grey--text text--darken-3 mb-3 text-uppercase" style="font-weight: 600">No fields <span v-if="moduleType !== 'ticket_custom_fields'">or tags</span> are added</div>
                            <p class="grey--text text--darken-1 mb-4 font-weight-medium mx-auto" style="max-width: 464px">You can configure existing fields <span v-if="moduleType !== 'ticket_custom_fields'">and tags</span>  using Layout Designer.</p>
                            <div class="u-flex justify-center font-weight-medium">
                                <a-btn depressed color="grey darken-4">
                                    <a-icon size="18" color="white" class="pr-1">login</a-icon>
                                    <span class="white--text">Go To Layout Designer</span>
                                </a-btn>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div v-for="(section, index) in local_preview_design_list" :key="section.id">
                            <a-card
                                flat
                                color="white"
                                min-height="177"
                                class="mb-8 u-shadow u-wfull u-rounded-corners c-section-card"
                                v-if="section.is_active && (section.fields && section.fields.length) && local_check_valid_fields(section)"
                            >
                                <div>
                                    <div class="pa-6">
                                        <a-layout align-center>
                                            <a-flex class="u-flex align-center">
                                                <div class="u-wfull">
                                                    <h2 class="c-module-title md-subtitle-1 indigo--text text--darken-1 font-weight-medium" :title="section.name">
                                                        {{ section.name | truncateText(local_is_lgdown ? 40 : 120) }}
                                                    </h2>
                                                </div>
                                            </a-flex>
                                        </a-layout>
                                    </div>
                                    <a-divider v-if="local_show_divider(section)"></a-divider>
                                    <a-sheet v-if="section.fields && !section.fields.length">
                                        <div class="text-center">
                                            <div class="py-8 px-6 text-center">
                                                <div class="grey--text text--darken-3 mb-3 text-uppercase" style="font-weight: 600">No fields <span v-if="moduleType !== 'ticket_custom_fields'">or tags</span> are added</div>
                                                <p class="grey--text text--darken-1 mb-4 font-weight-medium mx-auto" style="max-width: 464px">You can configure existing fields <span v-if="moduleType !== 'ticket_custom_fields'">and tags</span>  using Layout Designer.</p>
                                                <div class="u-flex justify-center font-weight-medium">
                                                    <div class="mx-4 indigo--text text--darken-1" :to="{ name: 'layout-designer'}">
                                                        <span>Go To Layout Designer</span>
                                                        <a-icon size="18" color="indigo darken-1" class="pl-1">arrow_forward</a-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a-sheet>
                                    <a-sheet v-else class="u-wfull c-section-container">
                                        <div v-for="(field, fieldIndex) in section.fields" :key="field.id" :class="[field.width === '50%' ? 'c-field-card__half--preview' : 'c-field-card__full']">
                                            <a-card
                                                flat
                                                class="u-wfull mb-5"
                                            >
                                                <a-divider v-if="local_show_item_divider(section, field, fieldIndex)"></a-divider>
                                                <div class="px-6 py-2 pt-5 u-wfull">
                                                    <div v-if="field && field.parent_type === 'CustomField'" class="u-flex align-start px-2 pb-3">
                                                        <a-icon
                                                            size="20"
                                                            color="grey"
                                                            style="marginTop: 2px"
                                                        >
                                                            {{ local_get_custom_field_type_item(field) }}
                                                        </a-icon>
                                                        <span class="px-3 md-body-1 grey--text text--darken-3 font-weight-medium">
                                                            <template v-if="field.parent_type === 'CustomField'">{{ field.parent.label }}</template>
                                                            <template v-else>
                                                                {{ field.parent_type === 'SystemTagSection' ? field.parent.value : field.parent.name}}
                                                            </template>
                                                        </span>
                                                        <a-tooltip right v-if="field.parent.description">
                                                            <template v-slot:activator="{ on }">
                                                                <a-icon v-on="on" size="16" color="grey" class="u-cursor-pointer mt-1">info</a-icon>
                                                            </template>
                                                            <span class="py-3">{{ field.parent.description }}</span>
                                                        </a-tooltip>
                                                    </div>
                                                    <div class="u-wfull">
                                                        <template v-if="field && field.parent.type === 'text'">
                                                            <template v-if="field">
                                                                <a-tooltip bottom offset-y :disabled="field.parent.user_writable === 1">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div v-on="on" >
                                                                            <a-text-field
                                                                                v-model="field.value"
                                                                                :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                color="grey darken-1"
                                                                                background-color="grey lighten-4"
                                                                                class="u-rounded-corners"
                                                                                :class="[!field.parent.user_writable ? 'u-border-dashed-px' : 'u-border', 'u-wfull']"
                                                                                :disabled="field.parent.user_writable === 0"
                                                                                solo flat hide-details
                                                                            ></a-text-field>
                                                                        </div>
                                                                    </template>
                                                                    <span class="py-3">{{ field.parent.user_writable ? '' : 'This is a read-only field.'}}</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </template>
                                                        <template v-if="field && field.parent.type === 'multiline'">
                                                            <template v-if="field">
                                                                <a-tooltip bottom offset-y :disabled="field.parent.user_writable === 1">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div style="overflow-y: auto; max-height: 15rem;" class="grey lighten-4 rounded" v-on="on">
                                                                            <a-textarea
                                                                                v-model="field.value"
                                                                                :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                color="grey darken-1"
                                                                                background-color="grey lighten-4"
                                                                                class="u-rounded-corners"
                                                                                :class="[!field.parent.user_writable ? 'u-border-dashed-px' : 'u-border', 'u-wfull']"
                                                                                :disabled="field.parent.user_writable === 0"
                                                                                solo flat hide-details no-resize auto-grow multi-line
                                                                                rows="3"
                                                                            >
                                                                            </a-textarea>
                                                                        </div>
                                                                    </template>
                                                                    <span class="py-3">{{ field.parent.user_writable ? '' : 'This is a read-only field.'}}</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </template>
                                                        <template v-if="field && field.parent.type === 'mask'">
                                                            <template v-if="field">
                                                                <a-tooltip bottom offset-y :disabled="field.parent.user_writable === 1">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div v-on="on" style="max-height: 14rem;">
                                                                            <a-text-field
                                                                                v-model="field.value"
                                                                                :placeholder="field.parent.placeholder ? field.parent.placeholder : 'Enter value'"
                                                                                color="grey darken-1"
                                                                                background-color="grey lighten-4"
                                                                                :class="[!field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                :disabled="field.parent.user_writable === 0"
                                                                                solo flat hide-details
                                                                                :append-icon="field.parent.user_writable === 1 ? 'drive_file_rename_outline' : ''"
                                                                                readonly
                                                                                @click:append="localSelectCustomFieldItem(field)"
                                                                            ></a-text-field>
                                                                        </div>
                                                                    </template>
                                                                    <span class="py-3">{{ field.parent.user_writable ? '' : 'This is a read-only field.'}}</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </template>
                                                        <template v-if="field && field.parent.type === 'dropdown'">
                                                            <template v-if="field">
                                                                <a-tooltip bottom offset-y :disabled="field.parent.user_writable === 1">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div class="u-wfull" v-on="on">
                                                                            <a-autocomplete
                                                                                    v-model="field.value"
                                                                                    :items="field.parent.options"
                                                                                    item-text="label"
                                                                                    item-value="value"
                                                                                    :placeholder="field.parent.user_writable === 1 ? (field.parent.placeholder ? field.parent.placeholder : 'Select a value') : ''"
                                                                                    class="u-rounded-corners"
                                                                                    :class="[!field.parent.user_writable ? 'u-border-dashed-px' : 'u-border', 'u-wfull']"
                                                                                    color="grey darken-1"
                                                                                    background-color="grey lighten-4"
                                                                                    :key="field.id"
                                                                                    :append-icon="field.parent.user_writable === 1 ? 'arrow_drop_down' : null"
                                                                                    :disabled="field.parent.user_writable === 0"
                                                                                    solo flat hide-details
                                                                                >
                                                                                <template v-slot:no-data>
                                                                                    <div class="px-4 py-2">
                                                                                        <span class="md-body-1 grey--text">No Data Found</span>
                                                                                    </div>
                                                                                </template>
                                                                                <template v-slot:selection="{ item }">
                                                                                    <span v-if="item === Object(item)" class="md-body-3 d-block py-1 u-rounded-corners text-truncate" style="max-width: 630px" :title="item.label">{{ item.label }} </span>
                                                                                </template>
                                                                                <template v-slot:item="{ item }">
                                                                                    <a-layout align-center class="py-0">
                                                                                        <a-flex style="max-width: 630px">
                                                                                            <h5 class="md-subtitle-1 text-truncate" :title="item.label">{{ item.label }}</h5>
                                                                                        </a-flex>
                                                                                    </a-layout>
                                                                                </template>
                                                                            </a-autocomplete>
                                                                        </div>
                                                                    </template>
                                                                    <span class="py-3">This is a read-only field</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </template>
                                                        <template v-if="field && field.parent.type === 'date'">
                                                            <template v-if="field">
                                                                <a-tooltip bottom offset-y :disabled="field.parent.user_writable === 1">
                                                                    <template v-slot:activator="{ on }">
                                                                        <div v-on="on">
                                                                            <!-- <a-menu
                                                                                v-model="datepickerMenu[field.id]"
                                                                                :nudge-right="40"
                                                                                transition="scale-transition"
                                                                                :close-on-content-click="false"
                                                                                min-width="250px"
                                                                                offset-y
                                                                            >
                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                    <a-text-field
                                                                                        v-model="datepickerDateValue[field.id]"
                                                                                        placeholder="Select Date"
                                                                                        background-color="neutral"
                                                                                        :class="[!field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                        :disabled="field.parent.user_writable === 0"
                                                                                        @click:clear="local_clear_date(field, index)"
                                                                                        solo flat hide-details readonly clearable
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                    >
                                                                                    </a-text-field>
                                                                                </template>
                                                                                <a-date-picker
                                                                                    v-model="field.value"
                                                                                    @input="local_update_date(field, index, field.value)"
                                                                                    no-title scrollable
                                                                                >
                                                                                </a-date-picker>
                                                                                <vc-date-picker
                                                                                    v-model="field.value"
                                                                                    class="d-block u-wfull"
                                                                                    @input="local_update_date(field, index, field.value)"
                                                                                    :model-config="modelConfig"
                                                                                ></vc-date-picker>
                                                                            </a-menu> -->
                                                                            <vc-date-picker
                                                                                v-model="field.value"
                                                                                class="d-block u-wfull"
                                                                                :model-config="modelConfig"
                                                                                @input="local_update_date(field, index, field.value)"
                                                                            >
                                                                                <template v-slot="{ togglePopover }">
                                                                                    <div @click="field.parent.user_writable === 0 ? '' : togglePopover({ placement: 'bottom-start' })">
                                                                                        <a-text-field
                                                                                            v-model="field.value"
                                                                                            placeholder="Select Date"
                                                                                            background-color="neutral"
                                                                                            :class="[!field.parent.user_writable ? 'u-border-dashed-px' : 'u-border']"
                                                                                            :disabled="field.parent.user_writable === 0"
                                                                                            @click:clear="local_clear_date(field, index)"
                                                                                            solo flat hide-details readonly clearable
                                                                                            v-on="on"
                                                                                        >
                                                                                        </a-text-field>
                                                                                    </div>
                                                                                </template>
                                                                            </vc-date-picker>
                                                                        </div>
                                                                    </template>
                                                                    <span class="py-3">{{ field.parent.user_writable ? '' : 'This is a read-only field.'}}</span>
                                                                </a-tooltip>
                                                            </template>
                                                        </template>
                                                        <template v-if="field && field.parent_type !== 'CustomField'">
                                                            <a-sheet class="u-rounded-corners u-flex align-start pt-3" :key="field.parent_id">
                                                                <a-sheet min-width="184" max-width="184" class="u-flex align-center pt-3">
                                                                    <a-icon size="20" color="grey lighten-1" class="mr-3 ml-2">sell</a-icon>
                                                                    <h2 v-if="field.parent_type === 'SystemTagSection'" class="md-subtitle-1 font-weight-medium grey--text text--darken-3 mb-0" :title="field.parent.value">
                                                                        {{ field.parent.value | truncateText(12) }}
                                                                    </h2>
                                                                    <h2 v-else class="md-subtitle-1 font-weight-medium grey--text text--darken-3 mb-0" :title="field.parent.name">
                                                                        {{ field.parent.name | truncateText(12) }}
                                                                    </h2>
                                                                </a-sheet>
                                                                <div
                                                                    :id="local_get_tag_slug(field) + '-tags'"
                                                                    class="u-flex align-center flex-wrap ml-4 u-wfull"
                                                                    :class="[
                                                                        tagSearchType === local_get_tag_slug(field) ? 'u-border-d u-rounded-corners-lg': 'u-border-transparent',
                                                                        localGetSelectedTags(local_get_tag_slug(field), null, 'length') ? 'pl-3 pt-3' : 'pa-3',
                                                                        {'u-border-transparent': tagRemoveType === local_get_tag_slug(field)}
                                                                    ]"
                                                                >
                                                                    <template v-for="(tag, tagIndex) in localGetSelectedTags(local_get_tag_slug(field))">
                                                                        <g-tags
                                                                            :tag="tag"
                                                                            :class="[
                                                                                { 'c-ring-blue': currentSelectionIndex === tagIndex && tagSearchType === local_get_tag_slug(field) },
                                                                                { 'c-ring-red u-opacity-70': tagConfirmRemoveIndex === tagIndex && tagSearchType === local_get_tag_slug(field) }
                                                                            ]"
                                                                            :canUpdate="true"
                                                                            @destroy="localOnTagRemove(tag)"
                                                                            :key="tag.id"
                                                                            tile
                                                                        ></g-tags>
                                                                    </template>
                                                                    <template>
                                                                        <div v-if="tagSearchType !== (local_get_tag_slug(field))" style="flex-grow: 1; height: 24px;" :class="['d-inline-flex align-center u-cursor-pointer', { 'mb-3': localGetSelectedTags(local_get_tag_slug(field), 'custom', 'length') }]" @click.stop="localShowTagSearchInput(local_get_tag_slug(field))">
                                                                            <a-icon color="grey" size="16" class="mr-1">add</a-icon>
                                                                            <a-sheet class="md-body-2 grey--text" min-width="240">
                                                                                <template v-if="localGetSelectedTags(local_get_tag_slug(field), 'custom', 'length')">
                                                                                    Select Existing Tag
                                                                                </template>
                                                                                <template v-else>
                                                                                    <template>Select Existing Tag</template>
                                                                                </template>
                                                                            </a-sheet>
                                                                        </div>
                                                                        <STagsCombobox
                                                                            v-if="tagSearchType === local_get_tag_slug(field)"
                                                                            :initialLoading="mixinIsLoading('tag-search-' + local_get_tag_slug(field))"
                                                                            :tagType="local_get_tag_slug(field)"
                                                                            :id="local_get_tag_slug(field) + 'TagSearchInput'"
                                                                            :tagList="localFilteredTags"
                                                                            :tagSearchType="tagSearchType"
                                                                            :tagsSelected="localGetSelectedTags(local_get_tag_slug(field), 'custom')"
                                                                            :tagsTotalCount="tag_types_count[tagSearchType]"
                                                                            :tagsCountLimit="tagsCountLimit"
                                                                            :canCreate="false"
                                                                            @reset="localResetTagIndices"
                                                                            @backspace="localOnBackspace"
                                                                            @escape="localOnEscape"
                                                                            @openDropdown="localShowTagSearchInput(local_get_tag_slug(field))"
                                                                            @search="localSearchTags($event, 'custom')"
                                                                            @select="localAttachTags({ ...$event, mode: local_get_tag_slug(field)})"
                                                                        ></STagsCombobox>
                                                                    </template>
                                                                </div>
                                                                <a-spacer></a-spacer>
                                                                <slot name="visibility">
                                                                    <s-tag-confirmation
                                                                        :tag-type="field.parent.status"
                                                                        :disabled="true"
                                                                        :is-public="localHasVisibility('key', field) === 'external-ready-only'"
                                                                        :org-visibility="localHasOrgVisibility('key', field)"
                                                                        type="organizationTag"
                                                                        class="d-inline-block mr-7"
                                                                        dense
                                                                    ></s-tag-confirmation>
                                                                </slot>
                                                            </a-sheet>
                                                        </template>
                                                    </div>
                                                </div>
                                            </a-card>
                                        </div>
                                    </a-sheet>
                                </div>
                            </a-card>
                        </div>
                    </template>
                </div>
            </a-responsive>
        </a-container>

        <a-dialog v-model="dialogMaskUpdateForm" persistent max-width="500">
            <SUpdateMaskedFieldPopup
                :item="selectCustomFieldItem"
                :is-modal-open="dialogMaskUpdateForm"
                @action="(mask_value) => localUpdateMaskCustomField(mask_value)"
                @close="localUpdateMaskCustomFieldClose()"
            />
        </a-dialog>

        <!--Cancel change alert-->
        <a-dialog v-model="dialog_cancel_changes" persistent max-width="400">
            <SCancelChanges
                @success="local_cancel_changes()"
                @close="dialog_cancel_changes = false"
                class="pa-6"
            />
        </a-dialog>

        <!--Leaving page without save alert -->
        <a-dialog v-model="dialog_leave_page_alert" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_page()"
                @close="dialog_leave_page_alert = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>

        <a-dialog v-model="dialog_leave_module_alert" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_module()"
                @close="local_cancel_module_change()"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>
    </a-main>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import LoaderTemplate from '../../components/Loader/LoaderTemplate.vue'
import { STagsCombobox, STagConfirmation } from '@/config/config-shared-components'
import SCancelChanges from '/src/components/Shared/SharedCancelChanges'
import { Validate } from '@/helpers/helper-validations'
import SUpdateMaskedFieldPopup from '/src/components/Shared/SharedUpdateMaskedFieldPopup'
import mixinLoading from '@/mixins/mixin-module-loading-setup'

export default {

    components: { LoaderTemplate, STagConfirmation, STagsCombobox, SCancelChanges, SUpdateMaskedFieldPopup },

    mixins: [
        mixinLoading,
    ],

    data () {
        return {
            //Arrays
            custom_field_sections: [
                { id: 1, label: 'Projects', type: 'Project', icon: 'work' },
                { id: 2, label: 'Tickets', type: 'Ticket', icon: 'account_tree' },
            ],
            custom_field_types: [
                { type: 'text', icon: 'text_fields' },
                { type: 'multiline', icon: 'subject' },
                { type: 'dropdown', icon: 'arrow_drop_down_circle' },
                { type: 'mask', icon: 'password' },
                { type: 'date', icon: 'calendar_month' },
                { type: 'tags', icon: 'sell' },
                { type: 'url', icon: 'link' },
                { type: 'number', icon: 'pin' },
            ],
            section_more_options: [
                { label: 'Rename', value: 'rename'},
                { label: 'Move up', value:'moveUp' },
                { label:'Move down', value:'moveDown' },
            ],
            local_layout_design_list: [],
            backup_list: [],
            datepickerMenu: [],
            local_fields_list: [],
            local_preview_design_list: [],
            section_modified: [],
            field_modified: [],
            tag_fields: [],
            loadedTagModules: [],
            currentTagList: [],
            removedTags: [],
            type_list: [],
            local_configure_list: [
                {label: 'Custom Fields', value: 'custom_field'},
                {label: 'Tags', value: 'tags'},
            ],
            local_filtered_field_list: [],
            dropdown: [],
            invalid_fields: [],

            // Objects
            datepickerDateValue: {},
            maskedValue: {},
            response_title: {},
            selectCustomFieldItem: {},
            selectedTags: {},
            maskedFieldItem: {},
            loadedTagsList: {},
            current_edit_section: {},

            //Boolean
            hide_add_field: false,
            page_loading: true,
            local_loading: false,
            preview_mode: false,
            dialog_leave_page_alert: false,
            dialog_cancel_changes: false,
            sections_modified: false,
            unsaved_changes: false,
            dialog_delete_section: false,
            delete_process: false,
            dialogMaskUpdateForm: false,
            dialog_leave_module_alert: false,
            local_no_results: false,
            onDrag: false,
            local_valid_sections: false,

            //Variables
            local_field_search: null,
            untitled_section: 1,
            current_remove_field: null,
            currentEditId: null,
            current_field: null,
            current_section: null,
            remove_field_timer: null,
            tagSearchType: null,
            moduleType: 'project_fields_and_tags',
            to_page_route: null,
            active_tab: 'tab-1',
            current_remove_section: null,
            to_module: null,
            field_hover_timer: null,
            section_hover_timer: null,
            currentSelectionType: null,
            waitTimeBeforeReset: null,
            tagRemoveType: null,
            tagRemoveTimeout: null,
            tagRemoveTimeout: null,
            currentTagToRemove: null,
            tagsCountLimit: 150,
            currentSelectionIndex: null,
            tagConfirmRemoveIndex: null,
            field_counter: 0,
            modelConfig: { type: 'string', mask: 'YYYY-MM-DD' },
        }
    },

    mounted () {
        this.local_index()
        window.addEventListener('beforeunload', this.local_handler_close)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.local_handler_close)
    },

    beforeRouteLeave (toPage, fromPage, next) {
        if (!this.unsaved_changes) return next()
        next(false)
        this.dialog_leave_page_alert = true
        this.to_page_route = next
    },

    computed: {
        local_is_lgdown () {
            return this.$vuetify.breakpoint.lgAndDown
        },

        local_get_available_fields () {
            let fieldParentIds = []
            const fieldList = [...this.custom_fields_list ]
            this.local_layout_design_list.forEach(section => {
                section.fields.forEach(field => {
                    if (field && field.parent) fieldParentIds.push(field.parent.id)
                })
            })
            const availFields = fieldList.filter(field => fieldParentIds.indexOf(field.id) === -1)
            return availFields
        },

        local_get_available_tags () {
            let mappedTags = []
            let tagParentIds = []

            this.tag_section_list.forEach(item => {
                const customTagField = { id: item.id, label: item.name, name: item.name, type: 'CustomTagSection', is_active: item.is_active , parent: item }
                mappedTags.push(customTagField)
            })

            this.type_list.forEach(item => {
                const systemTagField = { id: item.id, label: item.value, name: item.value, type: 'SystemTagSection', is_active: 1 , parent: item}
                mappedTags.push(systemTagField)
            })

            this.tag_fields = _.cloneDeep(mappedTags)
            this.local_layout_design_list.forEach(section => {
                section.fields.forEach(field => {
                    if (field && field.parent) tagParentIds.push(field.parent.id)
                })
            })
            const availableTags = mappedTags.filter(field => tagParentIds.indexOf(field.id) === -1)
            return availableTags
        },

        localFilteredTags () {
            return this.currentTagList.filter(item => {
                const index = this.selectedTags[this.tagSearchType].findIndex(i => i.id === item.id)
                return index !== -1 ? null : item
            })
        },

        dragOptions() {
            return {
                animation: 200,
                group: "field",
                disabled: false,
                ghostClass: "c-ghost",
                dropzone: '.list-dropzone',
                dropzoneOverClass: 'non-droppable'
            }
        },

        ...mapState('CustomField', {
            custom_fields_list: 'list'
        }),

        ...mapState('LayoutDesigner', {
            layout_design_list: 'list',
            layout_design_response: 'response'
        }),

        ...mapState('TagSection', {
            tag_section_list: 'list'
        }),

        ...mapState('Tag', {
            tag_list: 'list',
            tag_types_count: 'types_count',
            tag_response: 'response'
        }),

        ...mapState('Meta', {
            meta_list: 'list',
            meta_filters: 'filters',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapGetters('Visibility', {
            visibility_get_visibility_item: 'get_list_item',
        }),
    },

    methods: {
        async local_index () {
            await this.layout_design_index({'include': 'fields.parent', 'filter[type]': this.moduleType})
            this.local_layout_design_list = _.cloneDeep(this.layout_design_list)
            this.backup_list = _.cloneDeep(this.local_layout_design_list)
            this.local_load_custom_fields('Project')
            this.localFetchVisibility()
            await this.tag_section_index()
            await this.meta_index({ 'filter[type]': 'system_tag' })
            this.type_list = _.cloneDeep(this.meta_list)
            this.local_set_available_fields()
            this.localSetProjectTags()
            this.local_set_insertable_fields()
            this.page_loading = false
        },

        async localFetchVisibility () {
            this.visibility_index({
                'include': 'projectVisibility',
                'filter[resource_type]': 'Organization',
                'project_id': this.$route.params.id,
                'fields[visibilities]': 'id,key,visibility',
            })
        },

        local_set_insertable_fields () {
            this.field_counter = 0
            let sectionIndex = 0
            this.local_layout_design_list.forEach((section, index) => {
                if (index !== sectionIndex) this.field_counter = 0
                sectionIndex = index
                section.fields.forEach((item, fieldIndex) => {
                    item.insertable = false
                    item.solo_field = false
                    if (item.width === '50%') {
                        ++this.field_counter
                        if(this.field_counter % 2 !== 0 && (fieldIndex+1 < section.fields.length) && (section.fields[fieldIndex+1].width === '100%')) {
                            item.insertable = true
                            item.solo_field = true
                            this.field_counter = 0
                        } else if (this.field_counter % 2 === 0) {
                            item.insertable = true
                            item.solo_field = false
                            this.field_counter = 0
                        }
                    }
                    if (item.width === '100%') {
                        item.insertable = true
                        item.solo_field = false
                        this.field_counter = 0
                    }
                })
            })
        },

        async local_load_custom_fields (moduleItem) {
            await this.custom_field_index({
                'fields[custom_fields]': 'id,name,label,is_active,order,resource_type,placeholder,type,options,user_writable,description',
                'filter[resource_type]': moduleItem
            })
        },

        local_set_custom_field_value () {
            this.local_preview_design_list.forEach(item => {
                item.fields.forEach(item => {
                    Object.assign(item, {value: ''})
                })
            })
        },

        local_get_custom_field_type_item(field) {
            const isTagSection = field && this.local_check_field_type(field) === 'tag' || field.parent_type === 'SystemTagSection' || field.parent_type === 'CustomTagSection'
            const type = isTagSection ? 'tags' : (field.parent && field.parent.type) ? field.parent.type : field.type
            const custom_field_item = this.custom_field_types.find(item => item.type === type)
            if (custom_field_item) return custom_field_item.icon
        },

        async local_change_layout_module (moduleItem) {
            this.to_module = moduleItem
            if (this.unsaved_changes) return this.dialog_leave_module_alert = true
            this.local_loading = true
            this.active_tab = moduleItem === 'Project' ? 'tab-1' : 'tab-2'
            this.moduleType = moduleItem === 'Project' ? 'project_fields_and_tags' : 'ticket_custom_fields'

            await this.layout_design_index({'include': 'fields.parent', 'filter[type]': this.moduleType})
            await this.local_load_custom_fields(moduleItem)
            this.local_layout_design_list = _.cloneDeep(this.layout_design_list)
            this.backup_list = _.cloneDeep(this.local_layout_design_list)
            this.local_set_insertable_fields()
            this.local_set_available_fields()
            this.dropdown = []
            this.local_loading = false
        },

        local_create_section (index) {
            const section = { name: 'Untitled section ' + this.untitled_section++, is_active: 1,  fields: [], type: this.moduleType }
            this.$appendId(section)
            if (index === 0 || index) this.local_layout_design_list.splice(index + 1, 0, section)
            else this.local_layout_design_list.push(section)
            this.local_trigger_sections_modified()
        },

        local_enable_add_field (item, target) {
            setTimeout(() => {
                this.current_field = item.id
            }, 200);
        },

        local_disable_add_field (item) {
            this.current_field = null
        },

        local_select_remove_field (index, field) {
            this.current_remove_field = field.id
            clearTimeout(this.remove_field_timer)
            this.remove_field_timer = setTimeout(() => {
                this.current_remove_field = null
            }, 2000);
        },

        local_remove_field (sectionIndex, field) {
            const sectionFields = this.local_layout_design_list[sectionIndex].fields
            const index = sectionFields.findIndex(item => item.id === field.id)
            sectionFields.splice(index, 1)
            this.local_set_available_fields()
            this.current_remove_field = null
            this.local_trigger_sections_modified()
            this.local_set_insertable_fields()
        },

        local_change_field_width(sectionIndex, field, width) {
            const sectionFields = this.local_layout_design_list[sectionIndex].fields
            const index = sectionFields.findIndex(item => item.id === field.id)
            sectionFields[index].width = width
            this.local_trigger_sections_modified()
            this.local_set_insertable_fields()
        },

        local_modify_section (section, section_index, action) {
            if (action === 'deleteFields') this.local_layout_design_list[section_index].sublist = []
            if (action === 'rename') this.local_title_edit_focus(section)
            if (action === 'moveUp' && section_index > 0) {
                this.local_layout_design_list.splice(section_index - 1, 0, section)
                this.local_layout_design_list.splice(section_index + 1, 1)
            }
            if (action === 'moveDown' && section_index < this.local_layout_design_list.length-1) {
                this.local_layout_design_list.splice(section_index + 2, 0, section)
                this.local_layout_design_list.splice(section_index , 1)
            }
            this.local_trigger_sections_modified()
        },

        local_select_delete_section (section) {
            this.current_remove_section = section.id
            setTimeout(() => {
                this.current_remove_section = null
            }, 3000);
        },

        local_delete_section () {
            const index = this.local_layout_design_list.findIndex(item => item.id === this.current_remove_section)
            this.local_layout_design_list.splice(index, 1)
            this.local_trigger_sections_modified()
            this.local_set_available_fields()
            this.current_remove_section = null
        },

        local_change_section_display_status (section) {
            const index = this.local_layout_design_list.findIndex(item => item.id === section.id)
            this.local_layout_design_list[index].is_active = section.is_active ? 1 : 0
            this.local_trigger_sections_modified()
        },

        local_check_section_status (section) {
            return section.is_active
        },

        local_check_full_width_field(field) {
            return (field.parent.type === 'multiline' || field.parent_type !== 'CustomField')
        },

        local_focus_title_input(section, type) {
            this.local_validate_section_title(section)
            if (this.response_title && this.response_title.id) {
                this.local_revert_section_title(section)
                this.$notify('error', this.response_title.text)
            }
            setTimeout(() => {
                this.response_title = {}
                this.currentEditId = null
                this.current_edit_section = {}
            }, 100);
            this.local_trigger_sections_modified()
        },

        local_title_edit_focus (section) {
            this.current_edit_section = _.cloneDeep(section)
            if (!section.is_active) return
            setTimeout(() => {
                this.local_on_enter('title_input' + section.id)
                this.currentEditId = section.id
            }, 100);
        },

        local_on_enter (input) {
            setTimeout(() => {
                this.$refs[input][0].focus()
            }, 200)
        },

        local_inline_update (section, type) {
            if (type === 'clear') this.local_revert_section_title(section)
            this.local_focus_title_input(section, 'blur')
        },

        local_validate_section_title (section) {
            const { message } = new Validate(section.name, { silent: true }).length(1, 255).run()
            if (message) this.response_title = { id: section.id, text: message, status: 'error'}
            else this.response_title = {}
        },

        local_revert_section_title(section) {
            const backup_index = this.backup_list.findIndex(item => item.id === section.id)
            const index = this.local_layout_design_list.findIndex(item => item.id === section.id)
            this.local_layout_design_list[index].name = backup_index !== -1 ? this.backup_list[backup_index].name : this.current_edit_section.name
            this.local_trigger_sections_modified()
        },

        local_format_date(date) {
            return date ? moment(date).format('MMM D, YYYY') : null
        },

        local_update_date (field, index, value) {
            const fieldIndex = this.local_layout_design_list[index].fields.findIndex(item => item.id === field.id)
            this.local_layout_design_list[index].fields[fieldIndex].value = value
            this.datepickerDateValue[field.id] = this.local_format_date(value)
            this.datepickerMenu[field.id] = false
        },

        local_clear_date (field, index) {
            const fieldIndex = this.local_layout_design_list[index].fields.findIndex(item => item.id === field.id)
            this.local_layout_design_list[index].fields[fieldIndex].value = null
            this.datepickerDateValue[field.id] = null
        },

        localUpdateMaskCustomField(value) {
            const maskValue = '*'.repeat(value.length)
            const sectionIndex = this.local_preview_design_list.findIndex(section => section.id === this.maskedFieldItem.layout_designer_section_id)
            if (sectionIndex === -1 ) return
            const fieldIndex = this.local_preview_design_list[sectionIndex].fields.findIndex(item => item.id === this.maskedFieldItem.id)
            Object.assign(this.local_preview_design_list[sectionIndex].fields[fieldIndex], {value: maskValue})
            this.dialogMaskUpdateForm = false
        },

        local_set_available_fields () {
            this.local_field_search = ''
            this.local_fields_list = this.moduleType === 'project_fields_and_tags' ? [...this.local_get_available_fields, ...this.local_get_available_tags] : [...this.local_get_available_fields]
            this.local_filtered_field_list = this.local_fields_list
        },

        local_search_fields () {
            const searchResult = this.local_fields_list.filter(field => {
                return field.label.toLowerCase().startsWith(this.local_field_search.toLowerCase())
            })

            this.local_filtered_field_list = searchResult
            this.local_no_results = (this.local_field_search && searchResult && !searchResult.length)
            if (!this.local_field_search) this.local_set_available_fields()
        },

        local_add_field(action, field, section, fieldIndex = null) {
            const sectionIndex = this.local_layout_design_list.findIndex(item => item.id === section.id)
            const isTagField = field.type === 'SystemTagSection' || field.type === 'CustomTagSection'

            this.dropdown[section.id] = false
            this.local_remove_hover_element(null, fieldIndex+1, sectionIndex)
            this.dropdown['dummy_id'] = false

            const newField = {
                type: this.moduleType,
                parent_type: isTagField ? field.type : 'CustomField',
                parent: isTagField ? field.parent : field,
                parent_id: field.id,
                width: isTagField || field.type === 'multiline' ? '100%' : '50%'
            }
            this.$appendId(newField)

            if (action === 'append') this.local_layout_design_list[sectionIndex].fields.push(newField)
            else this.local_layout_design_list[sectionIndex].fields.splice(fieldIndex, 0, newField)

            this.local_field_search = ''
            this.local_set_available_fields()
            this.local_trigger_sections_modified()
            this.local_set_insertable_fields()
        },

        local_handler_close (e) {
            if (this.unsaved_changes) {
                e.returnValue = 'Are you sure to close the tab?';
            }
        },

        async local_store_layout () {
            this.local_remove_hover_element_on_select()
            await this.layout_design_store({
                type: this.moduleType,
                sections: [...this.local_layout_design_list]
            })

            if (this.layout_design_response && this.layout_design_response.status === 'success') {
                this.$notify('success', 'Layout saved successfully!')
                this.backup_list = _.cloneDeep(this.local_layout_design_list)
                this.local_reset_variables()
            }
        },

        local_reset_variables () {
            this.unsaved_changes = false
            this.response_title = {}
            this.field_modified = []
            this.section_modified = []
            this.untitled_section = 1
            this.current_edit_section = {}
        },

        async local_switch_preview_mode () {
            this.local_remove_hover_element_on_select()
            this.local_preview_design_list = _.cloneDeep(this.local_layout_design_list)
            this.local_filter_inactive_fields()
            this.local_remove_inactive_fields()
            this.local_set_custom_field_value()
            this.local_check_valid_sections()
            this.dropdown = []
            this.preview_mode = true
        },

        local_check_active_section () {
            return this.local_preview_design_list.filter(section => section.is_active).length
        },

        local_filter_inactive_fields() {
            this.invalid_fields = []
            this.local_preview_design_list.forEach((section, sectionIndex) => {
                section.fields.forEach((field, index) => {
                    if (field.parent && !field.parent.is_active) this.invalid_fields.push(field.id)
                })
            })
        },

        local_remove_inactive_fields () {
            this.local_preview_design_list.forEach(section => {
                section.fields = section.fields.filter(field => {
                    const match = this.invalid_fields.find(item => item === field.id)
                    return !match
                })
            })
        },

        local_trigger_sections_modified () {
            this.onDrag = false
            if ((this.backup_list && this.backup_list.length) !== (this.local_layout_design_list && this.local_layout_design_list.length)) return this.unsaved_changes = true

            this.local_check_sections_modified()
            this.local_check_fields_modified()
            this.unsaved_changes = (this.section_modified && this.section_modified.length) || (this.field_modified && this.field_modified.length)
        },

        local_check_sections_modified () {
            this.section_modified = this.local_layout_design_list.filter((section, sectionIndex) => {
                if (this.backup_list.length === this.local_layout_design_list.length) {
                    return (this.backup_list[sectionIndex].id !== this.local_layout_design_list[sectionIndex].id) || (this.backup_list[sectionIndex].name !== this.local_layout_design_list[sectionIndex].name) || (!!this.backup_list[sectionIndex].is_active !== !!this.local_layout_design_list[sectionIndex].is_active) || (this.backup_list[sectionIndex].fields.length !== this.local_layout_design_list[sectionIndex].fields.length)
                }
            })
        },

        local_check_fields_modified () {
            this.field_modified = []
            const current_list = _.cloneDeep(this.local_layout_design_list)
            current_list.forEach((section, sectionIndex) => {
                if (this.backup_list[sectionIndex].fields.length === (current_list[sectionIndex].fields.length)) {
                    current_list[sectionIndex].fields.forEach((field, index) => {
                        current_list[sectionIndex].fields[index].modified = ((this.backup_list[sectionIndex].fields[index].id !== current_list[sectionIndex].fields[index].id) || (this.backup_list[sectionIndex].fields[index].width !== current_list[sectionIndex].fields[index].width))
                    })
                }
            })

            current_list.forEach((section, sectionIndex) => {
                current_list[sectionIndex].fields.forEach((field, index) => {
                    if (current_list[sectionIndex].fields[index].modified) this.field_modified.push(field)
                })
            })
        },

        local_cancel_changes () {
            this.local_layout_design_list = _.cloneDeep(this.backup_list)
            this.unsaved_changes = false
            this.dialog_cancel_changes = false
            this.local_reset_variables()
            this.local_set_available_fields()
            this.local_set_insertable_fields()
        },

        local_leave_page () {
            this.unsaved_changes = false
            this.to_page_route()
        },

        local_leave_module () {
            this.unsaved_changes = false
            this.dialog_leave_module_alert = false
            this.local_change_layout_module(this.to_module)
        },

        local_cancel_module_change () {
            this.active_tab = this.to_module === 'Project' ? 'tab-2' : 'tab-1'
            this.dialog_leave_module_alert = false
        },

        async localSelectCustomFieldItem(field) {
            this.selectCustomFieldItem = {}
            this.maskedFieldItem = field
            this.selectCustomFieldItem = field.parent
            this.dialogMaskUpdateForm = true
        },

        localUpdateMaskCustomFieldClose () {
            this.dialogMaskUpdateForm = false
        },

        local_display_hover_element (field, index, sectionIndex) {
            this.local_remove_hover_element_on_select()
            this.local_set_available_fields()
            if (index === this.local_layout_design_list[sectionIndex].fields.length-1) return
            clearTimeout(this.field_hover_timer)
            this.field_hover_timer = setTimeout(() => {
                this.hide_add_field = true
                this.local_layout_design_list[sectionIndex].fields.splice(index+1, 0, {id: 'dummy_id', name: 'field_adder', width: '100%'})
            }, 600);
        },

        local_remove_hover_element (field, index, sectionIndex) {
            clearTimeout(this.field_hover_timer)
            this.dropdown['dummy_id'] = false
            this.dropdown = []
            const dummy_index = this.local_layout_design_list[sectionIndex].fields.findIndex(field => field.id === 'dummy_id')
            if (dummy_index === index || dummy_index === -1) return
            this.local_layout_design_list[sectionIndex].fields.splice(dummy_index, 1)
            this.hide_add_field = false
        },

        local_remove_hover_element_on_select () {
            this.local_layout_design_list.forEach(section => {
                section.fields.forEach((field, index) => {
                    if (field.id === 'dummy_id') section.fields.splice(index, 1)
                })
            })
        },

        async localShowTagSearchInput (type) {
            this.mixinSetLoading('tag-search-' + type)
            this.localResetTagIndices()
            this.tagSearchType = type
            if (this.localIsTagModuleLoaded(type)) {
                this.currentTagList = _.cloneDeep(this.loadedTagsList[type])
                return this.mixinResetLoading('tag-search-' + type)
            }

            this.currentTagList = []
            await this.tag_index({
                sort: 'order',
                'filter[type]': this.tagSearchType,
                'fields[tags]': 'id,label,color,type'
            })
            this.currentTagList = this.tag_list
            this.loadedTagsList[type] = _.cloneDeep(this.tag_list)
            this.localSetTagModuleLoad(type)
            this.mixinResetLoading('tag-search-' + type)
        },

        localSetTagModuleLoad (type) {
            const index = this.loadedTagModules.indexOf(type)
            if (index === -1) this.loadedTagModules.push(type)
        },

        localSetProjectTags () {
            this.tag_section_list.forEach(section => {
                this.selectedTags[section.slug] = []
            })

            this.type_list.forEach(section => {
                this.selectedTags[section.status] = []
            })
        },

        localUnsetTagModuleLoad (type) {
            const index = this.loadedTagModules.indexOf(type)
            if (index !== -1) this.loadedTagModules.splice(index, 1)
        },

        localIsTagModuleLoaded (type) {
            const index = this.loadedTagModules.indexOf(type)
            return index !== -1
        },

        localGetSelectedTags (type, mode = null, field = null) {
            let result = []
            result = this.selectedTags[type] || []
            return field === 'length' ? result.length : result
        },

        async localAttachTags (params) {
            const { tag, showSuccess, mode = null } = params || {}
            this.localResetTagIndices()
            const type = mode
            this.selectedTags[type].push(tag)
            showSuccess()
            this.localAddTagsToLoadedMods(tag)
        },

        localAddTagsToLoadedMods (tag) {
            if (this.loadedTagsList[tag.type]) {
                const index = this.loadedTagsList[tag.type].findIndex(i => i.id === tag.id)
                if (index === -1) this.loadedTagsList[tag.type].push(_.cloneDeep(tag))
            }
            else this.loadedTagsList[tag.type] = _.cloneDeep([tag])

            this.currentTagList = [...this.loadedTagsList[tag.type]]
        },

        localOnTagRemove (tag) {
            const tagToRemove = _.cloneDeep(tag)
            this.tagRemoveType = tag.type
            const tagType = tag.type

            const index = this.selectedTags[tagType].findIndex(i => i.id === tag.id)
            if (index === -1) return

            this.localSetRemovedTags(tagType, index)
            this.localResetTagIndices()

            const searchInputEl = document.getElementById(`refTagSearchInput${tagToRemove.type}`)
            if (searchInputEl) searchInputEl.focus()

            this.localAddRemovedTagToList(tagToRemove)
        },

        localAddRemovedTagToList (tagToRemove, noFocus = false) {
            this.localAddTagsToLoadedMods(tagToRemove)
        },

        localSetRemovedTags (tagType, index) {
            const removedTag = this.selectedTags[tagType].splice(index, 1)
            this.removedTags = [...this.removedTags, ...removedTag]
            this.tagRemoveType = null
        },

        localOnEscape (evt) {
            if (evt.code !== 'Escape') return
            if (evt.shiftKey || evt.ctrlKey) return

            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null
            if (this.currentSelectionIndex !== null) this.currentSelectionIndex = null

            this.tagSearchType = null
        },

        localOnBackspace (evt) {
            if (!['Backspace', 'Delete'].includes(evt.code)) return
            if (evt.shiftKey || evt.ctrlKey) return

            if (this.currentSelectionIndex === null) this.localTraverseLeft()
            if (this.tagConfirmRemoveIndex !== null) {
                this.tagRemoveType = this.tagSearchType
                return this.localTagRemove()
            }
            if (this.currentSelectionIndex !== null) return this.localGetTagRemoveConfirm()
        },

        localTraverseLeft () {
            if (this.tagConfirmRemoveIndex) this.tagConfirmRemoveIndex = null

            const currentTypeTags = this.localGetSelectedTags(this.tagSearchType)
            this.currentTagToRemove = currentTypeTags.slice(-1)[0]

            if (this.currentSelectionIndex === null) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex > 0) return this.currentSelectionIndex--
            if (this.currentSelectionIndex === 0 && currentTypeTags.length === 1) return this.currentSelectionIndex = currentTypeTags.length - 1
            if (this.currentSelectionIndex === 0) return this.currentSelectionIndex = currentTypeTags.length - 1
        },

        localGetTagRemoveConfirm () {
            if (this.tagRemoveTimeout) clearTimeout(this.tagRemoveTimeout)

            this.tagConfirmRemoveIndex = this.currentSelectionIndex
            this.tagRemoveTimeout = setTimeout(() => this.tagConfirmRemoveIndex = null, 2000)
        },

        localResetTagIndices () {
            this.currentSelectionIndex = null
            this.tagConfirmRemoveIndex = null
        },

        localTagRemove (type) {
            type = type || this.tagRemoveType
            this.localModifyRemovedTags(type)
        },

        async localModifyRemovedTags (type, isCustomTag) {
            const selectType = type
            let removedTag = null
            removedTag = this.selectedTags[selectType].splice(this.tagConfirmRemoveIndex, 1)
            const searchInputEl = document.getElementById(`refTagSearchInput${type}`)
            if (searchInputEl) searchInputEl.focus()
            this.localAddRemovedTagToList(removedTag[0])


            this.removedTags = [...this.removedTags, ...removedTag]
            this.tagConfirmRemoveIndex = null
            this.currentSelectionIndex = null
            this.currentTagToRemove = {}

        },

        local_check_field_type (field) {
            if (field && field.type === 'SystemTagSection' || field.type === 'CustomTagSection') return 'tag'
            return 'custom_field'
        },

        local_get_tag_slug (tagSection) {
            if (tagSection.parent && tagSection.parent.slug) return tagSection.parent.slug
            return tagSection.parent.status
        },

        local_fields_reorder () {
            this.local_trigger_sections_modified()
            this.local_set_insertable_fields()
        },

        local_direct_to_configure (moduleItem) {
            if (moduleItem === 'tags') {
                const tagRoute = this.$router.resolve({ name: 'settings-tags-list', params: { id: 'label' }, target: '_blank' })
                window.open(tagRoute.href, '_blank')
                return
            }
            const route = this.$router.resolve({ name: 'settings-custom-fields-list', params: { id: this.moduleType === 'ticket_custom_fields' ? 'ticket' : 'project' }, target: '_blank' })
            window.open(route.href, '_blank')
        },

        async localSearchTags ({ type, value, clearLoading, showError }) {
            this.localResetTagIndices()
            if (!this.tag_types_count[type] || (this.tag_types_count[type] <= this.tagsCountLimit)) {
                this.currentTagList = [...this.loadedTagsList[type]]
                return clearLoading()
            }

            await this.tag_index({
                count: this.tagsCountLimit,
                sort: 'order',
                'filter[type]': this.tagSearchType,
                'filter[label_filter]': (value && value.toLowerCase()) || '',
                'fields[tags]': 'id,label,color,type',
            })
            this.currentTagList = [...this.tag_list]
            clearLoading()
        },

        localHasVisibility (field, value) {
            const slug = value.parent_type === 'CustomTagSection' ? this.local_get_tag_slug(value) : `project_${this.local_get_tag_slug(value)}`
            const data = this.visibility_get_visibility_item(field, slug)
            if(!data) return
            return data && data.project_visibility ? data.project_visibility.visibility : data.visibility
        },

        localHasOrgVisibility (field, value) {
            const slug = value.parent_type === 'CustomTagSection' ? this.local_get_tag_slug(value) : `project_${this.local_get_tag_slug(value)}`
            const data = this.visibility_get_visibility_item(field, slug)
            if(!data) return
            return data.visibility
        },

        local_check_empty_sections (sectionList) {
            const empty_sections = sectionList.filter(section => {
                return (section.fields && !section.fields.length) && section.is_active
            })
            return empty_sections && empty_sections.length
        },

        local_check_valid_sections () {
            const valid_sections = this.local_preview_design_list.filter(section => {
                return section.is_active && (section.fields && section.fields.length) && !!this.local_check_valid_fields(section)
            })

            this.local_valid_sections = (valid_sections && valid_sections.length) ? true : false
        },

        local_check_valid_fields (section) {
            const valid_fields = section.fields.filter(field => field.parent && !!field.parent.is_active)
            return !!(valid_fields && valid_fields.length)
        },

        local_show_divider (section) {
            if (section.fields.length === 1 && section.fields[0].width === '50%') return true
            if (section.fields.length > 1 && section.fields[0].width === '50%' && section.fields[1].width !== '50%') return true
        },

        local_show_item_divider (section, field, index) {
            if (index === 0 && section.fields.length === 1 && field.width === '50%') return false
            if (index === 0 && section.fields.length > 1 && field.width === '50%' && section.fields[1].width !== '50%') return false
            return !field.solo_field
        },

        ...mapActions('CustomField', {
            custom_field_index: 'index',
        }),

        ...mapActions('LayoutDesigner', {
            layout_design_index: 'index',
            layout_design_store: 'store'
        }),

        ...mapActions('TagSection', {
            tag_section_index: 'index',
            tag_section_clear: 'clear',
            tag_section_clear_item: 'clear_item',
        }),

        ...mapActions('Tag', {
            tag_index: 'index',
            tag_store: 'store',
            tag_fetch_types_count: 'fetch_types_count',
            tag_clear: 'clear',
            tag_clear_item: 'clear_item',
        }),

        ...mapActions('Meta', {
            meta_index: 'index',
            meta_clear: 'clear',
        }),
        ...mapActions('Visibility', {
            visibility_index: 'index',
        }),

    }
}
</script>

<style scoped>
.c-field-width-toggle {
    border: 1px solid #EEEEEE !important;
    background-color: white;

}
.c-field-width-toggle__active {
    border: 0px solid transparent !important;
    background-color: #E8EAF6;
    color: #3949AB;
    border-radius: 4px;
}
.c-section-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.c-section-disabled {
    opacity: 0.5;
}
.c-field-disabled {
    opacity: 0.3;
}
.c-section-card:hover .c-section-tooltip {
    display: flex
}
.c-field-card:hover .c-field-tooltip {
    display: flex
}

.c-section-tooltip {
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    display: none;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 32px;
    top: calc(50% - 16px);
    left: calc(50% - 80px);
    background-color: #222222;
    border-radius: 4px;
    color: white;
    z-index: 20;
    opacity: 1;
}

.c-field-tooltip {
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    display: none;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 32px;
    top: calc(50% - 16px);
    left: calc(50% - 80px);
    background-color: #222222;
    border-radius: 4px;
    color: white;
    z-index: 30;
    opacity: 1;
}

.c-field-card {
    width: 100%;
    background-color: #FAFAFA !important;
    border: 1px solid #EEEEEE !important;
}
.c-field-card__half {
    width: 49% !important;
}
.c-field-card__half--preview {
    width: 50% !important;
}
.c-field-card__full{
    width: 100% !important;
}
.c-add-field-sm {
    top: 100px;
    height: 16px;
    left: -294px
}
.c-add-field-lg {
    top: 100px;
    height: 16px;
}
.c-add-field-solo {
    top: 100px;
    height: 16px;
    left: 320px
}
.c-save-layout-btn.v-btn.v-btn--disabled {
    opacity: 0.5;
    background-color: #222222 !important;
    color: #fff !important;
}
.c-border-error {
    border: 2px solid #DF0024 !important;
}
.c-ghost {
    background: #c8ebfb;
    opacity: 0.5;
}
.c-add-section__bar {
    visibility: hidden !important;
    z-index: 0;
}

.c-add-section:hover .c-add-section__bar {
    visibility: visible !important;
}

</style>

const api_endpoint = '/integrations'

export default {
    namespaced: true,

    state: {
        list: {},
        response: [],
        defaults: {
            list: {},
            response: [],
        },
    },

    mutations: {
        UPDATE_LIST (state, items) {
            state.list = items;
        },

        THROW_RESPONSE (state, payload) {
            state.response = payload;
        },
    },
    actions: {
        async statuses ({ commit, dispatch }, payload) {
            const params = payload && payload.params ? payload.params : {}

            this.dispatch('Interface/loader_start')
            try {
                const { data: { data } } = await axios.get(api_endpoint + '/status', { params })
                commit('UPDATE_LIST', data)
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' })
            }
        },

        async revoke_access(context, payload = {}) {
            this.dispatch('Interface/loader_start')
            try {
                await axios.delete(api_endpoint + '/revoke-access', { params: { ...payload } })
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: 'Done', status: 'success' })
            } catch (error) {
                this.dispatch('Interface/loader_stop')
                context.commit('THROW_RESPONSE', { text: error.response.data.message, status: 'error' });
            }
        },
    },
}

<template>
    <div>
        <a-tooltip bottom v-if="automation_workflow_hook_loading">
            <template v-slot:activator="{ on }">
                <div v-on="on" class="u-flex-center">
                    <SAutomationPreHook/>
                </div>
            </template>
            <span class="px-5 py-3">Automation is running...</span>
        </a-tooltip>
        <template v-if="hookStatus && !automation_workflow_hook_loading">
            <div class="justify-center u-flex c-automation-result" v-if="hookStatus === 'success'">
                <a-icon size="18" color="teal accent-4" class="mr-2">check_circle</a-icon>
                <p class="u-font-12 mb-0 u-font-weight-semibold teal--text text--darken-2">Validation Passed</p>
            </div>
            <div class="justify-center u-flex c-automation-result" v-if="hookStatus === 'error'">
                <a-icon size="18" color="amber darken-1" class="mr-2">info</a-icon>
                <p class="u-font-12 mb-0 u-font-weight-semibold amber--text text--darken-4">Validation Failed</p>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SAutomationPreHook } from '@/config/config-shared-components'

export default {
    inheritAttrs: false,
    components: {
        SAutomationPreHook
    },

    data() {
        return {
            hookStatus: null
        }
    },

    computed: {
        ...mapState('AutomationWorkflow', {
            automation_workflow_hook_response: 'hook_response',
            automation_workflow_hook_loading: 'loader',
        }),

        ...mapState('User', {
            user_self: 'self',
        }),
    },

    watch: {
        automation_workflow_hook_response (newValue) {
            if (newValue.status) {
                if (newValue.count === 0) {
                    this.hookStatus = null
                    return
                }
                this.hookStatus = newValue.status
                setTimeout(() => {
                    this.hookStatus = null
                }, 5000)
            }
        }
    },

    methods: {
        ...mapActions('AutomationWorkflow', {
            automation_workflow_hook_clear: 'hook_clear'
        }),
    },
}
</script>

<style>
.c-automation-icon {
    width: 48px;
    height: 48px;
    border-radius: 50% !important;
}
.c-automation-result {
    border-radius: 24px !important;
}
</style>

<template>
	<div v-if="is_loading" style="z-index: 1000; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #fff;">
		<div class="u-wfull u-hfull u-absolute text-center transparent u-opacity-40">
			<a-img :src="require('../../../assets/images/loader.svg')" :alt="img_alt" width="48" height="48" contain class="d-inline-block mt-16"></a-img>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		is_loading: {
			type: Boolean,
			default: true
		},
        img_alt: {
            type: String,
            default: 'Loading..'
        }
	}
}
</script>

<template>
    <div>
        <div @click.stop="localHardFocusInput()"
            :style="[{ cursor: disabled ? 'default' : 'text' }, { pointerEvents: disabled ? 'none' : 'auto' }, { gap: '8px' }]"
            :class="[localFilteredSelectedList.length ? 'px-3 py-2' : 'py-3' ]"
            class="u-relative u-flex-center-y align-stretch flex-wrap u-border-light grey lighten-4 u-rounded-corners white"
        >
            <template v-if="multiple">
                <div v-for="item in localFilteredSelectedList" class="d-inline-flex flex-wrap align-center" :key="item.id" style="flex: 0 1 auto;">
                    <slot name="selection" :item="item">
                        <div :class="['d-inline-flex align-center', selectionClass]">
                            {{ item.name }}
                            <v-hover v-slot="{ hover: hoverIcon }" v-if="removable && !disabled">
                                <slot name="removeIcon" :selectedItem="item" :hovered="hoverIcon" :remove="localRemoveItem">
                                    <a-icon
                                        size="14"
                                        @click.stop="localRemoveItem(item)"
                                        class="ml-2 u-rounded-corners"
                                        :class="[hoverIcon ? 'grey darken-4 white--text': 'grey--text text--darken-3']"
                                    >
                                        clear
                                    </a-icon>
                                </slot>
                            </v-hover>
                        </div>
                    </slot>
                </div>
            </template>

            <div
                v-if="!localFilteredSelectedList.length || (localFilteredSelectedList.length && !disabled)"
                :id="attachId"
                class="d-inline-flex align-center u-relative"
                style="flex: 1 0 250px"
                :class="[{ 'px-3': !localFilteredSelectedList.length }]"
            >
                <span v-if="disabled" class="u-wfull d-block md-subtitle-1 grey--text text--darken-1" style="pointerEvents: none;">{{ placeholder }}</span>
                <input
                    v-else
                    type="text"
                    v-model="modelSearchText"
                    @keydown="localInputKeyDown"
                    @click.stop="localOpenDropdown()"
                    ref="refInputField"
                    :placeholder="localFilteredSelectedList.length ? '' : placeholder"
                    class="u-wfull md-subtitle-1 grey--text text--darken-3"
                    autocomplete="off"
                    style="outline: none; border: none;"
                >
            </div>

            <!-- Total Clear -->
            <!--<div class="d-inline-flex align-center pr-3 u-cursor-pointer" v-if="localFilteredSelectedList.length" @click="localTotalClear()">
                <a-icon size="22" color="grey darken-1">backspace</a-icon>
            </div>-->
        </div>

        <!-- Dropdown list -->
        <a-menu v-model="modelDropdown" :attach="`#${attachId}`" offset-y bottom :close-on-content-clic="false" nudge-bottom="32" min-width="100%" content-class="u-shadow-md" transition="slide-y-transition">
            <ul class="c-custom-dropdown white u-overflow-y u-wfull py-2 c-tiny-scroll" style="maxHeight: 300px;">
                <li v-if="searchLoading" class="px-3 py-2 u-flex-center md-body-2 grey--text text--darken-2">
                    Loading...
                </li>
                <li v-else-if="localFilteredItems && !localFilteredItems.length" class="px-3 py-2 u-flex-center-y font-weight-medium md-body-2 grey--text text--darken-2">
                    No Data Found
                </li>
                <template v-else>
                    <template v-for="(item, index) in localFilteredItems">
                        <v-hover v-slot="{ hover }" :key="item.id + '-hover'">
                            <li
                                :key="item.id"
                                class="px-3 py-2 text-truncate u-cursor-pointer md-body-2 grey--text text--darken-2"
                                :class="[{ 'grey lighten-4': currentKeyIndex === index || hover }]"
                                :title="item.name"
                                @click.stop="localSelectItem(item)"
                            >
                                <slot name="listItem" :item="item">
                                    {{ item.name }}
                                </slot>
                            </li>
                        </v-hover>
                    </template>
                </template>
            </ul>
        </a-menu>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => []
        },
        selectedItems: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        chips: {
            type: Boolean,
            default: false
        },
        removable: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        attachId: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Select Value'
        },
    },

    watch: {
        selectedItems: {
            handler (val) {
                this.selectedList = _.cloneDeep(val)
            },
            deep: true,
            immediate: true
        },

        modelSearchText (val) {
            this.modelDropdown = !!_.trim(val)
            this.localSearch(val)
        },

        modelDropdown: {
            handler (val, oldVal) {
                if (!val) this.currentKeyIndex = null
                if (val) this.selectedList = _.cloneDeep(this.selectedItems)
            },
            immediate: true
        },
    },

    data () {
        return {
            modelDropdown: false,
            currentKeyIndex: 0,
            currentSelectionIndex: null,
            searchLoading: false,
            modelSearchText: '',
            classes: {
                chips: 'px-2 py-1 u-rounded-corners grey lighten-2',
                default: ''
            },
            selectedList: []
        }
    },

    mounted () {
        this.localIndex()
        document.addEventListener('keydown', this.localGlobalKeyDown)
        this.$el.addEventListener('keydown', this.localKeyDown)
    },

    beforeDestroy () {
        document.removeEventListener('keydown', this.localGlobalKeyDown)
        this.$el.removeEventListener('keydown', this.localKeyDown)
    },

    computed: {
        selectionClass () {
            if (this.chips) return this.classes['chips']
            return this.classes['default']
        },

        localFilteredSelectedList () {
            if (!this.multiple) return this.selectedList.length ? this.selectedList.slice(0, 1) : []
            return this.selectedList
        },

        localFilteredItems () {
            return this.items.filter(data => {
                const index = this.selectedList.findIndex(item => item.id === data.id)
                return index === -1 ? data : null
            })
        },
    },

     methods: {
         async localIndex () {
            if (!this.multiple) this.modelSearchText = this.selectedList.length ? this.selectedList[0].name : ''
         },

         localSearch (value) {
             this.searchLoading = true
             this.$emit('search', { value, clearLoading: this.localClearSearchLoading })
         },

         localClearSearchLoading () {
             this.searchLoading = false
         },

         localSelectItem (item) {
             this.$emit('add', item)
             this.modelSearchText = ''
             this.currentKeyIndex = 0
             this.localFocusInput()
             this.modelDropdown = false
         },

         localOpenDropdown () {
            // if (this.modelDropdown) return // Triggered when input field is open

             this.searchLoading = true
             if (_.trim(this.modelSearchText)) {
                if (this.modelDropdown) return this.localFocusInput()
                this.modelDropdown = true
                this.currentKeyIndex = 0
                return this.localSearch(this.modelSearchText)
            }

            if (this.modelDropdown) {
                this.currentKeyIndex = 0
                this.localSearch(this.modelSearchText)
                return this.localFocusInput()
            }

            this.modelDropdown = true
            this.currentKeyIndex = 0
            this.localFocusInput()
            this.localSearch(this.modelSearchText)
         },

         localInputKeyDown (evt) {
            if (!this.multiple) return

            const { code } = evt || {}
            if (code === 'Backspace') {
                if (_.trim(this.modelSearchText)) return
                this.selectedList.splice(-1)
                this.$emit('remove', this.selectedList)
            }
        },

        localRemoveItem (item) {
            const index = this.selectedList.findIndex(i => i.id === item.id)
            this.selectedList.splice(index, 1)
            this.$emit('remove', this.selectedList)
        },

        /* Keyboard events */
        localGlobalKeyDown (evt) {
            this.localOnEscape(evt)
        },

        localOnEscape (evt) {
            if (evt.code !== 'Escape') return

            this.localFocusInput()
            this.modelDropdown = false
            this.currentKeyIndex = null
        },

        localKeyDown (evt) {
            this.localOnArrowDown(evt)
            this.localOnArrowUp(evt)
            this.localOnEnter(evt)
        },

        localOnArrowDown (evt) {
            if (evt.code !== 'ArrowDown') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown || this.searchLoading) return

            evt.preventDefault()
            if (this.currentKeyIndex === this.items.length - 1) this.currentKeyIndex = 0
            else this.currentKeyIndex = this.currentKeyIndex + 1

            this.localFocusCurrentItem(evt, 'down')
            this.localFocusInput()
        },

        localOnArrowUp (evt) {
            if (evt.code !== 'ArrowUp') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown || this.searchLoading) return

            evt.preventDefault()
            if (this.currentKeyIndex === 0) this.currentKeyIndex = this.items.length - 1
            else this.currentKeyIndex = this.currentKeyIndex - 1

            this.localFocusCurrentItem(evt, 'up')
            this.localFocusInput()
        },

        async localOnEnter (evt) {
            if (evt.code !== 'Enter') return
            if (evt.shiftKey || evt.ctrlKey) return
            if (!this.modelDropdown || this.searchLoading) return

            const selectedTag = this.items[this.currentKeyIndex]
            this.localSelectItem(selectedTag)
            this.localFocusInput()
            this.modelDropdown = false
        },

        localFocusCurrentItem (evt, type = 'down') {
            const listChildren = evt.target.tagName === 'LI' ? evt.target.parentElement : evt.target.parentElement.querySelector('.c-custom-dropdown')
            if (listChildren) {
                const elArr = Array.from(listChildren.children)
                if (elArr && elArr[this.currentKeyIndex]) {
                    setTimeout(() => elArr[this.currentKeyIndex].focus(), 100)
                }
            }
        },

        localFocusInput () {
            setTimeout(() => {
                const input = this.$refs.refInputField
                if (input) input.focus()
            }, 200)
        },

        localTotalClear () {
            this.selectedList = []
            this.$emit('remove', this.selectedList)
        },

        localHardFocusInput () {
            if (this.disabled) return
            this.localOpenDropdown()
        }
    }
}
</script>

<style lang="scss" scoped></style>

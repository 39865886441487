<template>
    <div>
        <!-- User Name -->
        <div class="u-flex align-center" :class="[textCenter ? 'justify-center' : '']">
            <h5 :class="[dark ? 'white--text' : 'grey--text text--darken-2', { 'text-center': textCenter }]" class="text-subtitle-2 text-truncate" v-if="item && item.user" :style="{ 'max-width': setMaxWidth ? 500 + 'px' : 'auto' }">{{ item.user.name | truncateText(40)}}</h5>
            <h5 :class="[dark ? 'white--text' : 'grey--text text--darken-2', { 'text-center': textCenter }]" class="text-subtitle-2 text-truncate" v-else :style="{ 'max-width': setMaxWidth ? 500 + 'px' : 'auto' }">{{ (item ? item.name : 'User') | truncateText(40) }}</h5>

            <!-- Indicate user is blocked -->
            <template v-if="!hideBlock">
                <template v-if="showBlockIcon">
                    <a-icon size="12" color="red darken-1" class="ml-1" v-if="methodCheckIsActive()">not_interested</a-icon>
                </template>
                <template v-else>
                    <span class="md-caption red--text text--lighten-1 red lighten-5 px-2 ml-2" style="border-radius: 25px" v-if="!dark && methodCheckIsActive()">Blocked</span>
                    <a-icon color="red darken-1" size="12" class="ml-1" v-if="dark && methodCheckIsActive()">block</a-icon>
                </template>
            </template>
        </div>

        <!-- User Role -->
        <template v-if="!hideRole">
            <!-- Users role type -->
            <div v-if="typeUser" :class="[textCenter ? 'text-center' : '']">
                <template v-if="item.scope === 'internal'">
                    <p :class="[dark ? 'whit--text' : 'grey--text text--darken-1']" class="md-caption mb-0" v-if="item && item.user && item.user.role">{{ item.user.role.name }}</p>
                    <p :class="[dark ? 'whit--text' : 'grey--text text--darken-1']" class="md-caption mb-0" v-else>{{ item.role ? item.role.name : '' }}</p>
                </template>
                <p :class="[dark ? 'whit--text' : 'grey--text text--darken-1']" class="md-caption text-capitalize mb-0" v-else>{{ item.group ? item.group : ( item.resource_type === 'Customer') ? 'Client' : item.resource_type }}</p>
            </div>

            <!-- Users collaborator type -->
            <div v-else>
                <p :class="[dark ? 'whit--text' : 'grey--text text--darken-1']" class="md-caption mb-0" v-if="item && item.collaborator && item.collaborator.type">{{ item.collaborator.type.value }}</p>
                <p :class="[dark ? 'whit--text' : 'grey--text text--darken-1']" class="md-caption mb-0" v-if="item && item.type">{{ item.type.value }}</p>
                <p :class="[dark ? 'whit--text' : 'grey--text text--darken-1']" class="md-caption text-capitalize mb-0" v-if="item && item.scope === 'external'">{{ item.group }}</p>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        dark: {
            type: Boolean,
            default: false
        },
        textCenter: {
            type: Boolean,
            default: false
        },
        setMaxWidth: {
            type: Boolean,
            default: false
        },
        hideRole: {
            type: Boolean,
            default: false
        },
        typeUser: {
            type: Boolean,
            default: false
        },
        showBlockIcon: {
            type: Boolean,
            default: true
        },
        hideBlock: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        methodCheckIsActive () {
            return !((this.item.user && this.item.user.is_active) || (this.item.is_active))
        }
    }
}
</script>

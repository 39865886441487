<template>
    <a-sheet height="calc(100vh - 72px)" class="pb-6 pt-6" style="position: sticky; top: 72px; left: 0px">
        <!-- Title, Customer and Salesforce Icon -->
        <v-slide-x-transition hide-on-leave leave-absolute>
            <a-sheet class="px-6 pb-4" v-if="!localIsLoading('root')">
                <span class="ml-0 md-body-2 grey--text text--darken-1 font-weight-medium" v-if="project_item && project_item.customer && project_item.customer.name" :title="project_item.customer.name.length > 30 ? project_item.customer.name : ''">{{ project_item.customer.name | truncateText(30) }}</span>
                <div class="u-relative mt-1" :class="[{ 'c-project-title-edit u-cursor-pointer': $can('projects.update-only') && project_item.type !== 'demo' }]" @click="$can('projects.update-only') && project_item.type !== 'demo' ? localEditProjectTitle() : ''">
                    <a-avatar size="18" color="blue darken-2" class="c-project-title-edit--icon" v-if="$can('projects.update-only')" v-test-btn.edit>
                        <a-avatar size="16" color="white" class="">
                            <a-icon class="u-rounded-corners-full" size="12" color="grey darken-2" style="boder: 1px solid #666;">edit</a-icon>
                        </a-avatar>
                    </a-avatar>
                    <h2 class="md-heading-6 break-all grey--text text--darken-4 font-weight-medium">{{ project_item.title | truncateText(60) }}</h2>
                </div>
                <template v-if="local_check_bridge('salesforce', 'opportunity.salesforce_id') || local_check_bridge('salesforce', 'opportunity.salesforce_opportunity_id')">
                    <a :href="localSalesforceInstanceUrl + '/' + local_bridge_value('salesforce', 'opportunity.salesforce_id', 'opportunity.salesforce_opportunity_id')" target="_blank" rel="noopener" class="d-inline-flex align-center u-cursor-pointer mt-1">
                        <img :src="require('../../../assets/images/icon-salesforce.svg')" height="12" alt="Salesforce" class="mr-2">
                        <span class="md-body-2 font-weight-medium light-blue--text text--darken-2">Salesforce</span>
                        <a-icon class="ml-2" size="14" color="light-blue darken-2">launch</a-icon>
                    </a>
                </template>
                <template v-if="local_check_bridge('hubspot', 'hubspot.project.create')">
                    <a :href="localHubspotInstanceUrl+'/contacts/'+localHubspotPortalId+'/deal/'+local_bridge_value('hubspot', 'hubspot.project.create', null)" target="_blank" rel="noopener" class="d-inline-flex align-center u-cursor-pointer mt-1 orange--text text--darken-3">
                        <img :src="require('../../../assets/images/apps/hubspot/logo.svg')" height="20" alt="Hubspot" class="mr-2">
                        <span class="md-body-2 font-weight-medium" style="color: #e97558 !important;">HubSpot</span>
                        <a-icon class="ml-2" size="14" style="color: #e97558 !important;">launch</a-icon>
                    </a>
                </template>
            </a-sheet>
            <template v-else>
                <div class="px-6 pb-4" style="height: 106.5px">
                    <loader-template width="80" height="13" class="u-rounded-corners-full"></loader-template>
                    <div class="my-3">
                        <loader-template width="100%" height="16" class="u-rounded-corners-full mb-2"></loader-template>
                        <loader-template width="200" height="16" class="u-rounded-corners-full mb-2"></loader-template>
                    </div>
                    <loader-template width="60" height="13" class="u-rounded-corners-full"></loader-template>
                </div>
            </template>
        </v-slide-x-transition>

        <div class="px-6 blue darken-2 py-2" v-if="project_item && project_item.type === 'demo'">
            <h3 class="md-subtitle-1 white--text font-weight-medium u-flex-center-y">
                <a-icon color="white" size="22" class="mr-2">info</a-icon>
                This is a demo project.
            </h3>
        </div>

        <a-divider></a-divider>

        <!-- key Collaborators -->
        <v-slide-x-transition hide-on-leave leave-absolute>
            <div class="px-6 py-4 u-flex-center-y" v-if="!localIsLoading('root')">
                <div v-if="project_item.key_collaborators && project_item.key_collaborators.length">
                    <template v-if="$can('projects.update-only') && $can('collaborators.index')">
                        <router-link tag="div" class="u-flex-center-y u-cursor-pointer" :to="{ name: 'projects-collaborators' }">
                            <s-users-deck :list="project_item.key_collaborators" :avatar-size="36" :count="1"></s-users-deck>
                        </router-link>
                    </template>
                    <template v-else>
                        <div class="u-flex-center-y">
                            <s-users-deck :list="project_item.key_collaborators" :avatar-size="36" :count="1"></s-users-deck>
                        </div>
                    </template>
                </div>
                <div class="u-flex align-center" :class="{ 'u-cursor-pointer': $route.name !== 'projects-collaborators' }" v-else @click="$route.name !== 'projects-collaborators' ? $router.push({ name: 'projects-collaborators' }) : ''">
                    <a-avatar size="32" color="orange darken-3" class="mr-2">
                        <a-icon color="white" size="20">warning</a-icon>
                    </a-avatar>
                    <div>
                        <h5 class="md-subtitle-1">No Key Members</h5>
                        <p class="md-caption grey--text text--darken-1 mb-0" v-if="$can('projects.update-only') && $can('collaborators.index')">Click to assign</p>
                    </div>
                </div>
                <a-spacer></a-spacer>
                <a-tooltip bottom v-if="project_is_friendly_view" content-class="c-tooltip-pointer c-tooltip-pointer--right">
                    <template v-slot:activator="{ on }">
                        <a-btn v-on="on" icon small @click="isPresentationView = true">
                            <a-icon size="18" color="indigo darken-3">desktop_access_disabled</a-icon>
                        </a-btn>
                    </template>
                    <span>Exit Presentation Mode</span>
                </a-tooltip>
            </div>
            <template v-else>
                <a-sheet height="68" class="px-6 u-flex-center-y">
                    <loader-template width="36" height="36" class="u-rounded-corners-full"></loader-template>
                    <loader-template width="36" height="36" class="u-rounded-corners-full ml-n3"></loader-template>
                    <loader-template width="36" height="36" class="u-rounded-corners-full ml-n3"></loader-template>
                    <loader-template width="36" height="36" class="u-rounded-corners-full ml-n3"></loader-template>
                    <loader-template width="36" height="36" class="u-rounded-corners-full ml-n3"></loader-template>
                </a-sheet>
            </template>
        </v-slide-x-transition>

        <!-- Archived status -->
        <v-slide-x-transition hide-on-leave leave-absolute>
            <div v-if="!localIsLoading('root') && localProjectStatus === 'archived'">
                <a-divider></a-divider>
                <div class="px-6 py-3 u-flex-center-y">
                    <a-avatar color="grey lighten-2" size="36" class="u-flex-center">
                        <a-avatar color="grey darken-3" size="32" class="u-flex-center">
                            <a-icon size="16" color="white">archive</a-icon>
                        </a-avatar>
                    </a-avatar>
                    <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2 ml-2">This project has been archived.</h3>
                </div>
            </div>
            <div v-if="!localIsLoading('root') && localProjectStatus === 'completed'">
                <a-divider></a-divider>
                <div class="px-6 py-3 u-flex-center-y" v-if="project_item && project_item.result_id">
                    <a-avatar :color="localResultType.color + ' lighten-4'" size="36" class="u-flex-center">
                        <a-avatar :color="localResultType.color" size="32" class="u-flex-center">
                            <a-icon size="16" color="white">{{ localResultType.icon }}</a-icon>
                        </a-avatar>
                    </a-avatar>
                    <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2 ml-2 text-truncate" :title="localResultType.value">Project Result: {{ localResultType.value }}</h3>
                </div>
                <div class="px-6 py-3 u-flex-center-y" v-else>
                    <a-avatar color="orange lighten-4" size="36" class="u-flex-center">
                        <a-avatar color="orange darken-2" size="32" class="u-flex-center">
                            <a-icon size="16" color="white">info</a-icon>
                        </a-avatar>
                    </a-avatar>
                    <h3 class="md-subtitle-1 font-weight-medium grey--text text--darken-2 ml-2 text-truncate">No Result Specified</h3>
                </div>
            </div>
        </v-slide-x-transition>

        <a-divider></a-divider>

        <!-- Navigation Links -->
        <a-sheet height="calc(100vh - 384px)" class="u-overflow-y c-tiny-scroll">
            <template v-for="(link, index) in localSidebarLinks">
                <a-card v-test-link:[link.link] :key="index" flat active-class="grey lighten-4" class="c-sidebar-links u-flex-center-y u-cursor-pointer px-6 py-2" :to="{ name: link.link }">
                    <a-icon class="c-sidebar-links__icon mr-2" size="20" color="grey darken-1" outlined>{{ link.icon }}</a-icon>
                    <span class="c-sidebar-links__link md-subtile-1 grey--text text--darken-1">{{ link.text }}</span>
                </a-card>
            </template>
        </a-sheet>
        <a-divider></a-divider>

        <a-dialog v-model="dialogProjectStatic" max-width="600">
            <ModalProjectStaticFieldsUpdate
                :isOpen="dialogProjectStatic"
                :project="project_item"
                :canUpdate="$can('projects.update-only')"
                @update="localUpdateStaticFields"
                @close="dialogProjectStatic = false"
            />
        </a-dialog>

        <a-dialog v-model="isPresentationView" max-width="400">
            <ModalExitPresentationMode
                @exit="localExitFriendlyView()"
                @close="isPresentationView = false"
            />
        </a-dialog>
    </a-sheet>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import loadWorkspace from '@/mixins/mixin-workspace'
import { SWorkspaceSwitch, SUsersDeck } from '@/config/config-shared-components'
import { ModalProjectStaticFieldsUpdate, ModalExitPresentationMode } from '../Modals'

export default {
    name: 'ProjectSidebar',
    mixins: [loadWorkspace],

    components: {
        SUsersDeck,
        SWorkspaceSwitch,
        ModalProjectStaticFieldsUpdate,
        ModalExitPresentationMode,
    },


    data() {
        return {
            loadingTypes: [],
            dialogProjectStatic: false,
            isPresentationView: false,
            localSidebarLinks: []
        }
    },

    mounted() {
        this.local_index()
    },

    watch: {
        $route (to, from) {
            if (to.params.id !== from.params.id) this.local_index()
        }
    },

    computed: {
        localSidebarMenuList () {
            const menuList = [
                { icon: 'info', text: 'Overview', link: 'projects-overview', permission: !this.localUserExternal },
                { icon: 'group_work', text: 'Collaborators', link: 'projects-collaborators', permission: !this.localUserExternal && this.$can('collaborators.index') },
                { icon: 'scoreboard', text: 'Confidence Score', link: 'projects-confidence-score', permission: !this.localUserExternal && this.localConfidenceScoreFlag === 1 && this.$can('poc_confidence_score.index')},
                { icon: 'donut_large', text: 'Status', link: 'projects-status', permission: this.$presentationCheck({ slug: 'latest_status_update' }) },
                { icon: 'sell', text: 'Fields & Tags', link: 'projects-fields-tags', permission: true },
                { icon: 'check_box', text: 'Scope', link: 'projects-usecases', permission: this.$can('usecases.index') },
                { icon: 'video_call', text: 'Meetings', link: 'projects-meetings', permission: this.localHasMeetingFlag && this.$can('project_meetings.index') && this.$plan('meetings:full-access') },
                { icon: 'check_circle_outline', text: 'Tasks', link: 'projects-tasks', permission: this.$can('tasks.index') },
                { icon: 'notes', text: 'Notes', link: 'projects-notes', permission: this.$can('notes.index') },
                { icon: 'source', text: 'Documents', link: 'projects-documents', permission: this.$can('documents.index') },
                { icon: 'link', text: 'Links', link: 'projects-links', permission: this.$can('links.index') },
                { icon: 'article', text: 'Reports', link: 'projects-document-generator', permission: this.$can('document_generator.index') },
                { icon: 'account_tree', text: 'Tickets', link: 'projects-tickets', permission: !this.localUserExternal && this.$can('poc_tickets.index') },
                { icon: 'memory', text: 'Inventory', link: 'projects-inventories', permission: this.localInventoryFlag === 1 && this.$can('inventories.index') && !this.localUserExternal },
                { icon: 'history', text: 'Activities', link: 'projects-activities', permission: true },
                { icon: 'settings', text: 'Settings', link: 'projects-settings', permission: true },
            ]

            return menuList
        },

        localInventoryFlag () {
            return this.user_self.organization && this.user_self.organization.flag_inventory && this.user_self.organization.flag_inventory
        },

        localHasMeetingFlag () {
            return this.user_self.organization && this.user_self.organization.flag_meetings && this.user_self.organization.flag_meetings === 1
        },

        localConfidenceScoreFlag () {
            return this.user_self.organization && this.user_self.organization.flag_confidence_score && this.user_self.organization.flag_confidence_score
        },

        localSalesforceInstanceUrl () {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url
        },

        localHubspotPortalId () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_portal_id
        },

        localHubspotInstanceUrl () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_instance_url
        },

        localUserExternal () {
            return this.user_self && this.user_self.scope === 'external'
        },

        localProjectStatus () {
            return this.project_item && this.project_item.status
        },

        localResultType () {
            if (!this.project_item?.result_id || !this.project_item?.result) return

            const currentResult = this.project_item.result
            const resultTypes = {
                Positive: { text: 'Won', color: 'green', icon: 'thumb_up' },
                Negative: { text: 'Lost', color: 'red', icon: 'thumb_down' },
                Neutral: { text: 'Undecided', color: 'grey', icon: 'view_stream' },
            }

            if (currentResult) return { ...resultTypes[currentResult.status], value: currentResult.value }
        },

        ...mapState('User', {
            user_self: 'self',
        }),

        ...mapState('ProjectView', {
            project_item: 'item',
            project_is_friendly_view: 'is_friendly_view',
            project_response: 'response',
        }),

        ...mapState('Preference', {
            preference_list: 'list',
        }),
    },

    methods: {
        async local_index() {
            this.localSetLoading('root')
            this.localFetchPreferences()
            this.localSetSidebarLinks()
            if (this.user_self && this.user_self.scope === 'internal') this.workspace_board()
            this.local_project_show()
        },

        async localFetchPreferences () {
            await this.preference_index()
            this.localSetSidebarLinks()
        },

        localSetSidebarLinks () {
            this.localSidebarLinks = this.localSidebarMenuList.filter(item => {
                if (item.link === 'projects-confidence-score' && !this.localHasPreference()) return
                else return item.permission
            })
        },

        localHasPreference () {
            const preference = this.preference_list.find(item => item.key === 'organization.confidence_score')
            return preference && preference.value === 'Yes'
        },

        async local_project_show() {
            await this.project_show({
                id: this.$route.params.id,
                params: {
                    'include': 'customer,keyCollaborators.user,keyCollaborators.type,bridges,result',
                    'fields[projects]': 'id,title,status,customer_id,result_id',
                    'fields[customer]': 'id,name',
                    'fields[result]': 'id,type,value,status'
                },
            })
            this.localResetLoading('root')
        },

        localEditProjectTitle () {
            this.dialogProjectStatic = true
        },

        localGetProjectFieldsToUpdate (project) {
            return {
                id: project['id'] ?? null,
                title: project['title'] ?? null,
                start_date: project['start_date'] ?? null,
                due_date: project['due_date'] ?? null,
                estimated_start_date: project['estimated_start_date'] ?? null,
                estimated_end_date: project['estimated_end_date'] ?? null,
                is_date_permission: project['is_date_permission'] ?? 0,
                is_estimated_date_permission: project['is_estimated_date_permission'] ?? 0,
            }
        },

        async localUpdateStaticFields (project, clearLoading, setErrors) {
            await this.project_update({ id: this.$route.params.id, ...this.localGetProjectFieldsToUpdate(project) })
            if (!this.$status(this.project_response)) {
                clearLoading('error')
                return setErrors()
            }
            this.project_update_item(project)
            this.$notify('success', 'Updated Successfully!')
            clearLoading()
        },

        localSetLoading (type) {
            const index = this.loadingTypes.indexOf(type)
            if (index === -1) this.loadingTypes.push(type)
        },

        localResetLoading (type) {
            const index = this.loadingTypes.indexOf(type)
            if (index !== -1) this.loadingTypes.splice(index, 1)
        },

        localIsLoading (type) {
            return this.loadingTypes.includes(type)
        },

        localExitFriendlyView () {
            this.project_switch_friendly_view(false)
            this.isPresentationView = false
        },

        // Extras
        localHasPermission (type) {
            return this.$can(`projects.${type}`)
        },

        local_check_bridge (service, key) {
            return this.project_item.bridges && this.project_item.bridges.find(item => {
                return item.service === service && item.key === key
            })
        },

        local_bridge_value (service, bridges_key, bridges_opp_key) {
            if (this.project_item && this.project_item.bridges) {
                const bridges = this.project_item.bridges.find(item => item.service === service && item.key === bridges_key)
                const bridges_opp = this.project_item.bridges.find(item => item.service === service && item.key === bridges_opp_key)

                if (bridges && bridges.value) return bridges.value
                if (bridges_opp && bridges_opp.value) return bridges_opp.value
                return ''
            }
        },

        ...mapActions('ProjectView', {
            project_show: 'show',
            project_update: 'update',
            project_update_item: 'update_item',
            project_switch_friendly_view: 'switch_friendly_view',
        }),

        ...mapActions('Preference', {
            preference_index: 'index',
        })
    },
}
</script>

<style lang="scss" scoped>
.c-sidebar-links {
    &:hover { background-color: #FAFAFA !important; }
}

.break-all {
    word-break: break-all;
}

.c-project-title-edit {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid transparent !important;
    border-radius: 8px;
    &--icon {
        visibility: hidden !important;
        // transition: 0.25s visibility ease-in-out;
        position: absolute;
        top: -9px;
        right: -9px;
        // border: 1px solid #26a2c7;
        padding: 4px;
    }
    &:hover {
        border: 2px solid #f3f3f3 !important;
        .c-project-title-edit--icon {
            visibility: visible !important;
            // transition: 0.25s visibility ease-in-out;
        }
    }
}
</style>

<template>
    <a-main>
        <!-- <outgoing-webhooks-breadcrumb></outgoing-webhooks-breadcrumb>
        <outgoing-webhooks-header :installation="installation_item"></outgoing-webhooks-header>
        <outgoing-webhooks-tabs :installation="installation_item"></outgoing-webhooks-tabs> -->
        <a-container container--fluid grid-list-xl class="mt-2 mb-16">
            <a-layout align-center>
                <a-flex>
                    <h2 class="md-heading-5 font-weight-semibold">Settings</h2>
                    <p class="md-body-2 mb-0 grey--text text--darken-2">Global settings for the Outgoing Webhooks</p>
                </a-flex>
            </a-layout>

            <template>
                <a-card class="u-elevation-custom-1 mt-6">
                    <a-card-text class="pa-5">
                        <a-layout align-center>
                            <a-flex>
                                <h4 class="md-heading-6 black--text">Custom Headers</h4>
                                <p class="md-body-2 mb-0 grey--text text--darken-2">Define your custom headers which should be sent along the webhooks.</p>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink v-if="headers && headers.length === 0 && $can('applets.update')">
                                <a-btn color="primary" tag="a" small @click="local_add_headers()" :loading="loading" class="ma-0 px-2 py-3 u-flex align-center white--text text--darken-2 u-rounded-corners">
                                    <a-icon size="16" class="mr-1">add</a-icon>
                                    Add Header
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-card-text>
                    <template v-if="local_loading">
                        <a-divider></a-divider>
                        <div class="py-8 text-center md-subtitle-1">
                            Loading ...
                        </div>
                    </template>
                    <template v-if="headers && headers.length > 0">
                        <a-divider></a-divider>
                        <a-card-text class="pa-5">
                            <a-layout align-center v-for="(header, index) in headers" :key="index">
                                <a-flex shrink>
                                    <a-tooltip bottom v-if="!header.modified && header.type === 'new' && !header.toBeDeleted">
                                        <template v-slot:activator="{ on }">
                                            <div style="backgroundColor: #E8EAF6" class="c-custom-icon">
                                                <a-icon color="#3949AB" size="20" v-on="on">add_circle_outline</a-icon>
                                            </div>
                                        </template>
                                        <span>New Header</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-if="header.modified && !header.toBeDeleted">
                                        <template v-slot:activator="{ on }">
                                            <div style="backgroundColor: #EEEEEE" class="c-custom-icon">
                                                <a-icon color="#616161" size="20" v-on="on">drive_file_rename_outline</a-icon>
                                            </div>
                                        </template>
                                        <span>Modified</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-if="(header.is_duplicate || !header.is_valid) && header.type !== 'new' && !header.toBeDeleted && !header.modified">
                                        <template v-slot:activator="{ on }">
                                            <div style="backgroundColor: #FFF3E0" class="c-custom-icon">
                                                <a-icon color="#FB8C00" size="20" v-on="on">error</a-icon>
                                            </div>
                                        </template>
                                        <span v-if="!header.is_valid && !header.is_duplicate">Valid header must have both key and value</span>
                                        <span v-if="header.is_duplicate">Duplicate key</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-if="!!header.is_valid && !header.toBeDeleted && !header.modified && !header.is_duplicate">
                                        <template v-slot:activator="{ on }">
                                            <div style="backgroundColor: #E8F5E9" class="c-custom-icon">
                                                <a-icon color="#43A047" size="20" v-on="on">check_circle</a-icon>
                                            </div>
                                        </template>
                                        <span>Valid Header</span>
                                    </a-tooltip>
                                    <a-tooltip bottom v-if="header.toBeDeleted">
                                        <template v-slot:activator="{ on }">
                                            <div style="backgroundColor: #ffebee" class="c-custom-icon">
                                                <a-icon color="#E53935" size="20" v-on="on">delete</a-icon>
                                            </div>
                                        </template>
                                        <span>Deleted</span>
                                    </a-tooltip>
                                </a-flex>
                                <a-flex xs6 :class="{'u-opacity-50': header.toBeDeleted}">
                                    <input
                                        v-model="header.key"
                                        v-alphanumeric
                                        placeholder="Key"
                                        background-color="white"
                                        :disabled="!$can('applets.update') || header.toBeDeleted || loading"
                                        class="u-wfull px-3 md-subtitle-1 py-2 u-rounded-corners u-overflow-hidden"
                                        style="min-height: 40px; outline: none; border: 1px solid #EEEEEE"
                                        @input="local_header_key_validation(header, index)"
                                        :autofocus="(!header.key && !header.value)"
                                    >
                                </a-flex>
                                <a-flex xs6 :class="{'u-opacity-50': header.toBeDeleted}">
                                    <input
                                        v-model="header.value"
                                        placeholder="Value"
                                        background-color="white"
                                        style="min-height: 40px; outline: none; border: 1px solid #EEEEEE"
                                        class="u-wfull px-3 md-subtitle-1 py-2 u-rounded-corners u-overflow-hidden"
                                        @input="local_header_modified(header, index)"
                                        :disabled="!$can('applets.update') || header.toBeDeleted || loading"
                                    >
                                </a-flex>
                                <a-flex shrink v-if="$can('applets.update')">
                                    <a-tooltip bottom left v-if="!header.toBeDeleted" :disabled="header.toBeDeleted || loading">
                                        <template v-slot:activator="{ on }">
                                            <div v-if="loading" class="c-custom-icon" style="border: 1px solid #EEEEEE;">
                                                <a-icon color="#9E9E9E" size="20">remove_circle_outline</a-icon>
                                            </div>
                                            <div v-else class="c-custom-icon" style="border: 1px solid #EEEEEE; cursor: pointer" @click="local_select_remove_headers(header, index)" v-on="on">
                                                <a-icon color="#9E9E9E" size="20">remove_circle_outline</a-icon>
                                            </div>
                                        </template>
                                        <span>Remove Header</span>
                                    </a-tooltip>
                                    <div v-else>
                                        <a-tooltip bottom left :disabled="loading">
                                            <template v-slot:activator="{ on }">
                                                <div v-if="loading" class="c-custom-icon" style="border: 1px solid #EEEEEE; backgroundColor: #EEEEEE;">
                                                    <a-icon color="black" size="20">undo</a-icon>
                                                </div>
                                                <div v-else class="c-custom-icon" style="border: 1px solid #EEEEEE; backgroundColor: #EEEEEE; cursor: pointer" @click="local_revert_delete(index)">
                                                    <a-icon v-on="on" color="black" size="20">undo</a-icon>
                                                </div>
                                            </template>
                                            <span>Revert</span>
                                        </a-tooltip>
                                    </div>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                        <a-divider></a-divider>
                        <a-card-text class="pa-5" v-if="$can('applets.update')">
                            <a-layout align-center>
                                <a-flex shrink class="pr-0">
                                    <a-btn height="32" color="#3949AB" tag="a" small :loading="loading" :disabled="!headers_modified" @click="local_store_headers()" class="c-save-btn ma-0 px-2 py-3 u-flex align-center white--text text--darken-2 u-rounded-corners">
                                        Save
                                    </a-btn>
                                </a-flex>
                                <a-flex shrink style="padding-left: 8px">
                                    <a-btn height="32" color="#EEEEEE" depressed tag="a" small :disabled="!headers_modified || loading" @click="dialog_cancel_changes = true" class="ma-0 px-2 py-3 u-flex align-center text--darken-2 u-rounded-corners">
                                        <span style="color: #9E9E9E">Cancel</span>
                                    </a-btn>
                                </a-flex>
                                <a-flex shrink class="pl-1">
                                    <div class="px-4 u-rounded-corners d-flex align-center" style="backgroundColor: #FFF3E0; height: 32px" v-if="invalid_headers_alert">
                                        <a-icon size="16px" color="#FB8C00">info</a-icon>
                                        <p class="ml-2 font-weight-medium mb-0" style="color: #FB8C00">One or more headers are invalid</p>
                                    </div>
                                </a-flex>
                                <a-spacer></a-spacer>
                                <a-flex shrink>
                                    <a-btn height="32" color="primary" tag="a" small :loading="loading" @click="local_add_headers()" class="ma-0 px-2 py-3 u-flex align-center white--text text--darken-2 u-rounded-corners">
                                        <a-icon size="16" class="mr-1">add</a-icon>
                                        Add Header
                                    </a-btn>
                                </a-flex>
                            </a-layout>
                        </a-card-text>
                    </template>
                </a-card>
            </template>
        </a-container>

        <!--Cancel change alert-->
        <a-dialog v-model="dialog_cancel_changes" persistent max-width="400">
            <SCancelChanges
                @success="local_cancel_changes()"
                @close="dialog_cancel_changes = false"
                class="pa-6"
            />
        </a-dialog>

        <!--Leaving page without save alert -->
        <a-dialog v-model="dialog_leave_page_alert" persistent max-width="400">
            <SCancelChanges
                @success="local_leave_page()"
                @close="dialog_leave_page_alert = false"
                class="pa-6"
            >
                <template #title>You have unsaved changes. Are you sure you want to leave?</template>
                <template #successButtonLabel>Yes, Leave</template>
            </SCancelChanges>
        </a-dialog>

        <!-- Updated snackbar -->
        <a-snackbar v-model="snackbar_status" color="green darken-3" class="text-center" :timeout="4000" bottom center>
            <div class="text-center" style="width: 100%">{{snackbar_text}}</div>
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OutgoingWebhooksBreadcrumb from "./WebhooksBreadcrumb";
import OutgoingWebhooksHeader from "./WebhooksHeader";
import OutgoingWebhooksTabs from "./WebhooksTabs";
import SCancelChanges from '/src/components/Shared/SharedCancelChanges'

export default {
    directives: {
        alphanumeric: {
            bind (el) {
                el.value = el.value.replace(/[^aA-zZ0-9-]+/ig, '').replace(/\^/g, '').replace(/\_/g, '').replace(/[\[\]']+/g, '')
            },
            update (el) {
                el.value = el.value.replace(/[^aA-zZ0-9-]+/ig, '').replace(/\^/g, '').replace(/\_/g, '').replace(/[\[\]']+/g, '')
            },
            componentUpdated (el) {
                el.value = el.value.replace(/[^aA-zZ0-9-]+/ig, '').replace(/\^/g, '').replace(/\_/g, '').replace(/[\[\]']+/g, '')
            }
        }
    },

    components: {
        OutgoingWebhooksBreadcrumb,
        OutgoingWebhooksHeader,
        OutgoingWebhooksTabs,
        SCancelChanges
    },

    data () {
        return {
            headers: [],
            stored_headers: [],
            local_loading: true,
            selectedHeaders: [],
            dialog_cancel_changes: false,
            snackbar_status: false,
            snackbar_text: 'Updated Successfully !',
            headers_modified: false,
            invalid_headers_alert: false,
            dialog_leave_page_alert: false,
            to_page_route: null,
        }
    },

    mounted () {
        this.local_index()
        window.addEventListener('beforeunload', this.local_handler_close)
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.local_handler_close)
    },

    beforeRouteLeave (toPage, fromPage, next) {
        if (!this.headers_modified) return next()
        next(false)
        this.dialog_leave_page_alert = true
        this.to_page_route = next
    },

    computed: {
        breadcrumb_items() {
            let breadcrumbs = [];
            breadcrumbs.push({text: 'Dashboard', to: '/dashboard', exact: true})
            breadcrumbs.push({text: 'Apps', to: '/appstore', exact: true})
            breadcrumbs.push({text: 'Configure Apps', to: '/appstore/installed', exact: true})
            return breadcrumbs
        },
        ...mapState('InstallationSetting', {
            installation_setting_list: 'list',
        }),
        ...mapState('Installation', {
            installation_item: 'item',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async local_index() {
            if (this.user_self.role.scope === 'external' || !this.$can('applets.index')) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            await this.local_headers_show()
            this.local_loading = false
        },

        local_add_headers() {
            this.headers.push({ key: '', value: '', type: 'new' })
            this.headers_modified = true
        },

        async local_store_headers() {
            this.selectedHeaders = this.headers.filter((header, index) => {
                return !header.toBeDeleted && (header.key || header.value)
            })
            const current_headers = this.selectedHeaders.map(({ key, value }) => ({ key, value }))
            await this.installation_setting_patch({id: this.$route.params.id, headers: current_headers})
            this.snackbar_status = true
            this.headers_modified = false
            this.local_headers_show()
        },

        async local_headers_show () {
            await this.installation_setting_clear()
            await this.installation_setting_index({'filter[installation_id]': this.$route.params.id})
            if(this.installation_setting_list && this.installation_setting_list[0] && this.installation_setting_list[0].headers) {
                this.headers = this.installation_setting_list[0] ? [...this.installation_setting_list[0].headers] : []
                this.local_check_invalid_headers(this.headers)
                this.stored_headers = _.cloneDeep(this.headers)
            }
        },

        local_check_invalid_headers (headers) {
            const invalid_headers = headers.find(header => !header.is_valid || header.is_duplicate)
            this.invalid_headers_alert = invalid_headers ? true : false
        },

        local_header_key_validation (header, index) {
            this.local_alpha_numeric(header.key, header, 'key')
            this.local_header_modified(header, index)
        },

        local_select_remove_headers (header, index) {
            if (!header.key && !header.value) {
                this.headers.splice(index, 1)
                if(!this.stored_headers) return
                const altered = this.headers.filter(header => header.modified || header.toBeDeleted || header.type === 'new')
                this.headers_modified = !!altered.length
                return
            }
            const deletedHeader = {...this.headers[index], toBeDeleted: true}
            this.headers.splice(index, 1, deletedHeader)
            this.headers_modified = true
        },

        local_alpha_numeric (val, item, field) {
            if (!val) return
            const result = val.replace(/[^aA-zZ0-9-]+/ig, '').replace(/\^/g, '').replace(/\_/g, '').replace(/[\[\]']+/g, '')
            if (item) return item[field] = result
            return result
        },

        async local_cancel_changes () {
            await this.local_headers_show()
            this.headers_modified = false
            this.dialog_cancel_changes = false
        },

        local_header_modified (header, index) {
            if(!this.stored_headers[index]) return
            this.headers[index].modified = (header.value !== this.stored_headers[index].value || header.key !== this.stored_headers[index].key)
            const altered = this.headers.filter(header => header.modified || header.toBeDeleted || header.type === 'new')
            this.headers_modified = !!altered.length
        },

        local_revert_delete (index) {
            this.headers[index].toBeDeleted = false
            const revertedValue = {...this.headers[index], toBeDeleted: false}
            const altered = this.headers.filter(header => header.modified || header.toBeDeleted || header.type === 'new')
            this.headers.splice(index, 1, revertedValue)
            if(!this.stored_headers[index]) return
            this.headers_modified = (!!altered.length) || (revertedValue.toBeDeleted !== !!this.stored_headers[index].toBeDeleted)
        },

        local_leave_page () {
            this.headers_modified = false
            this.to_page_route()
        },

        local_handler_close (e) {
            if (this.headers_modified) {
                e.returnValue = 'Are you sure to close the tab?';
            }
        },

        ...mapActions('Installation', {
            installation_show: 'show',
            installation_clear_item: 'clear_item',
        }),

        ...mapActions('InstallationSetting', {
            installation_setting_index: 'index',
            installation_setting_clear: 'clear',
            installation_setting_patch: 'patch',
        }),

    },
}
</script>

<style scoped>
    .c-custom-icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        border: 0px;
        border-radius: 0.25rem;
    }
    .c-save-btn.v-btn.v-btn--disabled {
        opacity: 0.5;
        background-color: #3949AB !important;
        color: #fff !important;
    }
</style>

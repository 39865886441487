<template>
    <a-main class="mb-16">
        <a-layout align-start v-if="$can('templates.store')">
            <a-flex>
                <a-form @submit.prevent="local_group_store()">
                    <a-text-field
                        v-model="template_group_name"
                        placeholder="Add new link group"
                        prepend-inner-icon="add"
                        class="u-elevation-custom-1"
                        :loading="loading"
                        solo flat hide-details autofocus
                    ></a-text-field>
                </a-form>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name && template_group_form_mode == 'add'">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ template_group_response.server.errors.name[0] }}
                </span>
            </a-flex>
        </a-layout>
        <a-responsive class="py-4 my-4" v-if="!loading && template_group_list.length == 0 && !local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1 mb-1 text-uppercase">No Data Found</h3>
            <p class="md-body-2 text-center grey--text mb-0">There is no data to fetch right now.</p>
        </a-responsive>
        <template v-if="local_loading">
            <h3 class="md-subtitle-1 font-weight-bold text-center grey--text text--darken-1">Loading..</h3>
        </template>
        <template v-if="!local_loading">
            <draggable v-model="template_group_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                <div class="my-2 mt-1" :id="'link-'+template_group.id" :class="{ 'js-draggable-file-list-item': $can('templates.update') }" v-for="(template_group, index) in template_group_list" :key="template_group.id">
                    <a-container container--fluid grid-list-xl class="pa-4 u-elevation-custom-1 white" :class="[template_group_expanded === index ? '' : 'u-rounded-corners']">
                        <a-layout wrap align-center>
                            <a-flex shrink class="u-cursor-pointer pr-0" v-if="$can('templates.update') && user_self.role && user_self.role.scope === 'internal'">
                                <a-icon class="grey--text text--darken-1 js-drag-handle" :class="{'u-opacity-30': !$can('templates.update')}">drag_indicator</a-icon>
                            </a-flex>
                            <a-flex xs7 xl9 class="u-cursor-pointer" @click="local_group_toggle(index)">
                                <h2 class="subtitle-1 u-word-wrap">{{ template_group.name }}</h2>
                                <p class="md-body-2 mb-0 grey--text text--darken-2 mt-2" v-if="template_group_expanded !== index">
                                    <span>{{ template_group.template_links_count ? template_group.template_links_count : 0 }} Links<span class="mx-2"> </span></span>
                                </p>
                            </a-flex>
                            <a-spacer></a-spacer>
                            <a-flex shrink>
                                <a-tooltip bottom offset-overflow>
                                    <template v-slot:activator="{ on }">
                                        <a-btn v-on="on" icon>
                                            <a-icon size="16" class="grey--text text--darken-1">info</a-icon>
                                        </a-btn>
                                    </template>

                                    <div class="py-1">
                                        <p v-if="template_group.created_by" class="text-body-2 grey--text text--lighten-2 mb-0">Created by
                                            <span class="text-subtitle-2 white--text">{{ template_group.created_by.name }}</span>
                                            <span class="ml-2 text-caption">{{ local_date_output_format(template_group.created_at) }}</span>
                                        </p>

                                        <p v-if="template_group.updated_by && template_group.created_at !== template_group.updated_at" class="text-body-2 grey--text text--lighten-2 mb-0">Last updated by
                                            <span class="text-subtitle-2 white--text">{{ template_group.updated_by.name }}</span>
                                            <span class="text-caption ml-2">{{ local_date_output_format(template_group.updated_at) }}</span>
                                        </p>
                                    </div>
                                </a-tooltip>
                                <a-btn icon text color="grey darken-1" @click="local_template_clone_create(template_group.id, template_group)" v-if="$can('templates.store')">
                                    <a-icon size="16">content_copy</a-icon>
                                </a-btn>
                                <a-btn icon text color="grey darken-1"  @click="local_group_edit(template_group.id)" v-if="$can('templates.update')">
                                    <a-icon size="16">edit</a-icon>
                                </a-btn>
                                <a-menu bottom left offset-y :close-on-content-click="false" max-width="320" v-if="$can('templates.destroy')">
                                    <template v-slot:activator="{ on }">
                                        <a-btn icon text color="grey darken-1" v-on="on">
                                            <a-icon size="16">delete</a-icon>
                                        </a-btn>
                                    </template>
                                    <a-card>
                                        <a-card-text class="pa-4">
                                            <h2 class="md-heading-6 mb-2">Are you sure to delete this link group?</h2>
                                            <p class="md-body-2 mb-4">This will delete all links under this template and cannot be undone.</p>
                                            <a-btn class="ma-0 elevation-0" color="red" @click="local_group_destroy(template_group.id)" :loading="loading" dark small>
                                                <a-icon size="16" left class="mr-2">delete</a-icon> Delete
                                            </a-btn>
                                        </a-card-text>
                                    </a-card>
                                </a-menu>
                                <a-btn icon text color="grey darken-1" @click="local_group_toggle(index)">
                                    <a-icon v-if="template_group_expanded === index">arrow_drop_up</a-icon>
                                    <a-icon v-else>arrow_drop_down</a-icon>
                                </a-btn>
                            </a-flex>
                        </a-layout>
                    </a-container>
                    <template v-if="template_group_expanded === index">
                        <a-divider></a-divider>
                        <a-form @submit.prevent="local_store(template_group.id)" v-if="$can('templates.update')">
                            <a-sheet class="grey lighten-4 px-1 u-elevation-custom-1">
                                <a-text-field
                                    v-model="template_link_url"
                                    placeholder="Enter URL and hit enter"
                                    prepend-inner-icon="add"
                                    background-color="grey lighten-4"
                                    :loading="loading"
                                    solo flat hide-details autofocus
                                ></a-text-field>
                                <span class="md-caption red--text text--darken-2 d-block pl-4 pb-1 u-flex-center-y" v-if="template_link_response.server && template_link_response.server.errors && template_link_response.server.errors.url && template_link_form_mode == 'add'">
                                    <a-icon size="16" color="red darken-2">warning</a-icon>
                                    {{ template_link_response.server.errors.url[0] }}
                                </span>
                            </a-sheet>
                        </a-form>
                        <a-divider></a-divider>
                        <draggable v-model="template_link_list" :options="{handle: '.js-drag-handle'}" draggable=".js-draggable-file-list-item">
                            <div v-for="(template_link, index) in template_link_list" :key="template_link.id" :class="{'js-draggable-file-list-item': $can('templates.update')}">
                                <a-card class="u-elevation-custom-1 u-relative" flat>
                                    <div v-if="template_link.visibility === 'external'" class="c-visibility-bar"></div>
                                    <a-divider v-if="index !== 0" class="grey lighten-3" :key="template_link.id + '-divider'"></a-divider>
                                    <a-card-text class="pa-0">
                                        <a-container container--fluid grid-list-xl class="py-4 px-6">
                                            <a-layout align-center>
                                                <a-flex shrink class="px-0" v-if="user_self.role && user_self.role.scope === 'internal'">
                                                    <a-icon class="grey--text text--lighten-1 u-cursor-pointer js-drag-handle" :class="{'u-opacity-30': !$can('templates.update')}">drag_indicator</a-icon>
                                                </a-flex>
                                                <a-flex xs2 md3 lg4 xl8>
                                                    <h2 :class="[ template_link.title.length > 60 || template_link.title.length > 30 ? 'text-truncate' : '', 'md-subtitle-1 primary--text d-block text-no-wrap' ]" style="max-width: 760px;">{{ template_link.title }}</h2>
                                                    <a :href="template_link.url" :title="template_link.url" target="_blank" rel="noopener" :class="[ template_link.url.length > 60 || template_link.url.length > 30 ? 'text-truncate' : '', 'md-body-2 mb-0 blue--text text--darken-2 u-no-underline d-block text-no-wrap' ]" style="max-width: 760px;">{{ template_link.url }}</a>
                                                </a-flex>
                                                <a-spacer></a-spacer>
                                                <a-flex shrink v-if="$can('templates.update')">
                                                    <a-menu bottom right offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Copy to <a-icon>arrow_drop_down</a-icon></span>
                                                        </template>
                                                        <a-list class="u-list-condensed">
                                                            <template v-for="link_group in template_group_list">
                                                                <a-list-item :key="link_group.id" @click="local_copy_template_link_group(link_group.id, template_group.id, template_link)">
                                                                    <a-list-item-title class="md-body-2" :title="link_group.name">
                                                                        {{ link_group.name }}
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                            </template>
                                                        </a-list>
                                                    </a-menu>
                                                </a-flex>
                                                <a-flex shrink v-if="template_group_list.length > 1 && $can('templates.update')">
                                                    <a-menu bottom left offset-y transition="slide-y-transition" max-height="300" min-width="320" max-width="320">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="md-body-2 grey lighten-3 pl-2 u-rounded-corners grey--text text--darken-3 d-inline-block">Move to <a-icon>arrow_drop_down</a-icon></span>
                                                        </template>
                                                        <a-list class="u-list-condensed">
                                                            <template v-for="link_group in template_group_list">
                                                                <a-list-item :key="link_group.id" v-if="template_link.template_group_id !== link_group.id" @click="local_change_template_link_group(link_group.id, template_group.id, template_link)">
                                                                    <a-list-item-title class="md-body-2" :title="link_group.name">
                                                                        {{ link_group.name }}
                                                                    </a-list-item-title>
                                                                </a-list-item>
                                                            </template>
                                                        </a-list>
                                                    </a-menu>
                                                </a-flex>
                                                <a-flex shrink v-if="$can('templates.update')">
                                                    <a-tooltip bottom  v-if="template_link.visibility === 'internal' || template_link.visibility === null">
                                                        <template v-slot:activator="{ on }">
                                                            <a-btn text icon color="grey" class="mx-0" v-on="on" :disabled="loading" :loading="local_loading_get(template_link.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(template_link.id, template_link.visibility) : ''">
                                                                <a-icon size="16">lock</a-icon>
                                                            </a-btn>
                                                        </template>
                                                        <span>Private</span>
                                                    </a-tooltip>
                                                    <a-tooltip bottom v-else>
                                                    <template v-slot:activator="{ on }">
                                                        <a-btn text icon color="orange darken-2" class="mx-0" v-on="on" v-if="!$can('templates.update')">
                                                            <a-icon size="16">lock_open</a-icon>
                                                        </a-btn>
                                                        <a-btn text icon color="orange darken-2" class="mx-0" v-on="on" v-if="$can('templates.update')" :loading="local_loading_get(template_link.id, 'visibility')" @click="$can('templates.update') ? local_visibility_toggle(template_link.id, template_link.visibility) : ''">
                                                            <a-icon size="16">lock_open</a-icon>
                                                        </a-btn>
                                                    </template>
                                                    <span>Visible to external collaborators when imported into project.</span>
                                                </a-tooltip>
                                                </a-flex>
                                                <a-flex shrink v-if="user_self.role && user_self.role.scope === 'internal'">
                                                    <a-btn icon text small color="grey" class="mx-0" @click="local_edit(template_link.id)" v-if="$can('templates.update')">
                                                        <a-icon size="16">edit</a-icon>
                                                    </a-btn>
                                                    <a-btn icon text small color="grey" class="mx-0" @click="local_destroy(template_link)" v-if="$can('templates.destroy')">
                                                        <a-icon size="16">delete</a-icon>
                                                    </a-btn>
                                                </a-flex>
                                            </a-layout>
                                        </a-container>
                                    </a-card-text>
                                </a-card>
                            </div>
                        </draggable>
                    </template>
                </div>
            </draggable>
        </template>

        <a-dialog v-model="dialog_template_link_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                    <a-form @submit.prevent="local_store()">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>link</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Edit Link Information</h2>
                                    <p class="md-subtitle-2 mb-0">Edit information related to link.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Title</label>
                                    <a-text-field
                                        v-model="template_link_item.title"
                                        placeholder="Enter Title"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_link_response.server && template_link_response.server.errors && template_link_response.server.errors.title && template_link_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_link_response.server.errors.title[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the document.
                                    </span>
                                </a-flex>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">URL</label>
                                    <a-text-field
                                        v-model="template_link_item.url"
                                        placeholder="Enter URL"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_link_response.server && template_link_response.server.errors && template_link_response.server.errors.url  && template_link_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_link_response.server.errors.url[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        Enter the URL to store.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_update()" :loading="loading" :disabled="loading">Save Link</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_template_link_form_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                    </a-form>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_template_group_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>library_books</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Edit Link Group</h2>
                                    <p class="md-subtitle-2 mb-0">Edit information related to link group.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="template_group_item.name"
                                        placeholder="Enter Group Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_group_response.server && template_group_response.server.errors && template_group_response.server.errors.name && template_group_form_mode == 'edit'">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_group_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the group.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_group_update()" :loading="loading" :disabled="loading">Save Group</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_group_form_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-dialog v-model="dialog_template_group_clone_form" max-width="600" persistent>
            <a-card>
                <a-card-text class="pa-0">
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink class="pr-1">
                                    <a-avatar class="primary darken-1" size="40">
                                        <a-icon dark>content_copy</a-icon>
                                    </a-avatar>
                                </a-flex>
                                <a-flex>
                                    <h2 class="md-heading-6 primary--text text--darken-1">Duplicate Link Group</h2>
                                    <p class="md-subtitle-2 mb-0">Creates a duplicate of the link group.</p>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex xs12>
                                    <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Name</label>
                                    <a-text-field
                                        v-model="local_template_group_clone_item.name"
                                        placeholder="Enter Group Name"
                                        background-color="neutral"
                                        solo flat hide-details
                                    >
                                    </a-text-field>
                                    <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="template_link_response.server && template_link_response.server.errors && template_link_response.server.errors.name">
                                        <a-icon size="16" color="red darken-2">warning</a-icon>
                                        {{ template_link_response.server.errors.name[0] }}
                                    </span>
                                    <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                                        <a-icon size="16">info</a-icon>
                                        This will help you identify the group.
                                    </span>
                                </a-flex>
                            </a-layout>
                        </a-container>
                        <a-divider></a-divider>
                        <a-container container--fluid grid-list-xl class="pa-6">
                            <a-layout wrap align-center>
                                <a-flex shrink>
                                    <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="local_group_clone()" :loading="loading" :disabled="loading">duplicate</a-btn>
                                    <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="local_group_clone_cancel()" :disabled="loading">Cancel</a-btn>
                                </a-flex>
                            </a-layout>
                        </a-container>
                </a-card-text>
            </a-card>
        </a-dialog>
        <a-snackbar v-model="snackbar_status" color="green darken-3" class="text-center" :timeout="4000" bottom center>
            <div class="text-center" style="width: 100%">{{snackbar_text}}</div>
        </a-snackbar>
    </a-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { diffHuman } from '@/helpers/helper-date-filter'

export default {
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                    exact: true,
                }
            ],
            snackbar_status: false,
            snackbar_text: '',
            template_group_name: null,
            template_link_url: '',
            template_group_expanded: null,
            dialog_template_link_form: false,
            dialog_template_group_form: false,
            dialog_template_group_clone_form: false,
            local_template_group_clone_item: {},
            local_loading: false,
            loading_item: null,
            loading_action: null,
            local_template_group_item: null,
        }
    },
    mounted() {
        this.local_index()
    },
    watch: {
        template_group_expanded(newValue, oldValue) {
            if (newValue != null) {
                this.local_list(this.template_group_list[newValue].id)
            }
        },
        dialog_template_group_form(newValue, oldValue) {
            if (newValue === false) {
                this.template_group_clear_item();
            }
        },
    },
    computed: {
        local_linked_template_group_id() {
            return this.$route.params.group_id
        },
        template_link_item: {
            get () {
                return this.$store.state.TemplateLink.item
            },
            set (item) {
                this.$store.state.TemplateLink.item = item
            }
        },
        template_link_list: {
            get() {
                return this.$store.state.TemplateLink.list
            },
            set(list) {
                this.local_reorder(list)
            }
        },
        template_group_list: {
            get() {
                return this.$store.state.TemplateGroup.list
            },
            set(list) {
                this.local_group_reorder(list)
                this.template_group_expanded = null
            }
        },
        ...mapState('User', {
            user_self: 'self',
        }),
        ...mapState('TemplateGroup', {
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),
        ...mapState('TemplateLink', {
            template_link_filters: 'filters',
            template_link_response: 'response',
            template_link_form_mode: 'form_mode',
        }),
        ...mapState('Interface', {
            loading: 'loader',
        }),
    },
    methods: {
        async local_index() {
            if (this.$can('templates.show') == false) {
                this.$router.replace({name: 'errors-unauthorized'})
            }
            this.local_loading = true
            await this.template_group_clear()
            await this.local_load_links_template_group()

            if(this.local_linked_template_group_id) {
                setTimeout(() => {
                    this.$vuetify.goTo('#link-'+this.local_linked_template_group_id)
                    this.template_group_expanded = this.template_group_list.findIndex(list => list.id === this.local_linked_template_group_id);
                }, 500)
            }

            this.local_loading = false
        },
        async local_load_links_template_group() {
            await this.template_group_index({
                'filter[type]': 'links',
                'fields[template_groups]': 'id,name,order,type,created_at,updated_at,created_by_id,updated_by_id',
                'include': 'templateLinksCount,created_by,updated_by',
                'sort': 'order'
            })
        },
        async local_links_template_group_show(id) {
            await this.template_group_show({
                'fields[template_groups]': 'id,name,order,type,created_at,updated_at,created_by_id,updated_by_id',
                'include': 'templateLinksCount,created_by,updated_by',
                'sort': 'order',
                'id': id,
            })
        },
        async local_list(id) {
            await this.template_link_clear()
            await this.template_link_index({
                'filter[template_group_id]': id,
                'fields[template_links]': 'id,title,url,order,template_group_id,visibility',
                'sort': 'order',
            })
        },
        async local_store(id) {
            await this.template_link_clear_item()
            // this.template_link_item.project_id = this.$route.params.id
            this.template_link_item.url = this.template_link_url
            this.template_link_item.template_group_id = id
            this.template_link_item.visibility = 'internal'

            await this.template_link_store({
                ...this.template_link_item,
                    template_group_id: id,
                    'order': -1
            })

            if (this.template_link_response.status === 'success') {
                this.local_links_template_group_show(id)
                this.template_link_url = ''
                await this.template_link_clear_item()
            }
        },
        async local_edit(id) {
            this.dialog_template_link_form = true
            await this.template_link_select({id: id})
        },
        async local_update() {
            await this.template_link_update({
                ...this.template_link_item,
            })

            if (this.template_link_response.status == 'success') {
                this.dialog_template_link_form = false
                await this.local_links_template_group_show(this.template_link_item.template_group_id)
                await this.template_link_clear_item()
            }
        },
        async local_destroy(link) {
            await this.template_link_destroy({id: link.id})
            await this.local_links_template_group_show(link.template_group_id)
            this.template_group_list[this.template_group_expanded].template_links_count = this.template_link_list.length
        },
        async local_visibility_toggle(id, status) {
            if (status == 0) {
                await this.template_link_client_visible({id: id})
                await this.template_link_show({id: id})
            } else {
                await this.template_link_client_invisible({id: id})
                await this.template_link_show({id: id})
            }
        },
        async local_reorder(list) {
            await this.template_link_reorder({list: list})
            this.local_load_links_template_group()
        },
        async local_group_reorder(list) {
            await this.template_group_reorder({list: list})
            this.local_load_links_template_group()
        },
        async local_group_store() {
            if (this.template_group_form_mode === 'add') {
                this.template_group_item.name = this.template_group_name;
                await this.template_group_store({
                    name: this.template_group_item.name,
                    type: 'links',
                    model: 'template',
                    'order': -1
                })
            }
            if (this.template_group_response.status == 'success') {
                this.dialog_template_group_form = false
                this.template_group_name = ''
                await this.local_links_template_group_show(this.template_group_item.id)
                this.template_group_expanded = null
                this.template_group_clear_item()
            }
        },
        async local_group_update() {
            await this.template_group_update({
                ...this.template_group_item
            })
            if (this.template_group_response.status === 'success') {
                this.local_links_template_group_show(this.template_group_item.id)
                await this.template_group_clear_item()
                this.dialog_template_group_form = false
            }
        },
        async local_template_clone_create(group_id, template_group) {
            this.local_template_group_item = template_group
            this.local_template_group_clone_item.name = ''
            this.local_template_group_clone_item.template_group_id = group_id
            this.dialog_template_group_clone_form = true
        },
        local_group_clear_item() {
            this.template_link_clear_item()
            this.local_template_group_clone_item.name = null
            this.local_template_group_clone_item.template_group_id = null
        },
        async local_group_clone_cancel() {
            this.local_group_clear_item()
            this.dialog_template_group_clone_form = false
            this.local_template_group_clone_item.name = null
        },
        async local_group_clone() {
            this.local_template_group_clone_item.template_links_count = this.local_template_group_item.template_links_count
            this.local_template_group_clone_item.type = this.local_template_group_item.type
            await this.template_link_group_clone(this.$appendId(this.local_template_group_clone_item))
            if (this.template_link_response.status == 'success') {
                await this.local_group_clone_item_add()
                this.template_group_expanded = null
                this.local_group_clear_item()
                this.dialog_template_group_clone_form = false
                this.local_show_group_message('Link group duplicated successfully')
            }
        },
        async local_group_clone_item_add() {
            const group_item = {}
            group_item.id = this.local_template_group_clone_item.id
            group_item.name = this.local_template_group_clone_item.name
            group_item.template_links_count = this.local_template_group_clone_item.template_links_count
            group_item.type = this.local_template_group_clone_item.type
            await this.template_group_clone_add_item(group_item)
            await this.local_links_template_group_show(group_item.id)
            this.local_template_group_item = null
        },
        async local_change_template_link_group(new_group_id, old_group_id, template_link) {
            this.snackbar_status = false
            template_link.template_group_id = new_group_id

            this.template_link_select_item({ data: template_link })
            await this.template_link_group_move({ ...template_link })
            if (this.template_link_response.status === 'success') {
                await this.local_update_group_meta_counts({ group_id: new_group_id, increase: true })
                await this.local_update_group_meta_counts({ group_id: old_group_id, increase: false })
                await this.local_links_template_group_show(new_group_id)
                await this.local_links_template_group_show(old_group_id)
                this.local_show_group_message('Link moved successfully')
            }
        },
        async local_copy_template_link_group(new_group_id, old_group_id, template_link) {
            this.snackbar_status = false
            template_link = _.cloneDeep(template_link)
            template_link.template_group_id = new_group_id
            template_link.old_template_group_id = old_group_id

            await this.template_link_group_copy({
                template_link_id: template_link.id,
                template_group_id: new_group_id,
                old_template_group_id: old_group_id
            })
            if (this.template_link_response.status === 'success') {
                await this.local_update_group_meta_counts({ group_id: new_group_id, increase: true })
                await this.local_links_template_group_show(new_group_id)
                this.local_show_group_message('Link copied successfully')
            }
        },
        local_show_group_message(text) {
            this.snackbar_text = `${ text }`
            this.snackbar_status = !this.snackbar_status
        },
        async local_group_form_cancel() {
            this.dialog_template_group_form = false
            await this.template_group_clear_item()
        },
        async local_template_link_form_cancel() {
            this.dialog_template_link_form = false
            await this.template_link_clear_item()
        },
        local_group_edit(id) {
            this.template_group_select({id: id})
            this.dialog_template_group_form = true
        },
        async local_group_destroy(id) {
            this.template_group_destroy({id: id})
            this.template_link_url = ''
            this.template_group_expanded = null
        },
        local_group_toggle(index) {
            if (this.template_group_expanded === index) {
                this.template_group_expanded = null
            }
            else {
                this.template_group_expanded = index
            }
        },

        async local_visibility_toggle(id, status) {
            await this.local_loading_set(id, 'visibility')
            await this.template_link_select({id: id})
            this.template_link_item.visibility = status === 'external' ? 'internal' : 'external'
            this.template_link_item.state = status === 'external' ? 'internal' : 'external'
            await this.template_link_visibility(this.template_link_item)
            await this.local_loading_clear()
            if (this.template_link_response.status === 'success') {
                await this.local_links_template_group_show(this.template_link_item.template_group_id)
                await this.template_link_clear_item()
            }
        },
        async local_loading_set(id, action) {
            this.loading_item = id
            this.loading_action = action
        },
        async local_loading_clear() {
            this.loading_item = null
            this.loading_action = null
        },
        local_loading_get(id, action) {
            return this.loading && this.loading_item == id && this.loading_action == action
        },

        async local_update_group_meta_counts ({ group_id, increase = false }) {
            const index = this.template_group_list.findIndex(item => item.id === group_id)
            if (index === -1) return

            const temp_group = this.template_group_list[index]
            temp_group.group_id = group_id
            if(!temp_group.template_links_count) temp_group.template_links_count = 0

            increase ? temp_group.template_links_count++ : temp_group.template_links_count--
            this.template_change_group_update(temp_group)
        },
        local_date_output_format(date) {
            return diffHuman(date)
        },

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_show: 'show',
            template_group_clone_add_item: 'add_group_item',
            template_change_group_update: 'change_group_update',
            template_group_store: 'store',
            template_group_update: 'update',
            template_group_reorder: 'reorder',
            template_group_select: 'select',
            template_group_destroy: 'destroy',
            template_group_clear: 'clear',
            template_group_clear_item: 'clear_item',
        }),
        ...mapActions('TemplateLink', {
            template_link_index: 'index',
            template_link_show: 'show',
            template_link_visibility: 'visibility',
            template_link_store: 'store',
            template_link_group_move: 'group_link_move',
            template_link_group_copy: 'group_copy_store',
            template_link_group_clone: 'group_clone_store',
            template_link_select_item: 'select_item',
            template_link_client_visible: 'client_visible',
            template_link_client_invisible: 'client_invisible',
            template_link_update: 'update',
            template_link_select: 'select',
            template_link_destroy: 'destroy',
            template_link_reorder: 'reorder',
            template_link_clear: 'clear',
            template_link_clear_item: 'clear_item',
        })
    }
}
</script>

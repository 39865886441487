<template>
    <a-sheet class="u-relative u-overflow-hidden" width="360" max-width="360">
        <a-sheet style="border-radius: 50%; top: -32px; right: -32px;" class="u-absolute u-flex align-center justify-center" width="64" height="64" :color="`${project.health_color === 'yellow' ? 'orange' : project.health_color} lighten-4`">
            <a-sheet style="border-radius: 50%;" width="48" height="48" :color="`${project.health_color === 'yellow' ? 'orange' : project.health_color} darken-2`"></a-sheet>
        </a-sheet>
        <div class="px-4 py-4">
            <a-sheet class="c-title-block u-flex align-start u-cursor-pointer mb-1" @click="local_goto_project()">
                <a-icon size="14" color="grey darken-2" class="mr-1" style="margin-top: 6px;">launch</a-icon>
                <h2 class="md-subtitle-1 grey--text text--darken-4 font-weight-medium" :title="project.title && project.title.length > 70 ? project.title : ''">{{ project.title | truncateText(70) }}</h2>
            </a-sheet>
            <div class="u-flex align-center mt-2">
                <a-chip class="ml-0 font-weight-medium grey--text text--darken-2" label small>{{ project.customer_name | truncateText(16) }}</a-chip>
                <g-currency tag="span" class="md-subtitle-1 font-weight-medium ml-3 green--text text--darken-3" :value="project.deal_amount"></g-currency>
                <div class="ml-3" v-if="project.salesforce_bridge">
                    <a class="u-flex align-center" :href="local_salesforce_url + '/' + ((project.salesforce_bridge && project.salesforce_bridge.value) ? project.salesforce_bridge.value : project.salesforce_bridge)" target="_blank" rel="noopener">
                        <img src="../../assets/images/icon-salesforce.svg" height="12" alt="Salesforce" class="d-inline-block">
                    </a>
                </div>
                <div class="ml-3" v-if="project.hubspot_bridge">
                    <a class="u-flex align-center" :href="localHubspotInstanceUrl+'/contacts/'+localHubspotPortalId+'/deal/'+project.hubspot_bridge" target="_blank" rel="noopener">
                        <img :src="require('../../assets/images/apps/hubspot/logo.svg')" height="20" alt="Hubspot" class="d-inline-block">
                    </a>
                </div>
            </div>
            <div class="mt-4">
                <div class="u-flex align-center">
                    <a-icon size="16" color="#a0a0a0" class="mr-2">schedule</a-icon>
                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium">Lifetime:
                        <a-progress-circular v-if="loading" width="1" size="20" color="blue darken-2" indeterminate></a-progress-circular>
                        <template v-if="!loading">{{ project.active_days }} days</template>
                    </span>
                </div>
                <div class="u-flex align-center mt-3">
                    <a-icon size="16" color="#a0a0a0" class="mr-2">calendar_today</a-icon>
                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium u-flex align-center">
                        <g-moment v-if="project.start_date" :value="project.start_date" input-format="YYYY-MM-DD" output-format="MMM D" convert-local></g-moment>
                        <template v-else>No Start Date</template>
                        <a-icon size="12" class="mx-1" color="grey darken-1">east</a-icon>
                        <g-moment v-if="project.due_date" :value="project.due_date" input-format="YYYY-MM-DD" output-format="MMM D" convert-local></g-moment>
                        <template v-else>No End Date</template>
                    </span>
                </div>
                <div class="u-flex align-center mt-3">
                    <a-icon size="16" color="#a0a0a0" class="mr-2">leaderboard</a-icon>
                    <span class="md-body-2 grey--text text--darken-2 font-weight-medium">Stage:
                        <a-progress-circular v-if="loading" width="1" size="20" color="blue darken-2" indeterminate></a-progress-circular>
                        <template v-if="!loading">
                            <template v-if="project.status !== 'archived'">
                                {{ project.stage_name | truncateText(25) }}
                                <template v-if="project.status === 'active'">({{project.stage_active_days}} days)</template>
                            </template>
                            <template v-else>Archived</template>
                        </template>
                    </span>
                </div>
            </div>
        </div>
        <a-divider class="grey lighten-2"></a-divider>
        <a-sheet class="pa-4 grey lighten-4">
            <template v-if="$can('usecases.index')">
                <h3 class="md-caption text-uppercase font-weight-bold grey--text text--darken-4" style="letter-spacing: 0.3px">
                    Success Criteria
                    <span v-if="!loading">({{ local_sc_total_count }}/{{ project.success_criteria_count }})</span>
                </h3>
                <template v-if="loading">
                    <a-sheet height="6" class="u-wfull u-rounded-corners-full my-2" color="grey lighten-2"></a-sheet>
                    <div class="u-flex align-center justify-space-between">
                        <a-sheet width="30%" height="8" class="u-wfull u-rounded-corners-full" color="grey lighten-2"></a-sheet>
                        <a-sheet width="30%" height="8" class="u-wfull u-rounded-corners-full" color="grey lighten-2"></a-sheet>
                        <a-sheet width="30%" height="8" class="u-wfull u-rounded-corners-full" color="grey lighten-2"></a-sheet>
                    </div>
                </template>
                <v-scroll-x-transition hide-on-leave leave-absolute>
                    <s-sc-stats v-if="!loading" :project="project" class="u-wfull" :open="project.success_criteria_open_count" :passed="project.success_criteria_passed_count" :failed="project.success_criteria_failed_count" :invalid="project.success_criteria_invalid_count"></s-sc-stats>
                </v-scroll-x-transition>
            </template>
            <div class="u-flex align-center" :class="{ 'mt-4': $can('usecases.index') }">
                <v-scroll-x-transition hide-on-leave leave-absolute>
                    <template v-if="loading">
                        <a-progress-circular width="1" size="36" color="grey darken-1" indeterminate>
                            <a-icon size="18" color="grey lighten-1">person</a-icon>
                        </a-progress-circular>
                    </template>
                </v-scroll-x-transition>
                <v-scroll-x-transition hide-on-leave leave-absolute>
                    <s-users-deck v-if="!loading && project.key_collaborators" :list="project.key_collaborators" :count="4" hide-list hide-role></s-users-deck>
                </v-scroll-x-transition>
            </div>
        </a-sheet>
    </a-sheet>
</template>

<script>
import { mapState } from 'vuex'
import SScStats from './SharedSCStats.vue'
import SUsersDeck from './SharedUsersDeck.vue'

export default {
    name: 'SharedProjectInfo',

    props: {
        project: {
            type: Object
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    components: { SScStats, SUsersDeck },

    computed: {
        local_sc_total_count () {
            const { success_criteria_passed_count, success_criteria_failed_count, success_criteria_invalid_count, successCriteriaOpenCount } = this.project
            return (success_criteria_passed_count ?? 0) + (success_criteria_failed_count ?? 0) + (success_criteria_invalid_count ?? 0) + (successCriteriaOpenCount ?? 0)
        },

        local_salesforce_url () {
            return this.user_self && this.user_self.organization && this.user_self.organization.salesforce_instance_url
        },

        localHubspotPortalId () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_portal_id
        },

        localHubspotInstanceUrl () {
            return this.user_self && this.user_self.organization && this.user_self.organization.hubspot_instance_url
        },

        ...mapState('User', {
            user_self: 'self'
        })
    },

    methods: {
        local_goto_project () {
            const { href } = this.$router.resolve({ name: 'projects-overview', params: { id: this.project.id } })
            window.open(href, '_blank')
        },
    }
}
</script>

<style lang="scss" scoped>
.c-title-block {
    h2 {
        &:hover {
            text-decoration: underline;
            -webkit-text-decoration-color: #a0a0a0; /* Safari */
            text-decoration-color: #a0a0a0;
        }
    }
}
</style>

<template>
    <div class="c-summary-tile u-relative u-hfull u-flex flex-column">
        <loader-project-activity-summary v-if="mixLoading" />

        <template v-else>
            <div class="pa-5">
                <div class="u-flex-center-y">
                    <a-icon size="16" color="grey" class="u-icon-nudge-xs" outlined>date_range</a-icon>
                    <span class="md-body-2 ml-1 grey--text text--darken-1 font-weight-medium">Estimated Timeline</span>
                </div>

                <div class="mt-2">
                    <div v-if="!localProject('estimated_start_date') && !localProject('estimated_end_date')">
                        <div class="u-relative d-inline-block">
                            <h2 :class="['font-italic grey--text']" class="u-font-24 font-weight-medium u-tracking-tight">
                                No Estimated Start Date & End Date
                            </h2>
                            <a-icon
                                v-if="
                                    mixHasPermission('projects.update-only') &&
                                    mixHasPermission('projects-estimated-start-end-dates.update-only')
                                "
                                @click="mixOpenInNewWindow('projects-overview')"
                                size="16"
                                color="grey"
                                class="u-absolute"
                                style="bottom: 7px; right: -22px;"
                            >
                                drive_file_rename_outline
                            </a-icon>
                        </div>
                        <div class="u-flex-center-y mt-3">
                            <a-icon size="16" color="grey" outlined>info</a-icon>
                            <span class="md-body-1 ml-1 grey--text text--darken-1">Add estimated start and end date for timeline summary</span>
                        </div>
                    </div>
                    <div v-else class="u-flex-center-y" style="column-gap: 10px;">
                        <div class="u-relative d-inline-block">
                            <h2
                                :class="[{ 'font-italic grey--text u-tracking-tight': !localProject('estimated_start_date') }]"
                                class="u-font-24 u-font-weight-semibold u-tracking-tight"
                            >
                                {{ localFormatDate({ date: localProject('estimated_start_date') }) || 'No Estimated Start Date' }}
                            </h2>
                            <a-icon
                                v-if="
                                    !localProject('estimated_start_date') &&
                                    mixHasPermission('projects.update-only') &&
                                    mixHasPermission('projects-estimated-start-end-dates.update-only')
                                "
                                @click="mixOpenInNewWindow('projects-overview')"
                                size="16"
                                color="grey"
                                class="u-absolute"
                                style="bottom: 7px; right: -22px;"
                            >
                                drive_file_rename_outline
                            </a-icon>
                        </div>
                        <span
                            class="grey--text u-font-weight-semibold"
                            :class="[{
                                'ml-5': !localProject('estimated_start_date') && mixHasPermission('projects-estimated-start-end-dates.update-only')
                            }]"
                        >
                            ->
                        </span>
                        <div class="u-relative d-inline-block">
                            <h2
                                :class="[{ 'font-italic grey--text u-tracking-tight': !localProject('estimated_end_date') }]"
                                class="u-font-24 u-font-weight-semibold u-tracking-tight"
                            >
                                {{ localFormatDate({ date: localProject('estimated_end_date') }) || 'No Estimated End Date' }}
                            </h2>
                            <a-icon
                                v-if="
                                    !localProject('estimated_end_date') &&
                                    mixHasPermission('projects.update-only') &&
                                    mixHasPermission('projects-estimated-start-end-dates.update-only')
                                "
                                @click="mixOpenInNewWindow('projects-overview')"
                                size="16"
                                color="grey"
                                class="u-absolute"
                                style="bottom: 7px; right: -22px;"
                            >
                                drive_file_rename_outline
                            </a-icon>
                        </div>
                    </div>

                    <div
                        v-if="localProject('estimated_start_date') || localProject('estimated_end_date')"
                        class="u-flex-center-y mt-3"
                        style="column-gap: 6px;"
                    >
                        <a-icon size="16" color="grey">auto_awesome</a-icon>
                        <div class="u-flex-center-y grey--text text--darken-1" style="column-gap: 8px;">
                            <span v-if="localProject('estimated_start_date')">{{ localSmartEstStartDate }}</span>
                            <a-icon
                                v-if="localProject('estimated_start_date') && localProject('estimated_end_date')"
                                size="5"
                                color="grey"
                            >
                                fiber_manual_record
                            </a-icon>
                            <span v-if="localProject('estimated_end_date')">{{ localSmartEstEndDate }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <a-spacer></a-spacer>
            <a-divider></a-divider>

            <div class="pa-5 u-flex-center-y" style="border-radius: 0px 0px 8px 8px; background: linear-gradient(243deg, #F4F5FB 31.55%, rgba(255, 255, 255, 0.00) 66.94%);">
                <div v-if="mixHasPermission('usecases.index') || mixHasPermission('tasks.index')" class="u-flex-center-y" style="column-gap: 32px;">
                    <div
                        v-if="mixHasPermission('usecases.index')"
                        class="c-navigate-link u-cursor-pointer md-body-2 grey--text text--darken-2 font-weight-medium u-flex align-end"
                        style="column-gap: 6px;"
                        @click="mixOpenInNewWindow('projects-usecases')"
                    >
                        <span
                            v-if="mixParentState.project"
                            class="c-navigate-link__count u-font-20"
                            style="line-height: 24px;"
                        >
                            {{ mixParentState.project.total_success_criteria || 0 }}
                        </span>
                        <div class="u-flex-center-y u-relative">
                            <span class="c-navigate-link__text md-body-2 font-weight-medium" style="line-height: 20px;">Success Criteria</span>
                            <a-icon class="c-navigate-link__arrow u-absolute" style="top: 50%; transform: translateY(-50%)" color="indigo darken-2" size="18">chevron_right</a-icon>
                        </div>
                    </div>
                    <div
                        v-if="mixHasPermission('tasks.index')"
                        class="c-navigate-link u-cursor-pointer md-body-2 grey--text text--darken-2 font-weight-medium u-flex align-end"
                        style="column-gap: 6px;"
                        @click="mixOpenInNewWindow('projects-tasks')"
                    >
                        <span
                            v-if="mixParentState.project"
                            class="c-navigate-link__count u-font-20"
                            style="line-height: 24px;"
                        >
                            {{ mixParentState.project.total_tasks || 0 }}
                        </span>
                        <div class="u-flex-center-y u-relative">
                            <span class="c-navigate-link__text md-body-2 font-weight-medium" style="line-height: 20px;">Tasks</span>
                            <a-icon class="c-navigate-link__arrow u-absolute" style="top: 50%; transform: translateY(-50%)" color="indigo darken-2" size="18">chevron_right</a-icon>
                        </div>
                    </div>
                </div>
                <a-spacer></a-spacer>
                <div class="u-flex-center-y u-cursor-pointer" @click="mixOpenInNewWindow('projects-overview')">
                    <span v-if="mixHasPermission('projects.update-only')" class="md-body-2 indigo--text text--darken-2 u-font-weight-semibold">
                        Move to {{ localFirstActiveStage | truncateText(8) }} Stage
                    </span>
                    <span v-else class="md-body-2 indigo--text text--darken-2 u-font-weight-semibold">
                        Go to Overview
                    </span>
                    <a-icon size="16" color="indigo darken-2" class="ml-2">arrow_circle_right</a-icon>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { diffSimple } from '@/helpers/helper-date-filter'
import MixinModalStateInjection from '../Mixins/MixinModalStateInjection'
import LoaderProjectActivitySummary from '../Loaders/LoaderProjectActivitySummary.vue'
import moment from 'moment'

export default {
	components: {
        LoaderProjectActivitySummary
    },

    mixins: [MixinModalStateInjection],

    computed: {
        localFirstActiveStage () {
            return _.get(this.stage_list, '1.name') || ''
        },

        localSmartEstStartDate () {
            const startDate = this.localProject('estimated_start_date')
            const isThisWeek = moment(startDate).isSame(new Date(), 'week')
            const daysCount = moment(startDate).diff(moment().format('YYYY-MM-DD'), 'days')
            const monthDiff = moment(startDate).diff(moment().format('YYYY-MM-DD'), 'months')
            const yearDiff = moment(startDate).diff(moment().format('YYYY-MM-DD'), 'year')
            const weekday = moment(startDate).format('ddd')
            const range = { isPastDay: daysCount > 0, isPastMonth: monthDiff > 0, isPastYear: yearDiff > 0 }
            let rangeText = _.values(range).some(i => i) ? 'Starts ' : 'Overdue '

            return this.localCalculateDateText({ daysCount, monthDiff, yearDiff, rangeText, weekday, isThisWeek, field: 'startDate' })
        },

        localSmartEstEndDate () {
            const startDate = this.localProject('estimated_start_date')
            const endDate = this.localProject('estimated_end_date')
            const isSameDay = moment(endDate).isSame(startDate, 'day')
            if (moment(endDate).diff(moment(), 'days') < 0) return 'Needs Action'

            const isThisWeek = moment(endDate).isSame(new Date(), 'week')
            const daysCount = startDate && !isSameDay ? moment(endDate ? moment(endDate) : moment().format('YYYY-MM-DD')).diff(startDate, 'days') : moment(endDate).diff(moment().format('YYYY-MM-DD'), 'days')
            const monthDiff = startDate && !isSameDay ? moment(endDate ? moment(endDate) : moment().format('YYYY-MM-DD')).diff(startDate, 'months') : moment(endDate).diff(moment().format('YYYY-MM-DD'), 'months')
            const yearDiff = startDate && !isSameDay ? moment(endDate ? moment(endDate) : moment().format('YYYY-MM-DD')).diff(startDate, 'year') : moment(endDate).diff(moment().format('YYYY-MM-DD'), 'year')
            const weekday = moment(endDate).format('dddd')
            const range = { futureDay: daysCount > 0, futureMonth: monthDiff > 0, futureYear: yearDiff > 0 }
            const countRange = [daysCount, monthDiff, yearDiff]
            let rangeText = _.values(range).some(i => i) || countRange.every(i => i >= 0) ? 'To be completed ' : 'Overdue '

            return this.localCalculateDateText({ daysCount, monthDiff, yearDiff, rangeText, weekday, isSameDay, isThisWeek, hasStartDate: !!startDate, field: 'endDate' })
        },

        ...mapState('Stage', {
            stage_list: 'list'
        })
    },

    methods: {
        async localIndex () {
            //
        },

        localFormatDate (props) {
            const { date = null, type = null } = props || {}
            if (!date) return ''

            return date ? diffSimple(date, { format: 'MMM DD, YYYY', withYearFormat: 'MMM DD, YYYY' }) : null
        },

        localProject (field) {
            return this.mixParentState.project && this.mixParentState.project[field] || ''
        },

        localCalculateDateText (args) {
            let { daysCount, monthDiff, yearDiff, rangeText, isThisWeek, isSameDay, weekday, field, hasStartDate = null } = args || {}
            let result = ''
            if (daysCount === 0 && !isSameDay) result = 'Today'

            if (yearDiff === 0 && daysCount > 90) result = 'in ' + monthDiff + (monthDiff === 1 ? ' month' : ' months')
            if (yearDiff === 0 && daysCount < -90) result = 'by ' + Math.abs(monthDiff) + (Math.abs(monthDiff) === 1 ? ' month' : ' months') // past

            if (daysCount === 1) result = 'tomorrow'
            if (daysCount === -1) result = 'yesterday' // past

            if (!isThisWeek && (daysCount > 1 && daysCount <= 7)) result = 'next week'
            if (isThisWeek && (daysCount > 1 && daysCount <= 7)) result = 'this week'
            if (!isThisWeek && (daysCount < -1 && daysCount >= -7)) result = 'last week' // past
            if (isThisWeek && (daysCount < -1 && daysCount >= -7)) result = 'this week' // past

            if (daysCount > 7 && daysCount <= 90) result = 'in ' + daysCount + (daysCount === 1 ? ' day' : ' days')
            if (daysCount < -7 && daysCount >= -90) result = 'by ' + Math.abs(daysCount) + (Math.abs(daysCount) === 1 ? ' day' : ' days') // past

            if (yearDiff >= 1 || yearDiff <= -1) {
                const exactYear = Math.floor(Math.abs(monthDiff) / 12) + (Math.abs(monthDiff) % 12 !== 0 ? ('.' + (Math.abs(monthDiff) % 12)) : '')
                if (yearDiff >= 1) result = 'in ' + exactYear + (parseFloat(exactYear) > 1 ? ' years' : ' year')
                if (yearDiff <= -1) result = 'by ' + exactYear + (parseFloat(exactYear) < -1 ? ' years' : ' year') // past
            }

            const fullRangeText = _.cloneDeep(rangeText)
            rangeText = _.trim(rangeText) === 'Overdue' ? (rangeText + (field === 'startDate' ? 'to start ' : 'to complete ')) : rangeText
            return rangeText + result + (hasStartDate === false && fullRangeText !== 'Overdue ' && daysCount > 1 ? ' from today' : '')
        },
    }
}
</script>

<style lang="scss" scoped>
.c-navigate-link {
    transition: 0.25s all ease-in-out;
    &__text { color: #757575; }
    &__count {
        color: #424242;
        transition: 0.25s color ease-in-out;
    }
    &__arrow {
        visibility: hidden;
        right: -16px;
        opacity: 0;
        transition: 0.25s all ease-in-out;
    }
}

.c-navigate-link:hover {
    .c-navigate-link__text { color: #303F9F !important; }
    .c-navigate-link__count {
        color: #303F9F;
        transition: 0.25s color ease-in-out;
    }
    .c-navigate-link__arrow {
        visibility: visible !important;
        opacity: 1 !important;
        right: -18px;
        transition: 0.25s all ease-in-out;
    }
}
</style>

<template>
    <v-card-title v-bind="$attrs" v-on="$listeners" class="c-card-title">
        <slot></slot>
    </v-card-title>
</template>

<script>
import { VCardTitle } from 'vuetify/lib'

export default {
    inheritAttrs: false,
    components: { VCardTitle }
}
</script>

<template>
    <a-card>
        <div class="u-flex-center-y pa-6" style="column-gap: 16px;">
            <a-avatar class="primary darken-1" size="40">
                <a-icon dark>insert_drive_file</a-icon>
            </a-avatar>
            <a-flex>
                <h2 class="md-heading-6 primary--text text--darken-1">Export Tasks as Template</h2>
                <p class="md-subtitle-2 mb-0">You can save list of tasks to import them in new projects.</p>
            </a-flex>
        </div>

        <a-divider></a-divider>

        <div class="px-6 pt-5 pb-7">
            <div class="pt-1">
                <v-radio-group class="mt-0" hide-details v-model="exportMode" row @change="localExportModeChange()">
                    <v-radio label="New Template" value="new"></v-radio>
                    <v-radio label="Existing Template" value="existing" :disabled="!$can('templates.update') || !$can('templates.index')"></v-radio>
                </v-radio-group>
            </div>
            <div class="mt-4" v-if="exportMode === 'new'">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group Name</label>
                <a-text-field
                    v-model="templateName"
                    placeholder="Enter Template Name"
                    background-color="neutral"
                    solo flat hide-details
                >
                </a-text-field>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(template_group_response, 'name')">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ $response(template_group_response, 'name') }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    The name will be helpful to identify the group.
                </span>
            </div>
            <div class="mt-4" v-if="exportMode === 'existing'">
                <label class="md-body-2 d-block mb-2 mt-0 font-weight-medium">Group</label>
                <a-autocomplete
                    v-model="templateId"
                    :items="template_group_list"
                    item-text="name"
                    item-value="id"
                    placeholder="Select Template"
                    background-color="neutral"
                    :allow-overflow="false"
                    solo flat hide-details
                >
                    <template v-slot:item="{ item }">
                        <a-list-item-content>
                            <div class="member-label u-cursor-pointer">
                                <h5 class="md-subtitle-1 text-truncate mb-1" style="max-width: 520px" :title="item.name">{{ item.name }}</h5>
                            </div>
                        </a-list-item-content>
                    </template>
                </a-autocomplete>
                <span class="md-caption red--text text--darken-2 d-block mt-2" v-if="$response(template_milestone_response, 'template_group_id')">
                    <a-icon size="16" color="red darken-2">warning</a-icon>
                    {{ $response(template_milestone_response, 'template_group_id') }}
                </span>
                <span class="md-caption grey--text text--darken-2 d-block mt-2" v-else>
                    <a-icon size="16">info</a-icon>
                    The name will be helpful to identify the group.
                </span>
            </div>
        </div>

        <a-divider></a-divider>

        <div class="u-flex-center-y pa-6">
            <a-btn color="primary" class="ma-0 elevation-0 px-3" @click="localExportAsTemplate()" :loading="loading" :disabled="loading">Save Template</a-btn>
            <a-btn class="ml-4 mt-0 mb-0 mr-0" color="grey" text @click="localCloseExportAsTemplate()" :disabled="loading">Cancel</a-btn>
        </div>
    </a-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            exportMode: 'new',
            templateName: null,
            templateId: null
        }
    },

    watch: {
        isOpen: {
            handler (val) {
                if (val) this.localIndex()
            },
            immediate: true
        }
    },

    computed: {
        ...mapState('Milestone', {
            milestone_list: 'list',
        }),

        ...mapState('Task', {
            task_list: 'list',
        }),

        ...mapState('TemplateGroup', {
            template_group_list: 'list',
            template_group_item: 'item',
            template_group_filters: 'filters',
            template_group_response: 'response',
            template_group_form_mode: 'form_mode',
        }),

        ...mapState('TemplateMilestone', {
            template_milestone_item: 'item',
            template_milestone_response: 'response',
        }),

        ...mapState('TemplateTask', {
            template_task_item: 'item',
            template_task_response: 'response',
        }),

        ...mapState('Interface', {
            loading: 'loader',
        }),
    },

    methods: {
        async localIndex () {
            this.templateName = null
            this.templateId = null

            if (this.$can('templates.index')) {
                this.template_group_filters.type = 'Task'
                await this.template_group_index({
                    include: 'templateTask',
                    'filter[type]': 'tasks'
                })
            }
            this.exportMode = 'new'
        },

        async localExportAsTemplate () {
            if (this.exportMode === 'new') await this.localTemplateGroupStore()
            if (this.exportMode === 'existing') await this.localMilestoneGroupExport(this.templateId)
        },

        async localTemplateGroupStore () {
            await this.template_group_store({ name: this.templateName, type: 'tasks' })
            if (!this.$status(this.template_group_response)) return
            this.localMilestoneGroupExport(this.template_group_item.id)
        },

        async localMilestoneGroupExport (group_id) {
            const milestone_ids = this.milestone_list.map(item => item.id);
            if (milestone_ids.length) {
                const template_export_milestone_item = {}
                template_export_milestone_item.data = milestone_ids
                template_export_milestone_item.template_group_id = group_id
                await this.template_milestone_export(template_export_milestone_item);
            }
            await this.localTaskGroupExport(group_id)
            if (this.$status(this.template_task_response) || this.$status(this.template_milestone_response)) {
                this.localCloseExportAsTemplate()
                this.$notify('success', 'Tasks exported successfully!')
                this.localCloseExportAsTemplate()
            }
        },

        async localTaskGroupExport (group_id) {
            const task_ids = this.task_list.map(item => item.id);
            if (task_ids.length) {
                const template_export_task_item = {}
                template_export_task_item.data = task_ids
                template_export_task_item.template_group_id = group_id
                await this.template_task_export(template_export_task_item)
            }
        },

        localExportModeChange () {
            this.template_group_clear_item()
            this.template_milestone_clear_item()
        },

        localCloseExportAsTemplate () {
            this.localExportModeChange()
            this.$emit('close', true)
        },

        ...mapActions('TemplateGroup', {
            template_group_index: 'index',
            template_group_store: 'store',
            template_group_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateMilestone', {
            template_milestone_export: 'template_export',
            template_milestone_clear_item: 'clear_item',
        }),

        ...mapActions('TemplateTask', {
            template_task_export: 'template_export',
        }),
    }
}
</script>

import { v4 as uuidv4 } from 'uuid'

export default {
    install(Vue) {
        Vue.prototype.$appendId = function (param = {}) {
            Object.assign(param, { id: uuidv4() })
            return param
        }
    }
}

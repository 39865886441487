import Vue from 'vue'
import { pascalToKebab } from '@/helpers/helper-case-convertor'

Vue.directive('testLabel', {
    inserted: function (el, binding, vnode) {
        const type = 'label'
        const componentName = vnode ? `${pascalToKebab(vnode)}` : ''
        const acceptedTypes = ['info', 'error', 'success', 'warning']

        // type => success, error, info, warning
        const modifierKeys = Object.keys(binding.modifiers)
        const modifiers = modifierKeys.join('-').replaceAll('_', '-')

        if (modifierKeys.length === 0) throw Error(`Expected two modifiers. But receieved none.`)
        if (modifierKeys.length === 1) throw Error(`Expected a second modifier of type ${acceptedTypes.join(', ')}`)
        if (!acceptedTypes.includes(modifierKeys[1])) throw Error(`${modifiers[1]} is not valid. Accepted modifiers are ${acceptedTypes.join(', ')}`)

        let val = `${type}`;
        if (componentName) val += `-${componentName}`;
        el.dataset['testElement'] = val + `-${modifiers}`; // label-competitors-name-info
    }
})

<template>
    <a-main>
        <g-settings-breadcrumb></g-settings-breadcrumb>
        <a-container grid-list-xl container--fluid class="mt-5" pa-0>
            <PValidationPageHeader
                title="Validation"
                subtitle="Manage and configure Validation Workflows."
            >
            </PValidationPageHeader>
        </a-container>
        <a-container grid-list-xl container--fluid mt-6 pa-0>
            <router-view></router-view>
        </a-container>
    </a-main>
</template>

<script>
import { mapState } from 'vuex'
import PValidationPageHeader from './Partials/PartialValidationPageHeader'

export default {
    components: {
        PValidationPageHeader
    },
    
    mounted() {
        this.localIndex()
    },

    computed: {
        ...mapState('User', {
            user_self: 'self',
        }),
    },

    methods: {
        async localIndex() {
            if (!this.$can('validation-workflows.index') || this.user_self && this.user_self.is_admin === 0 || this.user_self.scope === 'external') {
                this.$router.replace({name: 'errors-unauthorized'})
            }
        },
    },
}
</script>
// import registerDynamic from '@/config/config-register-ui-comp'

// export default {
//     HeadingBlock: () => import('./HeadingBlock/index.vue'),
//     PrimaryText: () => import('./PrimaryText/index.vue'),
//     RichText: () => import('./RichText/index.vue'),
//     OrderedList: () => import('./OrderedList/index.vue'),
//     UnorderedList: () => import('./UnorderedList/index.vue'),
//     Divider: () => import('./Divider/index.vue'),
//     PageBreak: () => import('./PageBreak/index.vue'),
//     OrganizationLogo: () => import('./OrganizationLogo/index.vue'),
//     UsecaseTable: () => import('./UsecaseTable/index.vue'),
//     UsecaseDetailed: () => import('./UsecaseDetailed/index.vue'),
//     TaskTable: () => import('./TaskTable/index.vue')
// }


const DocumentGenerator = [
    'HeadingBlock',
    'PrimaryText',
    'RichText',
    'OrderedList',
    'UnorderedList',
    'Divider',
    'PageBreak',
    'OrganizationLogo',
    'UsecaseTable',
    'UsecaseDetailed',
    'TaskTable',
]
const componentsList = {}

DocumentGenerator.forEach(comp => {
    componentsList[comp] = () => import(`@/components/ReportBuilder/${comp}/index.vue`)
})

export default componentsList
